import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';
import feather from "feather-icons";
function Header() {
  const navigate = useNavigate();
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  let first_name_short = "";
  let isToken = '';
  if (authUserData) {
    if (authUserData[0]) {
      first_name_short = authUserData[0].name ? authUserData[0].name : "";
      isToken = authUserData[0].token ? authUserData[0].token : "";
    }
  }
  /* Handle Logout */
  const handleLogout = () => {
    navigate("/login");
    const UserData = [
      {
        name: "",
        status: "",
        token: "",
        email: "",
        user_id: "",
      },
    ];
    setAuthUserData(UserData);
    localStorage.removeItem("etIMSToken");
    toast.success("You have logged out successfully.");
  };
  useEffect(() => {
    feather.replace();
  }, []);
  return (
    <>
      {
        isToken && isToken ?
          <>
            <nav className="navbar navbar-expand navbar-light navbar-bg">
              <div className="navbar-collapse collapse">
                <ul className="navbar-nav navbar-align">
                  <li className="nav-item dropdown">
                    <Link className="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-bs-toggle="dropdown">
                      <i className="align-middle" data-feather="settings"></i>
                    </Link>
                    <Link className="nav-link dropdown-toggle d-none d-sm-inline-block" href="#" data-bs-toggle="dropdown">
                      <span className="text-dark">Hi, {first_name_short}</span>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end">
                      <Link className="dropdown-item" to="/admin/profile"><i className="align-middle me-1" data-feather="user"></i> Profile</Link>
                      {/*   <Link className="dropdown-item" to="/admin/change-password"><i className="align-middle me-1" data-feather="lock"></i> Change Password</Link> */}
                      <div className="dropdown-divider"></div>
                      <button className="dropdown-item" onClick={handleLogout}>Log out</button>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </> : ""
      }
    </>
  )
}

export default Header