import React, { useState, useEffect } from "react";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import Form from "../../includes/Forms";
import TextareaAutosize from "react-textarea-autosize";

import {
  countryData,
  currencyData,
  stateData,
  clientChannel
} from "../../includes/staticData";
import Select from 'react-select';

function AddEditClient({ client, onClose, onSubmit }) {
  const [clientAddedIn, setClientAddedIn] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company_name: "",
    phone_number: "",
    currency: "",
    billing_address: "",
    country: "",
    state: "",
    gst: "",
    hsn_number: "",
    account_details: "",
    status: "0",
    client_channel: "",
    client_added_in: "",
    client_departments: []
  });
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [depertmentList, setDepertmentList] = useState(null);
  const [departments, setDepartments] = useState(null);

  useEffect(() => {
    const generateClientAddedYears = () => {
      const startYear = 2002;
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      let clientAddedInList = [];
      let endYear = currentMonth >= 4 ? currentYear : currentYear - 1;

      for (let year = endYear; year >= startYear; year--) {
        clientAddedInList.push({
          value: `${year}-${year + 1}`,
          label: `${year}-${year + 1}`,
        });
      }

      setClientAddedIn(clientAddedInList);

      const currentFinancialYear = `${endYear}-${endYear + 1}`;
      const selectedFY = clientAddedInList.find(year => year.value === currentFinancialYear);

      setSelectedYear(selectedFY);
    };

    generateClientAddedYears();
    fetchDepartmentData();
  }, []);
  useEffect(() => {
    setLoading(true);
    if (client) {
      setFormData({
        name: client.name || "",
        email: client.email || "",
        company_name: client.company_name || "",
        phone_number: client.phone_number || "",
        currency: client.currency || "",
        billing_address: client.billing_address || "",
        country: client.country || "",
        state: client.state || "",
        gst: client.gst || "",
        hsn_number: client.hsn_number || "",
        account_details: client.account_details || "",
        status: client.status || "0",
        client_channel: client.client_channel || "",
        client_added_in: client.client_added_in || "",
        client_departments: client.client_departments
          ? client.client_departments.map((dep) => ({
            value: dep.value,
            label: dep.label,
          }))
          : [],
      });

      if (client && client.client_added_in !== "") {
        setSelectedYear(
          {
            value: client.client_added_in,
            label: client.client_added_in,
          }
        );
      }
      setDepartments(client.client_departments || []);
    }
    setLoading(false);
  }, [client]);

  /* HANDLES  */
  const fetchDepartmentData = async () => {
    try {
      const departmentResponse = await axios.get(CONST.GET_DEPARTMENT_LIST);
      if (departmentResponse.data.status === 'success') {
        setDepertmentList(departmentResponse.data.data.map(department => (
          {
            value: department.id,
            label: department.name,
          }
        )));
      }
    } catch (error) {
      toast.error('Error:', error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = async () => {
    let isValid = true;
    let errors = {};

    const validator = Form.validator({
      name: {
        value: formData.name,
        isRequired: true,
        label: "name",
      },
      email: {
        value: formData.email,
        isRequired: true,
        isEmail: true,
        label: "email",
      },
      company_name: {
        value: formData.company_name,
        isRequired: true,
        label: "company name",
      },
      currency: {
        value: formData.currency,
        isRequired: true,
        label: "currency",
      },
      billing_address: {
        value: formData.billing_address,
        isRequired: true,
        label: "billing address",
      },
      country: {
        value: formData.country,
        isRequired: true,
        label: "country",
      },
      state: {
        value: formData.state,
        isRequired: formData.country === "IN",
        label: "state",
      },
      gst: {
        value: formData.gst,
        isRequired: false,
        label: "GST",
      },
      hsn_number: {
        value: formData.hsn_number,
        isRequired: false,
        label: "HSN Number",
      },
      account_details: {
        value: formData.account_details,
        isRequired: false,
        label: "Account Details",
      },
      status: {
        value: formData.status,
        isRequired: true,
        label: "status",
      },
      client_departments: {
        value: formData.client_departments,
        isRequired: true,
        label: "depertments",
      },
    });

    if (validator && validator.errors) {
      errors = { ...errors, ...validator.errors };
      isValid = false;
    }

    /* const phoneRegex = /^\+([1-9]{1,4})\d{7,14}$/;
    if (!phoneRegex.test(formData.phone_number)) {
      isValid = false;
      errors.phone_number =
        "Please enter a valid phone number with country code";
    } */

    setValidate({
      validate: errors,
    });

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (!isValid) return;

    setLoading(true);

    const mappedFormData = {
      client_id: client ? client.id : "",
      name: formData.name,
      email: formData.email,
      company_name: formData.company_name,
      phone_number: formData.phone_number,
      currency: formData.currency,
      billing_address: formData.billing_address,
      country: formData.country,
      state: formData.state,
      gst: formData.gst,
      hsn_number: formData.hsn_number,
      account_details: formData.account_details,
      status: formData.status,
      client_departments: formData.client_departments,
      client_channel: formData.client_channel,
      client_added_in: formData.client_added_in
    };
    //console.log(formData);
    //console.log(mappedFormData);

    const request = client
      ? axios.post(CONST.UPDATE_CLIENT, mappedFormData)
      : axios.post(CONST.ADD_CLIENT, mappedFormData);

    request
      .then((response) => {


        if (response.data.status === "success") {
          toast.success(response.data.message);
          onSubmit();
          onClose();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error saving client:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const countryOptions = countryData.map((country) => ({
    label: country.name,
    value: country.code,
  }));
  const clientChannelOptions = clientChannel.map((channel) => ({
    label: channel.name,
    value: channel.code,
  }));
  const stateOptions = stateData.map((state) => ({
    label: state.name,
    value: state.code,
  }));
  const currencyOptions = currencyData.map((currency) => ({
    label: `${currency.name} (${currency.code})`,
    value: currency.code,
  }));
  const handleReactSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };
  const handleDepartmentChange = (dpt) => {
    setDepartments(dpt);
    setFormData((prev) => ({
      ...prev,
      client_departments: dpt
    }));
  };
  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    setFormData((prev) => ({
      ...prev,
      client_added_in: selectedOption.value
    }));
  };

  return (
    <>
      {loading && <Loading loading={loading} />}
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          {/* Client Name */}
          <div className="col-md-4">
            <label className="form-label">Client Name</label>
            <input
              type="text"
              className={`form-control ${validate.validate && validate.validate.name ? "is-invalid" : ""
                }`}
              placeholder="Client Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {validate.validate && validate.validate.name && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.name}
              </div>
            )}
          </div>
          {/* Company Name */}
          <div className="col-md-4">
            <label className="form-label">Company Name</label>
            <input
              type="text"
              className={`form-control ${validate.validate && validate.validate.company_name
                ? "is-invalid"
                : ""
                }`}
              placeholder="Company Name"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
            />
            {validate.validate && validate.validate.company_name && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.company_name}
              </div>
            )}
          </div>
          {/* Email Address */}
          <div className="col-md-4">
            <label className="form-label">Email Address</label>
            <input
              type="email"
              className={`form-control ${validate.validate && validate.validate.email ? "is-invalid" : ""
                }`}
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {validate.validate && validate.validate.email && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.email}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          {/* Phone Number */}
          <div className="col-md-4">
            <label className="form-label">Phone Number</label>
            <input
              type="text"
              className={`form-control ${validate.validate && validate.validate.phone_number
                ? "is-invalid"
                : ""
                }`}
              placeholder="Phone Number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
            />
            {validate.validate && validate.validate.phone_number && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.phone_number}
              </div>
            )}
          </div>
          {/* Currency */}
          <div className="col-md-4">
            <label className="form-label">Currency</label>
            <Select

              name="currency"
              value={currencyOptions.find((opt) => opt.value === formData.currency)}
              options={currencyOptions}
              onChange={handleReactSelectChange}
              className={`react-select-container ${validate.validate && validate.validate.currency ? "is-invalid" : ""
                }`}
              placeholder="Select Currency"
            >
              <option value="">Select Currency</option>
              {currencyData.length > 0 ? (
                currencyData.map((currency) => (
                  <option key={currency.code} value={currency.code}>
                    {currency.name} ({currency.code})
                  </option>
                ))
              ) : (
                <option disabled>Loading currencies...</option>
              )}
            </Select>
            {validate.validate && validate.validate.currency && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.currency}
              </div>
            )}
          </div>
          {/* Country */}
          <div className="col-md-4">
            <label className="form-label">Country</label>
            <Select
              name="country"
              classNamePrefix="react-select"
              options={countryOptions}
              value={countryOptions.find((opt) => opt.value === formData.country)}
              onChange={handleReactSelectChange}
              className={`react-select-container ${validate.validate && validate.validate.country ? "is-invalid" : ""
                }`}
              placeholder="Select Country"
            />
            {validate.validate && validate.validate.country && (
              <div className="invalid-feedback d-block">
                {validate.validate.country}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          {formData.country === "IN" && (
            <>
              {/* State */}
              <div className="col-md-4">
                <label className="form-label">State</label>
                <Select
                  name="state"
                  options={stateOptions}
                  className={`react-select-container ${validate.validate && validate.validate.country ? "is-invalid" : ""
                    }`}

                  value={stateOptions.find((opt) => opt.value === formData.state)}
                  onChange={handleReactSelectChange}
                >

                </Select>
                {validate.validate && validate.validate.country && (
                  <div className="invalid-feedback d-block">
                    {validate.validate.country}
                  </div>
                )}
              </div>
              {/* GST */}
              <div className="col-md-4">
                <label className="form-label">GST</label>
                <input
                  type="text"
                  className={`form-control ${validate.validate && validate.validate.gst
                    ? "is-invalid"
                    : ""
                    }`}
                  placeholder="GST"
                  name="gst"
                  value={formData.gst}
                  onChange={handleChange}
                />
                {validate.validate && validate.validate.gst && (
                  <div className="invalid-feedback">
                    {validate.validate.gst}
                  </div>
                )}
              </div>
              {/* HSN Numbe */}
              <div className="col-md-4">
                <label className="form-label">HSN Number</label>
                <input
                  type="text"
                  className={`form-control ${validate.validate && validate.validate.hsn_number
                    ? "is-invalid"
                    : ""
                    }`}
                  placeholder="HSN Number"
                  name="hsn_number"
                  value={formData.hsn_number}
                  onChange={handleChange}
                />
                {validate.validate && validate.validate.hsn_number && (
                  <div className="invalid-feedback">
                    {validate.validate && validate.validate.hsn_number}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="row mb-3">
          {/* BILLING ADDRESS */}
          <div className="col-md-4">
            <label className="form-label">Billing Address</label>
            <TextareaAutosize
              className={`form-control ${validate.validate && validate.validate.billing_address
                ? "is-invalid"
                : ""
                }`}
              placeholder="Billing Address"
              name="billing_address"
              value={formData.billing_address}
              onChange={handleChange}
              minRows={4} // Minimum number of rows
              maxRows={10} // Maximum number of rows before scrolling
              style={{
                width: "100%",
                resize: "none",
              }}
            />
            {validate.validate && validate.validate.billing_address && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.billing_address}
              </div>
            )}
          </div>
          {/* ACCOUNT DETAILS */}
          <div className="col-md-4">
            <label className="form-label">Account/Bank Details</label>
            <TextareaAutosize
              className={`form-control ${validate.validate && validate.validate.account_details
                ? "is-invalid"
                : ""
                }`}
              placeholder="Account Details"
              name="account_details"
              value={formData.account_details}
              onChange={handleChange}
              minRows={4} // Minimum number of rows
              maxRows={10} // Maximum number of rows before scrolling
              style={{
                width: "100%",
                resize: "none",
              }}
            />
            {validate.validate && validate.validate.account_details && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.account_details}
              </div>
            )}
          </div>
          <div className="col-md-4">
            <label className="form-label">Client Added In</label>
            <Select
              options={clientAddedIn}
              placeholder="Select Year..."
              onChange={handleYearChange}
              value={selectedYear}
              name="client_added_in"
            />
          </div>
        </div>


        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Client Channel</label>
            <Select
              name="client_channel"
              classNamePrefix="react-select"
              options={clientChannelOptions}
              value={clientChannelOptions.find((opt) => opt.value === formData.client_channel)}
              onChange={handleReactSelectChange}
              className='react-select-container'
              placeholder="Select Channel"
            />

          </div>


          <div className="col-md-4">
            <label className="form-label">Department</label>
            <Select
              isMulti
              name="client_departments"
              options={depertmentList}
              value={formData.client_departments}
              onChange={handleDepartmentChange}
              className={`react-select-container ${validate.validate && validate.validate.client_departments ? "is-invalid" : ""
                }`}
              placeholder="Select Department(s)"
            />
            {validate.validate && validate.validate.client_departments && (
              <div className="invalid-feedback d-block">
                {validate.validate.client_departments}
              </div>
            )}

          </div>

          <div className="col-md-4">
            <label className="form-label">Status</label>
            <select
              className={`form-control ${validate.validate && validate.validate.status
                ? "is-invalid"
                : ""
                }`}
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="0">Active</option>
              <option value="1">Inactive</option>
              <option value="2">Delete</option>
            </select>
            {validate.validate && validate.validate.status && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.status}
              </div>
            )}
          </div>
        </div>
        <div className="text-left">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form >
    </>
  );
}

export default AddEditClient;
