import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import axios from "axios";
import BlockLoading from "../../../blocks/BlockLoading";
import * as CONST from "../../../includes/Constant";
import { S_FORMAT_INR_CURRENCY_AND_AMOUNT } from "../../../includes/staticData";

const COLORS_FIXEDCOST = ["#306aaf", "#4780ff", "#dee8fd"];
const COLORS_DEDICATED = ["#198754", "#28a745", "#d4edda"];

const FixedcostVsDedicatedBarChart = ({ searchParams }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFixedcostVsDedicatedBarChart = async () => {
    try {
      setLoading(true);
      const response = await axios.get(CONST.DASH_FIXED_VS_DEDICATED_BAR_CHART, {
        params: searchParams
      });
      if (response.data && Array.isArray(response.data.data)) {
        setChartData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFixedcostVsDedicatedBarChart();
  }, [searchParams]);

  return (
    <>
      {loading ? (
        <div className="row"><BlockLoading /></div>
      ) : (
        <div className="card flex-fill w-100">
          <div className="card-header">
            <h5 className="card-title mb-0">Fixed Cost vs Dedicated (Month-wise)</h5>
          </div>
          <div className="card-body pt-2 pb-3">
            <div className="chart chart-sm">
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={chartData} margin={{ top: 20, right: 10, left: 50, bottom: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Month" />
                  <YAxis tickFormatter={(value) => S_FORMAT_INR_CURRENCY_AND_AMOUNT(value)} />
                  <Tooltip formatter={(value) => S_FORMAT_INR_CURRENCY_AND_AMOUNT(value)} />
                  <Legend />
                  <Bar dataKey="fixedcost.Raised" stackId="a" fill={COLORS_FIXEDCOST[2]} name="Fixed Cost Raised" />

                  <Bar dataKey="fixedcost.Considered" stackId="a" fill={COLORS_FIXEDCOST[1]} name="Fixed Cost Considered" />
                  <Bar dataKey="fixedcost.Received" stackId="a" fill={COLORS_FIXEDCOST[0]} name="Fixed Cost Received" />
                  <Bar dataKey="dedicated.Raised" stackId="b" fill={COLORS_DEDICATED[2]} name="Dedicated Raised" />

                  <Bar dataKey="dedicated.Considered" stackId="b" fill={COLORS_DEDICATED[1]} name="Dedicated Considered" />
                  <Bar dataKey="dedicated.Received" stackId="b" fill={COLORS_DEDICATED[0]} name="Dedicated Received" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FixedcostVsDedicatedBarChart;
