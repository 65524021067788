// src/data/currencyData.js

export const currencyData = [
  { code: "INR", name: "Indian Rupee" },
  { code: "USD", name: "US Dollar" },
  { code: "EUR", name: "Euro" },
  { code: "GBP", name: "British Pound" },
  { code: "SGD", name: "Singapore Dollar" },
  { code: "AUD", name: "Australian Dollar" },
  { code: "CAD", name: "Canadian Dollar" },
  { code: "JPY", name: "Japanese Yen" },
  { code: "CNY", name: "Chinese Yuan" },
  { code: "CHF", name: "Swiss Franc" },
  { code: "NZD", name: "New Zealand Dollar" },
  { code: "SEK", name: "Swedish Krona" },
  { code: "MXN", name: "Mexican Peso" },
  { code: "BRL", name: "Brazilian Real" },
  { code: "ZAR", name: "South African Rand" },
  { code: "HKD", name: "Hong Kong Dollar" },
  { code: "KRW", name: "South Korean Won" },
  { code: "TRY", name: "Turkish Lira" },
  { code: "RUB", name: "Russian Ruble" },
  { code: "PLN", name: "Polish Zloty" },
  { code: "MYR", name: "Malaysian Ringgit" },
  { code: "THB", name: "Thai Baht" },
  { code: "IDR", name: "Indonesian Rupiah" },
  { code: "PHP", name: "Philippine Peso" },
  { code: "AED", name: "United Arab Emirates Dirham" },
  { code: "SAR", name: "Saudi Riyal" },
  { code: "DKK", name: "Danish Krone" },
  { code: "NOK", name: "Norwegian Krone" },
  { code: "SEK", name: "Swedish Krona" },
  { code: "BHD", name: "Bahraini Dinar" },
  { code: "QAR", name: "Qatari Rial" },
  { code: "OMR", name: "Omani Rial" },
  { code: "KWD", name: "Kuwaiti Dinar" },
  { code: "LKR", name: "Sri Lankan Rupee" },
  { code: "KES", name: "Kenyan Shilling" },
  { code: "UGX", name: "Ugandan Shilling" },
  { code: "TZS", name: "Tanzanian Shilling" },
  { code: "MWK", name: "Malawian Kwacha" },
];


export const countryData = [
  { code: "IN", name: "India" },
  { code: "US", name: "United States" },
  { code: "SG", name: "Singapore" },
  { code: "CA", name: "Canada" },
  { code: "GB", name: "United Kingdom" },
  { code: "AU", name: "Australia" },
  { code: "DE", name: "Germany" },
  { code: "FR", name: "France" },
  { code: "IT", name: "Italy" },
  { code: "ES", name: "Spain" },
  { code: "JP", name: "Japan" },
  { code: "BR", name: "Brazil" },
  { code: "ZA", name: "South Africa" },
  { code: "RU", name: "Russia" },
  { code: "CN", name: "China" },
  { code: "MX", name: "Mexico" },
  { code: "NG", name: "Nigeria" },
  { code: "KR", name: "South Korea" },
  { code: "SE", name: "Sweden" },
  { code: "NO", name: "Norway" },
  { code: "FI", name: "Finland" },
  { code: "DK", name: "Denmark" },
  { code: "SE", name: "Sweden" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "KR", name: "South Korea" },
  { code: "PH", name: "Philippines" },
  { code: "ID", name: "Indonesia" },
  { code: "TH", name: "Thailand" },
  { code: "TR", name: "Turkey" },
  { code: "EG", name: "Egypt" },
  { code: "PK", name: "Pakistan" },
  { code: "IR", name: "Iran" },
  { code: "VN", name: "Vietnam" },
  { code: "UA", name: "Ukraine" },
  { code: "MY", name: "Malaysia" },
  { code: "CO", name: "Colombia" },
  { code: "PL", name: "Poland" },
  { code: "AR", name: "Argentina" },
  { code: "BE", name: "Belgium" },
  { code: "CH", name: "Switzerland" },
  { code: "AT", name: "Austria" },
  { code: "PT", name: "Portugal" },
  { code: "GR", name: "Greece" },
  { code: "HR", name: "Croatia" },
  { code: "RO", name: "Romania" },
  { code: "BG", name: "Bulgaria" },
  { code: "RS", name: "Serbia" },
  { code: "HU", name: "Hungary" },
  { code: "SK", name: "Slovakia" },
  { code: "CZ", name: "Czech Republic" },
  { code: "CL", name: "Chile" },
  { code: "PE", name: "Peru" },
  { code: "EC", name: "Ecuador" },
  { code: "KE", name: "Kenya" },
  { code: "NG", name: "Nigeria" },
  { code: "DZ", name: "Algeria" },
  { code: "MA", name: "Morocco" },
  { code: "TN", name: "Tunisia" },
  { code: "KW", name: "Kuwait" },
  { code: "QA", name: "Qatar" },
  { code: "OM", name: "Oman" },
  { code: "BH", name: "Bahrain" },
  { code: "JO", name: "Jordan" },
  { code: "LB", name: "Lebanon" },
  { code: "SY", name: "Syria" },
  { code: "IQ", name: "Iraq" },
  { code: "YE", name: "Yemen" },
  { code: "AF", name: "Afghanistan" },
  { code: "NP", name: "Nepal" },
  { code: "BD", name: "Bangladesh" },
  { code: "MM", name: "Myanmar" },
  { code: "KH", name: "Cambodia" },
  { code: "LA", name: "Laos" },
  { code: "KH", name: "Kuwait" },
  { code: "MO", name: "Macau" },
  { code: "MN", name: "Mongolia" },
  { code: "TJ", name: "Tajikistan" },
  { code: "KG", name: "Kyrgyzstan" },
  { code: "TM", name: "Turkmenistan" },
  { code: "UZ", name: "Uzbekistan" },
  { code: "KZ", name: "Kazakhstan" },
];

export const stateData = [
  { code: "AP", name: "Andhra Pradesh" },
  { code: "AR", name: "Arunachal Pradesh" },
  { code: "AS", name: "Assam" },
  { code: "BR", name: "Bihar" },
  { code: "CG", name: "Chhattisgarh" },
  { code: "HR", name: "Haryana" },
  { code: "HP", name: "Himachal Pradesh" },
  { code: "JH", name: "Jharkhand" },
  { code: "KA", name: "Karnataka" },
  { code: "KL", name: "Kerala" },
  { code: "MP", name: "Madhya Pradesh" },
  { code: "MH", name: "Maharashtra" },
  { code: "MN", name: "Manipur" },
  { code: "ML", name: "Meghalaya" },
  { code: "MZ", name: "Mizoram" },
  { code: "NL", name: "Nagaland" },
  { code: "OD", name: "Odisha" },
  { code: "PB", name: "Punjab" },
  { code: "RJ", name: "Rajasthan" },
  { code: "SK", name: "Sikkim" },
  { code: "TN", name: "Tamil Nadu" },
  { code: "TG", name: "Telangana" },
  { code: "UP", name: "Uttar Pradesh" },
  { code: "UK", name: "Uttarakhand" },
  { code: "WB", name: "West Bengal" },
  { code: "AN", name: "Andaman and Nicobar Islands" },
  { code: "CH", name: "Chandigarh" },
  { code: "DN", name: "Dadra and Nagar Haveli" },
  { code: "DD", name: "Daman and Diu" },
  { code: "DL", name: "Delhi" },
  { code: "LD", name: "Lakshadweep" },
  { code: "PY", name: "Puducherry" },
];

export const clientChannel = [
  { code: "dm", name: "Digital Marketing" },
  { code: "ref", name: "Referral" },
  { code: "linkedin", name: "LinkedIn" },
  { code: "telecalling", name: "TeleCalling" },
]
export const SD_INVOICE_STATUS = [
  { code: "4", name: "Cancelled", color: "bg-danger" },
  { code: "5", name: "Considered", color: "bg-secondary" },
  { code: "1", name: "Draft", color: "bg-warning" },
  { code: "2", name: "Paid", color: "bg-success" },
  { code: "3", name: "Partially Paid", color: "bg-info" },
  { code: "0", name: "Pending", color: "bg-primary" },
];

export const SD_PROJECT_TYPE = [
  { code: "fixedcost", name: "Fixed Cost" },
  { code: "dedicated", name: "Dedicated" },
]
export const SD_FY_MONTHS = [
  { value: "", label: 'All' },
  { value: 10, label: 'January' },
  { value: 11, label: 'February' },
  { value: 12, label: 'March' },
  { value: 1, label: 'April' },
  { value: 2, label: 'May' },
  { value: 3, label: 'June' },
  { value: 4, label: 'July' },
  { value: 5, label: 'August' },
  { value: 6, label: 'September' },
  { value: 7, label: 'October' },
  { value: 8, label: 'November' },
  { value: 9, label: 'December' }

];
export const SD_MODE_OF_PAYMENT = [
  { code: "bank", name: "Bank" },
  { code: "payoneer", name: "Payoneer" },
  { code: "paypal", name: "Paypal" },
  { code: "other", name: "Other" },
]
export const SD_STATUS = [
  { code: "0", name: "Active" },
  { code: "1", name: "Inactive" },
  { code: "12", name: "Delete" },
]
export const SD_CAP_FIRST_LETTER = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return "";
  }
};

export const SD_GET_YEAR_DATA = () => {
  const startYear = 2023;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  let financialYearsList = [];
  let endYear = currentMonth >= 4 ? currentYear : currentYear - 1;

  for (let year = endYear; year >= startYear; year--) {
    financialYearsList.push({
      value: `${year}-${year + 1}`,
      label: `${year}-${year + 1}`,
    });
  }

  const currentFinancialYear = `${endYear}-${endYear + 1}`;
  const selectedFY = financialYearsList.find(year => year.value === currentFinancialYear);

  return {
    YEAR_LIST: financialYearsList,
    CURRENT_FINANCIAL_YEAR: currentFinancialYear,
    CURRENT_YEAR: currentYear,
    SELECTED_YEAR: selectedFY
  };
};

export const S_CURRENT_FINACIAL_MONTH = () => {
  const jsCurrentMonth = new Date().getMonth() + 1; // JavaScript months are 0-based, so add 1
  const fyMonthMap = {
    1: 10,  // Jan -> 10
    2: 11,  // Feb -> 11
    3: 12,  // Mar -> 12
    4: 1,   // Apr -> 1
    5: 2,   // May -> 2
    6: 3,   // Jun -> 3
    7: 4,   // Jul -> 4
    8: 5,   // Aug -> 5
    9: 6,   // Sep -> 6
    10: 7,  // Oct -> 7
    11: 8,  // Nov -> 8
    12: 9   // Dec -> 9
  };
  const financialMonth = fyMonthMap[jsCurrentMonth]; // Convert JS month to financial year month
  const defaultMonth = SD_FY_MONTHS.find((m) => m.value === financialMonth);
  return defaultMonth || null;
};

export const S_INR_CURRENCY_SYMBOL = (currencyCode) => {
  return (0).toLocaleString("en", { style: "currency", currency: currencyCode }).replace(/\d|,|\.|\s/g, '');
};
export const S_FORMAT_INR_CURRENCY_AND_AMOUNT = (amount) => {
  const parsedAmount = parseFloat(amount) || 0;
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  }).format(parsedAmount);
};

export const S_FORMAT_CURRENCY_AND_AMOUNT = (currency, amount) => {
  const parsedAmount = parseFloat(amount) || 0;

  return new Intl.NumberFormat(currency === "INR" ? "en-IN" : "en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  }).format(parsedAmount);
};

export const S_DATE_FORMATE = (anydate) => {
  const indian_date = new Date(anydate).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  })
  return indian_date;
}
export const S_GET_CURRENCY_SYMBOL = (currencyCode) => {
  return (0).toLocaleString("en", { style: "currency", currency: currencyCode }).replace(/\d|,|\.|\s/g, '');
};


export const S_RENDER_HTML = ({ description }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: description }} />
  );
};