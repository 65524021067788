import React, { useEffect, useState } from "react";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import Loading from 'react-fullscreen-loading';
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";


export default function Setting() {
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [validate, setValidate] = useState(false);

  // Fetch Settings from API
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(CONST.GET_SETTING_LIST);
      if (response.data.status === "success") {
        const settingsData = response.data.data.reduce((acc, item) => {
          acc[item.meta_key] = item.meta_value;
          return acc;
        }, {});
        setSettings(settingsData);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
      setSettings({});
    }
    setLoading(false);
  };

  // Handle Input Change
  const handleChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  // Handle Form Submission (Update API)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(CONST.UPDATE_SETTING_LIST, settings);
      toast.success("Setting has been updated.");
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error("Error updating settings:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Loading loading={loading} background="rgba(0, 0, 0, 0.5)" loaderColor="#fff" />
      <div className="container-fluid p-0">
        <div className="row">
          <h1 className="h3 mb-5">Manage <strong>Settings</strong></h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form className="auth-form" onSubmit={handleSubmit} autoComplete="off">
                  <div className="row mb-3">
                    <div className="col-6 mb-3">
                      <label className="form-label">PAN Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="PAN Number"
                        name="pan"
                        value={settings.pan || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <label className="form-label">Website</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Website"
                        name="website"
                        value={settings.website || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <label className="form-label">GST Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="GST Number"
                        name="gstin"
                        value={settings.gstin || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <label className="form-label">Address</label>
                      <TextareaAutosize
                        className="form-control"
                        placeholder="Address"
                        name="address"
                        value={settings.address || ""}
                        onChange={handleChange}
                        minRows={2} // Minimum number of rows
                        maxRows={10} // Maximum number of rows before scrolling
                        style={{
                          width: "100%",
                          resize: "none",
                        }}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <label className="form-label">Account</label>
                      <TextareaAutosize
                        className="form-control"
                        placeholder="Account"
                        name="account"
                        value={settings.account || ""}
                        onChange={handleChange}
                        minRows={2} // Minimum number of rows
                        maxRows={10} // Maximum number of rows before scrolling
                        style={{
                          width: "100%",
                          resize: "none",
                        }}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <label className="form-label">Note</label>
                      <TextareaAutosize
                        className="form-control"
                        placeholder="Note"
                        name="note"
                        value={settings.note || ""}
                        onChange={handleChange}
                        minRows={2} // Minimum number of rows
                        maxRows={10} // Maximum number of rows before scrolling
                        style={{
                          width: "100%",
                          resize: "none",
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-left">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4">{" "}</div>
        </div>
      </div>
    </>
  );
}
