import React, { useContext, useState, useEffect } from "react";
import { Table, Button, Form, Pagination, Modal, Spinner, Col, Row } from "react-bootstrap";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import { Link } from "react-router-dom";
import Loading from 'react-fullscreen-loading';
import { AuthContext } from '../../context/AuthContext';
import Select from 'react-select';
import { toast } from "react-toastify";
import useAuthInfo from "../../../hooks/IsAdmin";

import AddEditClient from "./AddEditClient";
 
import {
  countryData,
  currencyData,
  stateData,
  clientChannel
} from "../../includes/staticData";
import { setGlobalModal } from "../modal/GlobalModal";
import ViewClient from "../modal/client/ViewClient";

export default function ManageClients() {
  const { isAdmin, eDepartmentId } = useAuthInfo();
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState("u.id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [loading, setLoading] = useState(false);
  /* SEARCH VARIABLES */
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [searchParams, setSearchParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [status, setStatus] = useState([]);
  const [editClient, setEditClient] = useState(null);
  const [lUserDptId, setLuserDptId] = useState(null);

  useEffect(() => {
    if (isAdmin || lUserDptId) {
      fetchData();
    }
  }, [currentPage, recordsPerPage, sortColumn, sortOrder, searchParams, lUserDptId]);

  useEffect(() => {
    if (!isAdmin) {
      setLuserDptId(eDepartmentId);
    }

  }, [isAdmin]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(CONST.GET_CLIENT_LIST, {
        params: {
          page: currentPage,
          per_page: recordsPerPage,
          sort_by: sortColumn,
          sort_order: sortOrder,
          name: searchParams.userName,
          email: searchParams.email,
          status: searchParams.status,
          department_id: lUserDptId

        }
      });
      if (response.data) {
        setData(response.data.data);
        setTotalRecords(response.data.total);
      } else {
        setData([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setData([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };


  /* TABLE FUNCTIONS  */
  const actionFormatter = (cell, row) => {
    return (
      <>
        <Link to="#" className="action" onClick={() => handleEdit(row)}>
          <i class='bx bx-pencil'></i>
        </Link>
        {/*  <Link to="#" className="action" onClick={() => handleShow(row)}>
          <i class='bx bx-show'></i>
        </Link> */}

        {/* {row.status !== "2" && row.role_id !== 1 && (
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleDelete(row.id)}
          >
            Delete
          </Button>
        )} */}
      </>
    );
  };
  const columns = [
    {
      dataField: "index",
      text: "Sr.no",
      sortable: true,
      onSort: () => handleSort("index"),
    },
    {
      dataField: "name",
      text: "Name",
      sortable: true,
      onSort: () => handleSort("name"),
      formatter: (cell, row) => {
        const client_name = row.name || "N/A";
        const client_company_name = row.company_name || "N/A";
        const client_id = row.id || null;
        return (
          <> <Link to="#" className="action"
            onClick={() =>
              setGlobalModal(
                ViewClient,
                {
                  client_id: client_id,
                },
                {  // Modal Props
                  size: "lg",
                  title: "View Client " + client_name
                }
              )
            }
          >
            {client_name}<br />
          </Link >
            <small>{client_company_name}</small>
          </>
        );
      }
    },
    {
      dataField: "email",
      text: "Email",
      sortable: true,
      onSort: () => handleSort("email"),
      formatter: (cell, row) => {
        return (
          <>
            <div>{row.email || ""}</div>
            <div><small>{row.phone_number || ""}</small></div>
          </>
        )
      }

    },
    ...(isAdmin
      ? [
        {
          dataField: "departments",
          text: "Departments",
          sort: false,
          formatter: (cell) => {
            return cell?.split(',').map((dept, index) => (
              <div key={index}>{dept.trim()}</div>
            ));
          }
        }
      ]
      : [])
    ,


    /* {
      dataField: "phone_number",
      text: "Contact",
      sortable: true,
      onSort: () => handleSort("phone_number"),
    }, */
    /* {
      dataField: "client_added_in",
      text: "Added In",
      sortable: true,
      onSort: () => handleSort("client_added_in"),
      formatter: (cell) => {
        return cell ? `FY - ${cell}` : "-";
      },
    }
    , 
    {
      dataField: "client_channel",
      text: "Channel",
      sortable: false,
      formatter: (cell) => {
        const channel = clientChannel.find((item) => item.code === cell);
        return (channel ? channel.name : cell);
      },
    }
    
    {
      dataField: "gst",
      text: "GST",
      sortable: true,
      onSort: () => handleSort("gst"),
    },*/
    {
      dataField: "status",
      text: "Status",
      sortable: true,
      onSort: () => handleSort("status"),
      formatter: (cell) => {
        let statusText = "";
        let statusClass = "";

        switch (cell) {
          case "0":
            statusText = "Active";
            statusClass = "bg-success";
            break;
          case "1":
            statusText = "Inactive";
            statusClass = "bg-secondary";
            break;
          case "2":
            statusText = "Deleted";
            statusClass = "bg-danger";
            break;
          default:
            statusText = "Unknown";
            statusClass = "bg-light";
        }
        return <span className={`badge ${statusClass}`}>{statusText}</span>;
      },

    },
    {
      dataField: "actions",
      text: "Actions",
      sortable: false,
      formatter: actionFormatter,
    },


  ];
  /* HANDLES */
  const handleSearch = () => {
    setSearchParams({
      name: userName ? userName : "",
      email: email ? email : "",
      status: status ? status.value : "",
    });
    // fetchData();
  };
  const handleReset = () => {
    setUserName(null);
    setEmail("")
    setStatus(null)
    setSearchParams({});
    //fetchData();
    setCurrentPage(1);
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSort = (column) => {
    const order = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
  };
  const handleEdit = (data) => {
    setEditClient(data);
    setShowModal(true);
  };
  const handleShow = (data) => {
    setEditClient(data);
    setShowViewModal(true);
  };

  const handlePageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <>
      <Loading loading={loading} background="rgba(0, 0, 0, 0.5)" loaderColor="#fff" />
      <div className="container-fluid p-0">
        <div className="row mb-5">
          <div className="col-8">
            <h1 className="h3 mb-2">Manage <strong>Clients</strong></h1>
          </div>
          <div className="col-4 text-right">
            <Link className="btn btn-secondary" to="#" onClick={() => {
              setEditClient(null);
              setShowModal(true);
            }} ><i class='bx bx-user-plus'></i>Add Client</Link>
          </div>
        </div>
        <Row className="mb-3">
          {/* Financial Year Dropdown */}
          <Col>
            <Form.Control
              type="text"
              placeholder="Search Name"
              value={userName}
              onChange={handleUserNameChange}
            />
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Search Email"
              value={email}
              onChange={handleEmailChange}
            />
          </Col>
          <Col>
            <Select
              options={[
                { value: "0", label: "Active" },
                { value: "1", label: "InActive" },
                { value: "2", label: "Deleted" },
              ]}
              placeholder="Select Status..."
              onChange={handleStatusChange}
              value={status}
            />
          </Col>

          {/* Search & Reset Buttons */}
          <Col xs="auto">
            <Button variant="primary" onClick={handleSearch}>
              <i className='bx bx-search-alt'></i> {" "} Search
            </Button>
            <Button
              variant="secondary"
              onClick={handleReset}
              className="ml-2"
            >
              <i className='bx bx-x'></i> {" "} Reset
            </Button>
          </Col>
        </Row>

        <div className="row mb-1">
          <div className="card flex-fill pt-3 pb-2">
            <div className="mb-2">
              Showing total {totalRecords} records | Show per
              <select className="m-2" onChange={handlePageChange} value={recordsPerPage}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select> page
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.dataField}
                      onClick={() => col.sortable && handleSort(col.dataField)}
                      style={{ cursor: col.sortable ? "pointer" : "default" }}
                    >
                      {col.text}
                      {col.sortable && (sortColumn === col.dataField ? (sortOrder === "asc" ? " ▲" : " ▼") : "")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  data.length > 0 ? (data.map((item, index) => (
                    <tr key={index}>
                      {columns.map((col) => (
                        <td key={col.dataField} >
                          {col.formatter ? col.formatter(item[col.dataField], item) : item[col.dataField]}
                        </td>
                      ))}
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        No records found.
                      </td>
                    </tr>
                  )
                }

              </tbody>
            </Table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            Showing total {totalRecords} records | Show per
            <select className="m-2" onChange={handlePageChange} value={recordsPerPage}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select> page
          </div>
          <div className="col-md-8 text-right">
            <Pagination className="text-right">
              {[...Array(Math.ceil(totalRecords / recordsPerPage)).keys()].map((num) => (
                <Pagination.Item key={num + 1} active={num + 1 === currentPage} onClick={() => setCurrentPage(num + 1)}>
                  {num + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
        {/* ADD EDIT MODAL */}
        <Modal show={showModal} onHide={() => setShowModal(false)} size="xl" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{editClient ? "Edit Client" : "Add Client"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditClient
              client={editClient}
              onClose={() => setShowModal(false)}
              onSubmit={fetchData}
            />
          </Modal.Body>
        </Modal>
        {/* VIEW MODAL */}
        {/* <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>View Client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewClient
              client={editClient}
              onClose={() => setShowViewModal(false)}

            />
          </Modal.Body>
        </Modal> */}

      </div>
    </>
  );
};


