import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import feather from 'feather-icons';
import '../../../invoice.css';
import Loading from 'react-fullscreen-loading';
import axios from 'axios';
import * as CONST from '../../includes/Constant';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SD_INVOICE_STATUS } from "../../includes/staticData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { AuthContext } from '../../context/AuthContext';
import TextareaAutosize from "react-textarea-autosize";
import { format } from "date-fns";
import Select from 'react-select';

export default function EditInvoice(props) {
  const navigate = useNavigate();
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [editInvoice, setEditInvoice] = useState([]);
  const [settings, setSettings] = useState({});
  const [fileName, setFileName] = useState("");
  const [status, setStatus] = useState(null);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [internalNote, setInternalNote] = useState();
  const [iErrors, setIErrors] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const { invoice_id } = useParams();
  const [payments, setPayments] = useState([
    { amount: "", description: "" } // Initial Row
  ]);
  ////console.log(invoice_id);
  let totalPayment = '';
  let totalGross = '';
  let totalGstAmount = '';

  useEffect(() => {
    feather.replace();
    fetchSettings();
    fetchInvoiceById();
  }, []);

  const invoiceStatusOptions = SD_INVOICE_STATUS
    .filter((invoiceStatus) => !["2", "3", "5"].includes(invoiceStatus.code))
    .map((invoiceStatus) => ({
      label: invoiceStatus.name,
      value: invoiceStatus.code,
    }));

  // Handle input change for a specific row
  const handleChange = (index, field, value) => {
    const updatedPayments = [...payments];
    updatedPayments[index][field] = value;
    setPayments(updatedPayments);
  };
  // Add new row
  const addPaymentRow = () => {
    setPayments([...payments, { amount: "", description: "" }]);
  };

  // Remove row (except first one)
  const removePaymentRow = (index) => {
    const updatedPayments = payments.filter((_, i) => i !== index);
    setPayments(updatedPayments);
  };
  //GET_INVOICE_BY_ID
  const fetchInvoiceById = async () => {
    try {
      setLoading(true);
      const response = await axios.post(CONST.GET_INVOICE_BY_ID, {
        invoice_id: invoice_id,
      });
      if (response) {
        //console.log(response.data.status);
        console.log(response.data.data);
        if (response.data.status === "success") {
          setEditInvoice(response.data.data);
          let eInvoiceDate = response.data.data.invoice_date;
          setInvoiceDate(eInvoiceDate);
          let eInvoiceitems = response.data.data.invoice_items;
          let ePurchaseOrder = response.data.data.purchase_order;
          setPurchaseOrder(ePurchaseOrder);
          let eStatus = response.data.data.status;
          setStatus(eStatus);
          console.log(eStatus);
          //console.log(eInvoiceitems);

          let ePayments = response.data.data.payments;
          // Transform the invoice items into the required format
          const eFormattedItems = eInvoiceitems.map((item, index) => ({
            invoiceSrNo: index + 1,
            invoiceDescription: item.item_description || "",
            invoiceTotal: item.total_amount || "",
            invoiceGross: item.gross_amount || "",
            invoiceCgstRate: item.cgst_rate || "",
            invoiceCgstAmount: item.cgst_amount || "",
            invoiceSgstRate: item.sgst_rate || "",
            invoiceSgstAmount: item.sgst_amount || "",
            invoiceIgstRate: item.igst_rate || "",
            invoiceIgstAmount: item.igst_amount || "",
          }));
          setInvoiceItems(eFormattedItems);
        } else {
          console.error("Error fetching invoice:", response.data.message);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await axios.get(CONST.GET_SETTING_LIST);
      if (response.data.status === 'success') {
        const settingsData = response.data.data.reduce((acc, item) => {
          acc[item.meta_key] = item.meta_value;
          return acc;
        }, {});
        setSettings(settingsData);
      }
    } catch (error) {
      toast.error('Error:', error);
    }
  };

  const getCurrencySymbol = (currencyCode) => {
    return (0).toLocaleString("en", { style: "currency", currency: currencyCode }).replace(/\d|,|\.|\s/g, '');
  };
  const [invoiceItems, setInvoiceItems] = useState([
    { invoiceSrNo: 1, invoiceDescription: "", invoiceTotal: "", invoiceGross: "", invoiceCgstRate: "", invoiceCgstAmount: "", invoiceSgstRate: "", invoiceSgstAmount: "", invoiceIgstRate: "", invoiceIgstAmount: "" }
  ]);
  // Add New Row
  const addRow = () => {
    setInvoiceItems([...invoiceItems, { invoiceSrNo: invoiceItems.length + 1, invoiceDescription: "", invoiceTotal: "", invoiceGross: "", invoiceCgstRate: "", invoiceCgstAmount: "", invoiceSgstRate: "", invoiceSgstAmount: "", invoiceIgstRate: "", invoiceIgstAmount: "" }]);
  };
  // Remove Row
  const removeRow = (index) => {
    const updatedItems = invoiceItems.filter((_, i) => i !== index);
    setInvoiceItems(updatedItems);
  };
  // Handle Input Change
  const handleInputChange = (index, field, value) => {
    let updatedItems = [...invoiceItems];
    updatedItems[index][field] = value;
    if (field === "invoiceGross") {
      const total = parseFloat(value) || 0;
      if (editInvoice.client.country === "IN") {
        const gstRate = 18; // Example GST Rate
        const gstAmount = (total * gstRate) / 100;
        updatedItems[index].invoiceGross = total;
        updatedItems[index].invoiceTotal = total + gstAmount;
        updatedItems[index].invoiceCgstRate = 9;
        updatedItems[index].invoiceCgstAmount = (total * 9) / 100;
        updatedItems[index].invoiceSgstRate = 9;
        updatedItems[index].invoiceSgstAmount = (total * 9) / 100;
        updatedItems[index].invoiceIgstRate = 18;
        updatedItems[index].invoiceIgstAmount = gstAmount;
      } else {
        updatedItems[index].invoiceTotal = total;
      }
    }
    setInvoiceItems(updatedItems);
  };
  totalPayment = invoiceItems.reduce((sum, item) => sum + (parseFloat(item.invoiceTotal) || 0), 0);
  totalGross = invoiceItems.reduce((sum, item) => sum + (parseFloat(item.invoiceGross) || 0), 0);
  totalGstAmount = invoiceItems.reduce((sum, item) => sum + (parseFloat(item.invoiceIgstAmount) || 0), 0);

  const validateInvoiceItems = (invoiceItems) => {
    let tempErrors = invoiceItems.map((item, index) => {
      let iErrors = {};

      // Validate product name
      if (!item.invoiceDescription || item.invoiceDescription.trim() === "") {
        iErrors.invoiceDescription = `Row ${index + 1}: Product name is required`;
        toast.error(`Row ${index + 1}: Product name is required`);
      }

      // Validate invoice total
      if (item.invoiceTotal === "" || isNaN(Number(item.invoiceTotal)) || Number(item.invoiceTotal) < 0) {
        iErrors.invoiceTotal = `Row ${index + 1}: Enter a valid total amount`;
        toast.error(`Row ${index + 1}: Enter a valid total amount`);
      }

      // Ensure client object exists before checking country/state
      if (editInvoice.client && editInvoice.client.country === "IN") {
        if (item.invoiceGross === "" || isNaN(Number(item.invoiceGross)) || Number(item.invoiceGross) < 0) {
          iErrors.invoiceGross = `Row ${index + 1}: Enter a valid gross amount`;
          toast.error(`Row ${index + 1}: Enter a valid gross amount`);
        }

        if (editInvoice.client && editInvoice.client.state === "MH") {
          if (item.invoiceCgstRate === "" || isNaN(Number(item.invoiceCgstRate)) || Number(item.invoiceCgstRate) < 0 || Number(item.invoiceCgstRate) > 100) {
            iErrors.invoiceCgstRate = `Row ${index + 1}: CGST rate must be between 0 and 100`;
            toast.error(`Row ${index + 1}: CGST rate must be between 0 and 100`);
          }
          if (item.invoiceCgstAmount === "" || isNaN(Number(item.invoiceCgstAmount)) || Number(item.invoiceCgstAmount) < 0) {
            iErrors.invoiceCgstAmount = `Row ${index + 1}: Enter a valid CGST amount`;
            toast.error(`Row ${index + 1}: Enter a valid CGST amount`);
          }
          if (item.invoiceSgstRate === "" || isNaN(Number(item.invoiceSgstRate)) || Number(item.invoiceSgstRate) < 0 || Number(item.invoiceSgstRate) > 100) {
            iErrors.invoiceSgstRate = `Row ${index + 1}: SGST rate must be between 0 and 100`;
            toast.error(`Row ${index + 1}: SGST rate must be between 0 and 100`);
          }
          if (item.invoiceSgstAmount === "" || isNaN(Number(item.invoiceSgstAmount)) || Number(item.invoiceSgstAmount) < 0) {
            iErrors.invoiceSgstAmount = `Row ${index + 1}: Enter a valid SGST amount`;
            toast.error(`Row ${index + 1}: Enter a valid SGST amount`);
          }
        } else {
          if (item.invoiceIgstRate === "" || isNaN(Number(item.invoiceIgstRate)) || Number(item.invoiceIgstRate) < 0 || Number(item.invoiceIgstRate) > 100) {
            iErrors.invoiceIgstRate = `Row ${index + 1}: IGST rate must be between 0 and 100`;
            toast.error(`Row ${index + 1}: IGST rate must be between 0 and 100`);
          }
          if (item.invoiceIgstAmount === "" || isNaN(Number(item.invoiceIgstAmount)) || Number(item.invoiceIgstAmount) < 0) {
            iErrors.invoiceIgstAmount = `Row ${index + 1}: Enter a valid IGST amount`;
            toast.error(`Row ${index + 1}: Enter a valid IGST amount`);
          }
        }
      }

      return iErrors;
    });

    setIErrors(tempErrors);
    return tempErrors.every((err) => Object.keys(err).length === 0); // Returns true if no errors
  };
  const handleStatusChange = (selectedOption) => {
    console.log(selectedOption);
    setStatus(selectedOption?.value);
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const actionType = e.nativeEvent.submitter.value;

    if (!validateInvoiceItems(invoiceItems)) {
      //console.log(iErrors);
      return; // Prevent submission if there are errors
    }

    const inVoiceData = (
      {

        invoice_id: parseInt(invoice_id) ?? "",
        invoice_status: status || 0,
        invoice_client_name: editInvoice.client.name ?? "",
        invoice_client_id: editInvoice.client_id ?? "",
        invoice_client_country: editInvoice.client.country ?? "",
        invoice_client_company_name: editInvoice.client.company_name ?? "",
        invoice_client_billing_address: editInvoice.client.billing_address ?? "",
        invoice_client_currency: editInvoice.client.currency ?? "",
        invoice_client_state: editInvoice.client.state ?? "",
        invoice_client_gst_number: editInvoice.client.gst ?? "",
        invoice_client_hsn_number: editInvoice.client.hsn_number ?? "",
        invoice_client_account_details: editInvoice.client.account_detail ?? "",
        invoice_client_bank_details: editInvoice.client.account_details ?? "",
        invoice_client_invoice_date: invoiceDate ? format(invoiceDate, "dd/MM/yyyy") : "",
        invoice_client_purchase_order: purchaseOrder ?? "",
        invoice_client_departments: (editInvoice && editInvoice.department_name) ? editInvoice.department_name.toUpperCase() : "",
        invoice_client_department_id: (editInvoice && editInvoice.department_id) ? editInvoice.department_id : "",
        invoice_client_project: editInvoice?.project_id || 0,
        invoice_client_project_name: editInvoice.project_name || "",
        invoice_client_created_by: authUserData ? authUserData[0].user_id : "1",
        invoice_client_invoice_items: invoiceItems ?? "",
        invoice_client_internal_note: internalNote ?? "",
        invoice_client_file_Name: fileName ?? "",
        invoice_action_type: actionType ?? "",
        invoice_total_payment: totalPayment ?? "0.00",
        invoice_total_gross: totalGross ?? "0.00",
        invoice_total_gst_amount: totalGstAmount ?? "0.00",
        invoice_payments: payments
      }
    );

    setLoading(true);
    console.log(status);
    console.log(inVoiceData);


    try {
      const response = await axios.post(CONST.UPDATE_INVOICE, inVoiceData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === "success") {
        if (actionType === 'download') {
          if (response.data.pdf_link) {
            const iPdfLink = CONST.PDF_LINK + response.data.pdf_link;
            window.open(iPdfLink, '_blank');
          }
        }
        toast.success('Invoice Updated Successfully');
        navigate(CONST.ADMIN_PRE + "invoice");
      } else {
        console.error("Unexpected response:", response);
        toast.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error adding invoice:", error.response?.data || error.message);
      toast.error("Unexpected response:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };
  console.log(invoiceStatusOptions);
  return (
    <>
      <Loading loading={loading} background="rgba(0, 0, 0, 0.5)" loaderColor="#fff" />
      <div className="container mt-0 mb-2">
        <div className="row">
          <div className="col-8">
            <h1 className="h3 mb-2">Edit <strong>Invoice</strong></h1>
          </div>
          <div className="col-4 text-right">
            <Link className="mr-2 btn btn-secondary" to={CONST.ADMIN_PRE + 'invoice/add'}><i className="align-middle" data-feather="dollar-sign"></i>{" "}Create Invoice</Link>
            <Link className="btn btn-primary" to={CONST.ADMIN_PRE + 'invoice'}><i className="align-middle" data-feather="dollar-sign"></i>{" "}View Invoices</Link>
          </div>
        </div>
      </div>


      <div className="container mt-1">
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h5 className="h6 text-center mb-4">TAX INVOICE</h5>


              <div className="row">
                <div className='col-md-4'>
                  <span className='mb-3'><strong>Billing Address</strong></span>
                  <div style={{ fontSize: '12px' }}>
                    <strong>To,</strong><br />
                    {editInvoice.client && editInvoice.client.company_name ? (<span><strong>{editInvoice.client.company_name}</strong>,<br /></span>) : ""}
                    {editInvoice.client && editInvoice.client.name ? (<span><strong>{editInvoice.client.name}</strong>,<br /></span>) : ""}
                    {editInvoice.client && editInvoice.client.billing_address ? (<span>{editInvoice.client.billing_address},<br /></span>) : ""}
                    {
                      (editInvoice.client && editInvoice.client.country === 'IN') ?
                        (
                          <>
                            {editInvoice.client && editInvoice.client.gst ? (<span><strong>GSTIN: </strong>{editInvoice.client.gst}</span>) : ""}
                          </>
                        )
                        : ""
                    }
                  </div>
                </div>
                <div className='col-md-4'></div>
                <div className='col-md-4'>
                  <div style={{ fontSize: '12px' }}>
                    <div ><strong>Invoice Number: </strong>{editInvoice.invoice_number || ""}</div>
                    {editInvoice && (editInvoice.status === 0 || editInvoice.status === 1) ?
                      (
                        <div>
                          <strong>Invoice Date: </strong>
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={invoiceDate}
                            onChange={(date) => setInvoiceDate(date)}
                          />
                        </div>
                      ) : ""
                    }


                    {
                      (editInvoice.client && editInvoice.client.country === 'IN') ?
                        (
                          <>
                            <div>
                              <strong>Banking Details: </strong> {editInvoice.client && editInvoice.client.account_details ? (<span>{editInvoice.client.account_details}</span>) : ""}</div>
                            <div>
                              <strong>HSN Code: </strong> {editInvoice.client && editInvoice.client.hsn_number ? (<span>{editInvoice.client.hsn_number}</span>) : ""}</div>
                            <div>
                              <strong>Purchase Order Number: </strong>
                              <input
                                name="purchaseOrder"
                                value={purchaseOrder}
                                onChange={(e) => setPurchaseOrder(e.target.value)}
                              />
                            </div>
                          </>
                        )
                        : ""
                    }
                  </div>
                </div>
              </div>
              <div className='row mt-5'>
                <div className="invoice-container">
                  <form onSubmit={handleSubmit}>
                    <table className="table table-bordered text-center align-middle table-responsive invoice-table" width="100%">
                      <thead>
                        <tr>
                          <th rowSpan="2" className="srno align-middle">SR. NO.</th>
                          <th rowSpan="2" className=" align-middle description">DESCRIPTION</th>
                          {
                            editInvoice.client && editInvoice.client.country === "IN" && (
                              <>
                                <th rowSpan="2" className="amount align-middle">GROSS</th>
                                {
                                  editInvoice.client && editInvoice.client.state === "MH" ? (
                                    <>
                                      <th colSpan="2" className="">CGST</th>
                                      <th colSpan="2" className="">SGST</th>
                                    </>
                                  ) : (
                                    <th colSpan="2" className="">IGST</th>
                                  )
                                }
                              </>
                            )
                          }
                          <th rowSpan="2" className="amount align-middle">TOTAL</th>
                        </tr>
                        {
                          editInvoice.client && editInvoice.client.country === "IN" && (
                            <tr>
                              {
                                editInvoice.client.state === "MH" ? (
                                  <>
                                    <th className="rate">Rate</th>
                                    <th className="amount"> Amount</th>
                                    <th className="rate">Rate</th>
                                    <th className="amount"> Amount</th>
                                  </>
                                ) : (
                                  <>
                                    <th className="rate">Rate</th>
                                    <th className="amount"> Amount</th>
                                  </>
                                )
                              }


                            </tr>
                          )}
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {
                          invoiceItems.map((item, index) => (
                            <tr key={index}>
                              <td className="srno">{item.invoiceSrNo}</td>
                              <td className="description">
                                <textarea name="invoiceDescription" value={item.invoiceDescription} onChange={(e) => handleInputChange(index, "invoiceDescription", e.target.value)} />

                              </td>

                              {
                                editInvoice.client && editInvoice.client.country === "IN" && (
                                  <>
                                    <td className="amount">
                                      <div class="currency-input">
                                        <span>{editInvoice.client && editInvoice.client.currency ? getCurrencySymbol(editInvoice.client.currency) : ""}</span>
                                        <input type="text" name="invoiceGross" value={item.invoiceGross} onChange={(e) => handleInputChange(index, "invoiceGross", e.target.value)} />
                                      </div>
                                    </td>
                                    {
                                      (
                                        editInvoice.client && editInvoice.client.state === "MH") ? (
                                        <>
                                          <td className="rate">
                                            <div class="percentage-input">
                                              <input type="text" name="invoiceCgstRate" value={item.invoiceCgstRate} readOnly />
                                              <span>%</span>
                                            </div>
                                          </td>
                                          <td className="amount">
                                            <div class="currency-input">
                                              <span>{editInvoice.client && editInvoice.client.currency ? getCurrencySymbol(editInvoice.client.currency) : ""}</span>
                                              <input type="text" name="invoiceCgstAmount" value={item.invoiceCgstAmount} readOnly />
                                            </div>
                                          </td>
                                          <td className="rate">
                                            <div class="percentage-input">
                                              <input type="text" name="invoiceSgstRate" value={item.invoiceSgstRate} readOnly />
                                              <span>%</span>
                                            </div>
                                          </td>
                                          <td className="amount">
                                            <div class="currency-input">
                                              <span>{editInvoice.client && editInvoice.client.currency ? getCurrencySymbol(editInvoice.client.currency) : ""}</span>
                                              <input name="invoiceSgstAmount" type="text" value={item.invoiceSgstAmount} readOnly />
                                            </div>
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td className="rate">
                                            <div class="percentage-input">
                                              <input type="text" name="invoiceIgstRate" value={item.invoiceIgstRate} readOnly />
                                              <span>%</span>
                                            </div>
                                          </td>
                                          <td className="amount">
                                            <div class="currency-input">
                                              <span>{editInvoice.client && editInvoice.client.currency ? getCurrencySymbol(editInvoice.client.currency) : ""}</span>
                                              <input type="text" name="invoiceIgstAmount" value={item.invoiceIgstAmount} readOnly />
                                            </div>
                                          </td>
                                        </>
                                      )
                                    }

                                  </>
                                )}
                              <td className="amount" >
                                <div class="currency-input">
                                  <span>{editInvoice.client && editInvoice.client.currency ? getCurrencySymbol(editInvoice.client.currency) : ""}</span>

                                  <input type="text" name="invoiceTotal" value={item.invoiceTotal} onChange={(e) => {
                                    if (editInvoice.client && editInvoice.client.country !== "IN") {
                                      handleInputChange(index, "invoiceTotal", e.target.value);
                                    }
                                  }} />
                                </div>
                              </td >
                              <td className="removeButton" >
                                {
                                  index && index !== 0 ? (
                                    <button type="button" className="btn btn-outline-danger btn-pill btn-sm" onClick={() => removeRow(index)}>X</button>
                                  ) : ""
                                }
                              </td>
                            </tr>
                          ))}
                      </tbody>

                    </table>
                    <div className='col-12 text-left'>
                      <Button variant="secondary" onClick={addRow}>
                        <i className="align-middle" data-feather="plus"></i> {" "}Add Row
                      </Button>
                    </div>

                    <div className='col-md-12 text-right'>
                      {
                        editInvoice.client && editInvoice.client.country === "IN" ? (
                          <>
                            <div>
                              <span className='text-left'><strong>Gross Payable: </strong></span>
                              <span className='text-right'> {editInvoice.client && editInvoice.client.currency ? getCurrencySymbol(editInvoice.client.currency) : ""}  {totalGross || "0.00"}</span>
                            </div>
                            <div>
                              <span className='text-left'><strong>GST Payable: </strong></span>
                              <span className='text-right'> {editInvoice.client && editInvoice.client.currency ? getCurrencySymbol(editInvoice.client.currency) : ""}  {totalGstAmount || "0.00"}</span>
                            </div>
                          </>
                        ) : ""
                      }
                      <div>
                        <span className='text-left'><strong>Total Payable: </strong></span>
                        <span className='text-right'> {editInvoice.client && editInvoice.client.currency ? getCurrencySymbol(editInvoice.client.currency) : ""} {totalPayment || "0.00"}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-4'>
                        <label className="form-label">Status</label>
                        <Select
                          name="status"
                          classNamePrefix="react-select"
                          options={invoiceStatusOptions}
                          value={invoiceStatusOptions.find((option) => option.value === String(status))}
                          onChange={handleStatusChange}
                          className="react-select-container"
                          placeholder="Select status"
                        />

                      </div>
                    </div>
                    <div className='col-12 text-center'>

                      <button type="submit" value="update" className="btn btn-primary mr-2"><i class='bx bx-save'></i> {" "}Save & Update </button>
                      <button type="submit" value="download" className="btn btn-secondary mr-2" ><i class='bx bxs-download'></i> {" "}Save & Download</button>
                      <button type="submit" value="draft" className="btn btn-success mr-2" ><i class='bx bxs-purchase-tag'></i> {" "}Save as Draft</button>
                    </div>

                  </form>
                </div>
              </div>

            </div>
          </div>
        </div >
      </div >

    </>
  );
}
