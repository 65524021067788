import React, { useEffect, useState } from 'react'
import * as CONST from "../../../includes/Constant";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import { S_FORMAT_INR_CURRENCY_AND_AMOUNT } from '../../../includes/staticData';
import { Link } from 'react-router-dom';
import BlockLoading from '../../../blocks/BlockLoading';

export default function StatsVendorPayments({ searchParams }) {
  // console.log(searchParams);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchVendorPaymentHistory();
  }, [searchParams]);

  const fetchVendorPaymentHistory = async () => {
    setLoading(true);
    try {
      const response = await axios.get(CONST.DASH_LATEST_VENDOR_PAYMENT_HISTORY, {
        params: {
          sort_by: "id",
          sort_order: "desc",
          year: searchParams.year || "",
          department_id: searchParams.department_id || "",
          limit: 5,
          month: searchParams.month || "",
        }
      });
      if (response.data) {
        setData(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading ? (
        <div className="row"><BlockLoading /></div>
      ) : (
        <div className="card flex-fill">
          <div className="card-header">
            <div className="card-actions float-end">
              <div className="dropdown position-relative">
                <Link href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" className="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-horizontal align-middle"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                </Link>

                <div className="dropdown-menu dropdown-menu-end">
                  <Link className="dropdown-item" to={CONST.ADMIN_PRE + "vendor/payments"}>View All</Link>

                </div>
              </div>
            </div>
            <h5 className="card-title mb-0">Latest Vendor Payments</h5>
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <Table className="striped bordered hover responsive">
              <thead className="bg-gray-100">
                <tr>
                  <th className="p-2">Vendor Name</th>
                  <th className="p-2">Payment</th>
                  <th className="p-2">Date</th>
                </tr>
              </thead>
              <tbody>
                {data.slice().reverse().map((item, idx) => (

                  <tr key={idx}>
                    <td className="p-2">{item.vendor_name}</td>
                    <td className="p-2">{S_FORMAT_INR_CURRENCY_AND_AMOUNT(item.amount || 0)}</td>
                    <td className="p-2">
                      {item.payment_month ? new Date(item.payment_month).toLocaleDateString("en-GB", { month: "short", year: "numeric" }) : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

          )}
        </div>
      )
      }
    </>
  )
}
