import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
function Footer() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  let isToken = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
    }
  }
  return (
    <>
      {isToken && isToken ?
        <>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row text-muted">
                <div className="col-6 text-start">
                  <p className="mb-0">
                    <Link className="text-muted" href="https://eluminoustechnologies.com/" target="_blank"><strong>eLuminous Technologies Pvt. Ltd.</strong></Link>
                  </p>
                </div>
                <div className="col-6 text-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      Copyright © 2003 -{new Date().getFullYear()}
                    </li>
                    {/* <li className="list-inline-item">
                  <Link className="text-muted" href="https://adminkit.io/" target="_blank">Help Center</Link>
                </li>
                <li className="list-inline-item">
                  <Link className="text-muted" href="https://adminkit.io/" target="_blank">Privacy</Link>
                </li>
                <li className="list-inline-item">
                  <Link className="text-muted" href="https://adminkit.io/" target="_blank">Terms</Link>
                </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </> : ""}

    </>
  )
}

export default Footer