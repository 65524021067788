import React, { useContext, useState, useEffect } from "react";
import { Table, Button, Form, Pagination, Modal, Spinner, Col, Row } from "react-bootstrap";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import Loading from 'react-fullscreen-loading';
import { AuthContext } from '../../context/AuthContext';
import Select from 'react-select';
import useAuthInfo from "../../../hooks/IsAdmin";
import { SD_GET_YEAR_DATA, SD_FY_MONTHS, S_CURRENT_FINACIAL_MONTH, S_FORMAT_CURRENCY_AND_AMOUNT } from "../../includes/staticData";

export default function ManageExchange() {
  const { isAdmin, eDepartmentId, eUser_id } = useAuthInfo();
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(30);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [loading, setLoading] = useState(false);
  /* SEARCH VARIABLES */
  const [searchParams, setSearchParams] = useState({});
  const [financialYears, setFinancialYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const { YEAR_LIST, CURRENT_FINANCIAL_YEAR, CURRENT_YEAR, SELECTED_YEAR } = SD_GET_YEAR_DATA();
  const [currentFinancialYears, setCurrentFinancialYears] = useState([]);


  useEffect(() => {
    console.log(CURRENT_FINANCIAL_YEAR)
    const defaultParams = {
      year: CURRENT_FINANCIAL_YEAR,
      month: S_CURRENT_FINACIAL_MONTH().value || "",
    };
    setSearchParams(defaultParams);
    if (YEAR_LIST) setFinancialYears(YEAR_LIST);
    if (CURRENT_FINANCIAL_YEAR) setCurrentFinancialYears(CURRENT_FINANCIAL_YEAR);
    if (SELECTED_YEAR) setSelectedYear(SELECTED_YEAR);

    setSelectedMonth(S_CURRENT_FINACIAL_MONTH());
    setCurrentMonth(S_CURRENT_FINACIAL_MONTH());
    setSearchParams(defaultParams);

  }, []);

  // Runs when dependencies change
  useEffect(() => {
    console.log(Object.keys(searchParams).length);
    if (Object.keys(searchParams).length > 0) {
      fetchData();
    }
  }, [currentPage, recordsPerPage, sortColumn, sortOrder, searchParams]);


  const fetchData = async (param) => {
    setLoading(true);
    try {
      console.log(searchParams);
      const response = await axios.get(CONST.GET_EXCHANGE_LIST, {
        params: {
          year: searchParams.year,
          month: searchParams?.month || "",
          page: currentPage,
          per_page: recordsPerPage,
          sort_by: sortColumn,
          sort_order: sortOrder,
        }
      });
      if (response.data) {
        setData(response.data.data);
        setTotalRecords(response.data.total);
      } else {
        setData([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setData([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };



  const columns = [
    {
      dataField: "sr",
      text: "Sr.no",
      sortable: true,
      onSort: () => handleSort("sr"),
    },
    {
      dataField: "fetched_date",
      text: "Date",
      sort: true,
      onSort: () => handleSort("fetched_date"),
      formatter: (cell) => {
        if (!cell) return "";
        const date = new Date(cell);
        return date.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" });
      }
    },
    {
      dataField: "USD",
      text: "USD",
      sortable: true,
      onSort: () => handleSort("USD"),
      formatter: (cell, row) => {
        const amount = S_FORMAT_CURRENCY_AND_AMOUNT("USD", row.USD)
        return (amount)
      }
    },
    {
      dataField: "GBP",
      text: "GBP",
      sortable: true,
      onSort: () => handleSort("GBP"),
      formatter: (cell, row) => {
        const amount = S_FORMAT_CURRENCY_AND_AMOUNT("GBP", row.GBP)
        return (amount)
      }
    },
    {
      dataField: "EUR",
      text: "EUR",
      sortable: true,
      onSort: () => handleSort("EUR"),
      formatter: (cell, row) => {
        const amount = S_FORMAT_CURRENCY_AND_AMOUNT("EUR", row.EUR)
        return (amount)
      }
    },
    {
      dataField: "SGD",
      text: "SGD",
      sortable: true,
      onSort: () => handleSort("SGD"),
      formatter: (cell, row) => {
        const amount = S_FORMAT_CURRENCY_AND_AMOUNT("SGD", row.SGD)
        return (amount)
      }
    },
    {
      dataField: "ZAR",
      text: "ZAR",
      sortable: true,
      onSort: () => handleSort("ZAR"),
      formatter: (cell, row) => {
        const amount = S_FORMAT_CURRENCY_AND_AMOUNT("ZAR", row.ZAR)
        return (amount)
      }
    },
    {
      dataField: "NZD",
      text: "NZD",
      sortable: true,
      onSort: () => handleSort("NZD"),
      formatter: (cell, row) => {
        const amount = S_FORMAT_CURRENCY_AND_AMOUNT("NZD", row.NZD)
        return (amount)
      }
    },
    {
      dataField: "CAD",
      text: "CAD",
      sortable: true,
      onSort: () => handleSort("CAD"),
      formatter: (cell, row) => {
        const amount = S_FORMAT_CURRENCY_AND_AMOUNT("CAD", row.CAD)
        return (amount)
      }
    },
    {
      dataField: "AUD",
      text: "AUD",
      sortable: true,
      onSort: () => handleSort("AUD"),
      formatter: (cell, row) => {
        const amount = S_FORMAT_CURRENCY_AND_AMOUNT("AUD", row.AUD)
        return (amount)
      }
    },





  ];
  /* HANDLES */
  const handleSearch = () => {
    const newParams = {
      year: selectedYear ? selectedYear.value : "",
      month: selectedMonth ? selectedMonth.value : "",
    };
    setSearchParams(newParams);

  };

  const handleReset = () => {
    console.log(currentMonth);
    console.log(selectedMonth);

    setSelectedMonth(currentMonth || "");
    const resetParams = {
      year: CURRENT_FINANCIAL_YEAR,
      month: currentMonth?.value || "",
    };
    setSearchParams(resetParams);
    setCurrentPage(1);

  };
  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
  };
  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
  };

  const handleSort = (column) => {
    const order = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
  };


  const handlePageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };


  return (
    <>
      <Loading loading={loading} background="rgba(0, 0, 0, 0.5)" loaderColor="#fff" />
      <div className="container-fluid p-0">
        <div className="row mb-5">
          <div className="col-8">
            <h1 className="h3 mb-2">Exchange <strong>Rates</strong></h1>
          </div>
          <div className="col-4 text-right">


          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 d-flex justify-content-end">
            <Row className="  justify-content-end w-100">
              <Col xl={3} md={4} sm={6}>
                <Select
                  options={financialYears}
                  placeholder="Financial Year..."
                  onChange={handleYearChange}
                  value={selectedYear}
                />
              </Col>
              <Col xl={3} md={4} sm={6}>
                <Select
                  options={SD_FY_MONTHS}
                  placeholder="Select Month"
                  onChange={handleMonthChange}
                  value={selectedMonth}
                />
              </Col>
              <Col xl="auto" className="d-flex align-items-center">
                <Button variant="primary" onClick={handleSearch} className="me-2">
                  <i className="bx bx-search-alt"></i> Search
                </Button>
                <Button variant="secondary" onClick={handleReset}>
                  <i className="bx bx-x"></i> Reset
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <div className="row mb-1">
          <div className="card flex-fill pt-3 pb-2">
            <Table striped bordered hover>
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.dataField}
                      onClick={() => col.sortable && handleSort(col.dataField)}
                      style={{ cursor: col.sortable ? "pointer" : "default" }}
                    >
                      {col.text}
                      {col.sortable && (sortColumn === col.dataField ? (sortOrder === "asc" ? " ▲" : " ▼") : "")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  data.length > 0 ? (data.map((item, index) => (
                    <tr key={index}>
                      {columns.map((col) => (
                        <td key={col.dataField} >
                          {col.formatter ? col.formatter(item[col.dataField], item) : item[col.dataField]}
                        </td>
                      ))}
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        No records found.
                      </td>
                    </tr>
                  )
                }

              </tbody>
            </Table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            Showing total {totalRecords}
            <select className="m-2" onChange={handlePageChange} value={recordsPerPage}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select> records
          </div>
          <div className="col-md-8 text-right">
            <Pagination className="text-right">
              {[...Array(Math.ceil(totalRecords / recordsPerPage)).keys()].map((num) => (
                <Pagination.Item key={num + 1} active={num + 1 === currentPage} onClick={() => setCurrentPage(num + 1)}>
                  {num + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>

      </div>
    </>
  );
};


