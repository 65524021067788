// src/hooks/useAuthInfo.js
import { useContext } from "react";
import { AuthContext } from "../component/context/AuthContext";

const useAuthInfo = () => {
  const [authUserData] = useContext(AuthContext);
  const user = authUserData?.[0] || {};

  return {
    isAdmin: user.isAdmin === 'yes',
    eDepartmentId: user.dept_id || null,
    eUser_id: user.user_id || 1,
    euser: user, // (optional) if you want full access
  };
};

export default useAuthInfo;
