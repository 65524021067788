import React, { useContext, useState, useEffect } from "react";
import { Table, Button, Form, Pagination, Modal, Spinner, Col, Row } from "react-bootstrap";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import { Link } from "react-router-dom";
import Loading from 'react-fullscreen-loading';
import { AuthContext } from '../../context/AuthContext';
import Select from 'react-select';
import { toast } from "react-toastify";
import useAuthInfo from "../../../hooks/IsAdmin";
import { SD_CAP_FIRST_LETTER } from "../../includes/staticData";

import {
  countryData,
  currencyData,
  stateData,
  projectChannel
} from "../../includes/staticData";
import AddEditProject from "./AddEditProject";

export default function ManageProjects() {
  const { isAdmin, eDepartmentId } = useAuthInfo();
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState("p.id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [clientData, setClientData] = useState([]);
  /* SEARCH VARIABLES */
  const [userName, setUserName] = useState("");
  const [clientName, setClientName] = useState("");
  const [searchParams, setSearchParams] = useState({});

  const [showViewModal, setShowViewModal] = useState(false);
  const [status, setStatus] = useState([]);
  const [editProject, setEditProject] = useState(null);
  const [lUserDptId, setLuserDptId] = useState(null);


  //console.log(eDepartmentId);
  useEffect(() => {
    if (!isAdmin) {
      setLuserDptId(eDepartmentId);
    }
  }, [eDepartmentId, isAdmin]);

  useEffect(() => {
    if (isAdmin || lUserDptId !== null) {
      fetchData();
    }
  }, [currentPage, recordsPerPage, sortColumn, sortOrder, searchParams, lUserDptId]);



  const fetchData = async () => {
    setLoading(true);
    console.log(searchParams);
    try {
      const response = await axios.get(CONST.GET_PROJECT_LIST, {
        params: {
          page: currentPage,
          per_page: recordsPerPage,
          sort_by: sortColumn,
          sort_order: sortOrder,
          project_name: searchParams.name,
          client_name: searchParams.client_name,
          status: searchParams.status,
          department_id: lUserDptId

        }
      });
      if (response.data) {
        setData(response.data.data);
        setTotalRecords(response.data.total);
      } else {
        setData([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setData([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };


  /* TABLE FUNCTIONS  */
  const actionFormatter = (cell, row) => {
    return (
      <>
        <Link to="#" className="action" onClick={() => handleEdit(row)}>
          <i class='bx bx-pencil'></i>
        </Link>
      </>
    );
  };
  const columns = [
    {
      dataField: "index",
      text: "Sr.no",
      sortable: true,
      onSort: () => handleSort("index"),
    },
    {
      dataField: "project_name",
      text: "Project Name",
      sortable: true,
      onSort: () => handleSort("project_name"),
      formatter: (cell, row) => {
        return SD_CAP_FIRST_LETTER(row.project_name || null);
      }
    },
    {
      dataField: "client.client_name",
      text: "Client Info",
      sort: true,
      onSort: () => handleSort('client.client_name'),
      formatter: (cell, row) => {
        const client_name = row.client.client_name;
        const client_email = row.client.email;
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: `${client_name} `,
            }}
          />
        );
      },
    },
    {
      dataField: "project_type",
      text: "Project Type",
      sortable: true,
      onSort: () => handleSort("project_type"),
      formatter: (cell, row) => {
        return SD_CAP_FIRST_LETTER(row.project_type || null);
      }
    },
    ...(isAdmin
      ? [{
        dataField: "department",
        text: "Department",
        sort: false,
        formatter: (cell) => cell?.department_name || "-"
      }]
      : [])
    ,
    {
      dataField: "project_status",
      text: "Status",
      sortable: true,
      onSort: () => handleSort("project_status"),
      formatter: (cell) => {
        let statusText = "";
        let statusClass = "";
        //console.log(cell);
        switch (cell) {
          case 0:
            statusText = "Active";
            statusClass = "bg-success";
            break;
          case 1:
            statusText = "Inactive";
            statusClass = "bg-secondary";
            break;
          case 2:
            statusText = "Deleted";
            statusClass = "bg-danger";
            break;
          default:
            statusText = "Unknown";
            statusClass = "bg-light";
        }
        return <span className={`badge ${statusClass}`}>{statusText}</span>;
      },

    },
    {
      dataField: "actions",
      text: "Actions",
      sortable: false,
      formatter: actionFormatter,
    },


  ];
  /* HANDLES */
  const handleSearch = () => {
    setSearchParams({
      name: userName ? userName : "",
      client_name: clientName ? clientName : "",
      status: status ? status.value : "",
    });
    // fetchData();
  };
  const handleReset = () => {
    setUserName(null);
    setClientName("")
    setStatus(null)
    setSearchParams({});
    //fetchData();
    setCurrentPage(1);
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };
  const handleClientNameChange = (e) => {
    setClientName(e.target.value);
  };

  const handleSort = (column) => {
    const order = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
  };
  const handleEdit = (data) => {
    setSelectedProject(data);
    setShowAddEditModal(true);
  };
  const handleShow = (data) => {
    setEditProject(data);
    setShowViewModal(true);
  };

  const handlePageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <>
      <Loading loading={loading} background="rgba(0, 0, 0, 0.5)" loaderColor="#fff" />
      <div className="container-fluid p-0">
        <div className="row mb-5">
          <div className="col-8">
            <h1 className="h3 mb-2">Manage <strong>Projects</strong></h1>
          </div>
          <div className="col-4 text-right">
            <Link className="btn btn-secondary" to="#" onClick={() => {
              setSelectedProject(null);
              setShowAddEditModal(true);
            }} ><i class='bx bx-user-plus'></i>Add Project</Link>
          </div>
        </div>
        <Row className="mb-3">

          {/* Financial Year Dropdown */}
          <Col>
            <Form.Control
              type="text"
              placeholder="Search Name"
              value={userName}
              onChange={handleUserNameChange}
            />
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Client Name"
              value={clientName}
              onChange={handleClientNameChange}
            />
          </Col>
          <Col>
            <Select
              options={[
                { value: "0", label: "Active" },
                { value: "1", label: "InActive" },
                { value: "2", label: "Deleted" },
              ]}
              placeholder="Select Status..."
              onChange={handleStatusChange}
              value={status}
            />
          </Col>

          {/* Search & Reset Buttons */}
          <Col xs="auto">
            <Button variant="primary" onClick={handleSearch}>
              <i className='bx bx-search-alt'></i> {" "} Search
            </Button>
            <Button
              variant="secondary"
              onClick={handleReset}
              className="ml-2"
            >
              <i className='bx bx-x'></i> {" "} Reset
            </Button>
          </Col>
        </Row>

        <div className="row mb-1">
          <div className="card flex-fill pt-3 pb-2">
            <div className="mb-2">
              Showing total {totalRecords} records | Show per
              <select className="m-2" onChange={handlePageChange} value={recordsPerPage}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select> page
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.dataField}
                      onClick={() => col.sortable && handleSort(col.dataField)}
                      style={{ cursor: col.sortable ? "pointer" : "default" }}
                    >
                      {col.text}
                      {col.sortable && (sortColumn === col.dataField ? (sortOrder === "asc" ? " ▲" : " ▼") : "")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  data.length > 0 ? (data.map((item, index) => (
                    <tr key={index}>
                      {columns.map((col) => (
                        <td key={col.dataField} >
                          {col.formatter ? col.formatter(item[col.dataField], item) : item[col.dataField]}
                        </td>
                      ))}
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        No records found.
                      </td>
                    </tr>
                  )
                }

              </tbody>
            </Table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            Showing total {totalRecords} records | Show per
            <select className="m-2" onChange={handlePageChange} value={recordsPerPage}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select> page
          </div>
          <div className="col-md-8 text-right">
            <Pagination className="text-right">
              {[...Array(Math.ceil(totalRecords / recordsPerPage)).keys()].map((num) => (
                <Pagination.Item key={num + 1} active={num + 1 === currentPage} onClick={() => setCurrentPage(num + 1)}>
                  {num + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
        {/* ADD EDIT MODAL */}
        <Modal
          show={showAddEditModal}
          onHide={() => {
            setShowAddEditModal(false);
            setSelectedProject(null);
          }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton >
            <Modal.Title>
              {selectedProject ? "Edit Project" : "Add Project"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditProject
              project={selectedProject}
              clientData={clientData}
              onClose={() => {
                setShowAddEditModal(false);
                setSelectedProject(null);
              }}
              onSubmit={fetchData}
            />
          </Modal.Body>
        </Modal>


      </div>
    </>
  );
};


