class Form {
    /**
     * Validate Login
     * @param str
     * @returns boolean
     */
    static validEmail(str) {
        let regex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return regex.test(str);
    }

    /**
     * Minimum length of string
     * @param str
     * @param length
     * @returns
     */
    static containsSpecialChars(str) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        /*  //console.log(specialChars.test(str)); */
        return specialChars.test(str);
    }

    static minLength(str, length) {
        let isInvalid = false;

        if (str.length < length) {
            isInvalid = true;
        }
        /*  //console.log("minLength")
             //console.log(isInvalid) */
        return isInvalid;
    }

    static isNumber(str) {
        const checkIsNum = /^[0-9\b]+$/;
        return checkIsNum.test(str);
    }



    /**
     * Form Validator
     * @param  obj
     * @returns
     */
    static validator(obj) {
        let keys = Object.entries(obj);

        let results = [];
        let validations = null;

        keys.map((key) => {
            //console.log(key[1]);
            if ("isRequired" in key[1] && key[1].isRequired) {
                if (key[1].value.length === 0) {
                    results.push({
                        [key[0]]: [`The ${key[1].label} is required.`],
                        /* [key[0]]: [`This is required.`], */
                    });
                } else {
                    if ("isSymbol" in key[1] && key[1].isSymbol) {
                        let isSymbol = Form.containsSpecialChars(key[1].value);
                        /* //console.log(isSymbol);
                                    //console.log("isSymbol"); */
                        if (!isSymbol) {
                            /* //console.log("in isSymbol false"); */
                            results.push({
                                [key[0]]: [
                                    `The ${key[1].label} must be contain one special character.`,
                                ],
                            });
                        } else {
                            /*  //console.log("in isSymbol true"); */
                        }
                    }

                    if ("isEmail" in key[1] && key[1].isEmail) {
                        let isValidEmail = Form.validEmail(key[1].value);

                        if (!isValidEmail) {
                            results.push({
                                [key[0]]: [`The ${key[1].label} must be valid email.`],
                            });
                        }
                    }
                    if (
                        "minLength" in key[1] &&
                        Form.minLength(key[1].value, key[1].minLength)
                    ) {
                        results.push({
                            [key[0]]: [
                                `The ${key[1].label} must at least ${key[1].minLength} characters.`,
                            ],
                        });
                    }
                }
            } else if ("isEmail" in key[1]) {
                let isValidEmail = Form.validEmail(key[1].value);

                if (!isValidEmail) {
                    results.push({
                        [key[0]]: [`The ${key[1].label} must be valid email`],
                    });
                }
            } else if (
                "minLength" in key[1] &&
                Form.minLength(key[1].value, key[1].minLength)
            ) {
                results.push({
                    [key[0]]: [
                        `The ${key[1].label} must at least ${key[1].minLength} characters.`,
                    ],
                });
            } else if ("isSymbol" in key[1] && key[1].isSymbol) {
                let isSymbol = Form.containsSpecialChars(key[1].value);
                /*    //console.log(isSymbol);
                        //console.log("isSymbol 2"); */
                if (!isSymbol) {
                    results.push({
                        [key[0]]: [
                            `The ${key[1].label} must be contain one special character.`,
                        ],
                    });
                }
            }
            else if ("isNumber" in key[1] && key[1].isNumber) {
                let isNumber = Form.isNumber(key[1].value);
                /*    //console.log(isSymbol);
                        //console.log("isSymbol 2"); */
                if (!isNumber) {
                    results.push({
                        [key[0]]: [
                            `The ${key[1].label} must be numeric.`,
                        ],
                    });
                }
            }


            return results;
        });

        results = Object.assign({}, ...results.map((result) => result));

        if (Object.keys(results).length > 0) {
            validations = {
                errors: results,
            };
        } else {
            validations = null;
        }
        /*   //console.log(validations) */
        return validations;
    }
}

export default Form;