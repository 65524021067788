import React, { useState } from "react";
import { S_FORMAT_INR_CURRENCY_AND_AMOUNT } from "../../../includes/staticData";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import BlockLoading from "../../../blocks/BlockLoading";
import RaisedVsReceived from "../stats/RaisedVsReceived";
import VendorsSummery from "../stats/VendorsSummery";
import FixedcostMonthlySummary from "../stats/FixedcostMonthlySummary";
import DedicatedMonthlySummary from "../stats/DedicatedMonthlySummary";

const DashboardCard = ({ title, values = [], loading, currency = false, view, split = false, modal_size = 'xl' }) => {

  const [showModal, setShowModal] = useState(false);
  const [selectedView, setSelectedView] = useState(null);

  const handleView = () => () => {
    setSelectedView(view);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedView(null);
  };

  const viewComponents = {
    raisedVsReceived: RaisedVsReceived,
    vendorsSummery: VendorsSummery,
    fixedcostMonthlySummary: FixedcostMonthlySummary,
    dedicatedMonthlySummary: DedicatedMonthlySummary,
  };

  const SelectedComponent = viewComponents[selectedView];

  return (
    <>
      <div className="row">
        <div className="col-12  ">
          <div className="card flex-fill">
            <div className="card-body">
              <div className="row mb-1 align-items-center">
                <div className="col mt-0">
                  <h5 className="card-title">{title}
                    {view && (

                      <span className="badge badge-primary-light">
                        <Link onClick={handleView()}>
                          <i className="bx bx-info-circle"></i>
                        </Link>
                      </span>

                    )}</h5>
                </div>
                <div className="col-auto">
                  <div className="stat"><i className="bx bx-rupee"></i></div>
                </div>
              </div>

              {loading ? (
                <BlockLoading />
              ) : (
                <>
                  {Array.isArray(values) && values.length > 0 ? (
                    <>
                      <div className="d-flex justify-content-between flex-wrap">
                        {values.map((item, index) => (
                          <div key={index} className="me-3">
                            <strong>{item.label}</strong>
                          </div>
                        ))}
                      </div>
                      <div className="d-flex justify-content-between flex-wrap">
                        {values.map((item, index) => (
                          <div key={index} className="me-3">
                            <h5 className="mt-0 mb-2">{S_FORMAT_INR_CURRENCY_AND_AMOUNT(item.amount || 0)}</h5>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <h5 className="mt-0 mb-1">{S_FORMAT_INR_CURRENCY_AND_AMOUNT(values || 0)}</h5>
                  )}

                </>
              )}


            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        size={modal_size}
        centered
        backdrop="static"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{title || ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {SelectedComponent ? <SelectedComponent /> : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashboardCard;