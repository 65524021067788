import React, { useContext, useState } from 'react'
import Form from '../includes/Forms'
import * as CONST from '../includes/Constant'
import axios from "axios";
import { toast } from "react-toastify";
import Loading from 'react-fullscreen-loading';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";
import { jwtDecode } from "jwt-decode";


function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  /* Validate Login */
  const validateForgotPassword = async () => {
    let isValid = true;
    let validator = Form.validator({
      email: {
        value: email,
        isRequired: true,
        isEmail: true,
        label: "email",
      },

    });
    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };
  const frmAuthenticate = async (e) => {
    e.preventDefault();
    const validate = await validateForgotPassword();
    if (validate) {
      setLoading(true);
      await axios.post(CONST.FORGOT_PASSWORD, {
        email: email,
        reset_url: CONST.RESET_PASSWORD
      })
        .then((response) => {
          if (response) {
            setLoading(false);
            const status = response.data.status;
            if (status === 'error') {
              toast.error(response.data.message);
            } else if (status === "success") {
              toast.success("Password Recovery email has been sent. Please check the email.");
            }
            else {
              toast.error("Something went wrong. Please try again.");
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again.");
        });
    }
  }
  //console.log(authUserData);
  if (authUserData) {
    let isToken = authUserData[0].token ? authUserData[0].token : "";
    //console.log(isToken);
    if (isToken) {
      navigate("/admin/dashboard");
    }
  }

  return (
    <>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <main className="d-flex w-100">
        <div className="container d-flex flex-column mt-5">
          <div className="row">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-5">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/eluminous-pvt-ltd.png"
                    }
                    alt="logo"
                    style={{ width: "250px" }}
                    className="img-fluid"
                  />
                  <h1 className="h2 mt-4">Password Recovery</h1>
                  <p className="lead mt-3">
                    Have you forgotten your password? Simply enter the email
                    associated with your account in the form below and we'll
                    send you the instructions to reset your password.
                  </p>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-3">
                      <form
                        className="auth-form"
                        method="POST"
                        onSubmit={frmAuthenticate}
                        autoComplete={"off"}
                      >
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <input
                            type="email"
                            className={`form-control form-control-lg ${
                              validate.validate && validate.validate.email
                                ? "is-invalid "
                                : ""
                            }`}
                            id="email"
                            name="email"
                            value={email}
                            placeholder="your@email.com"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div className="d-grid gap-2 mt-3">
                          <button
                            type="submit"
                            id="signin"
                            className="btn btn-lg btn-primary"
                          >
                            Send me new password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="text-center mb-3">
                  Not Needed. <Link to="/login">Let me login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ForgotPassword