import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import axios from "axios";
import BlockLoading from "../../../blocks/BlockLoading";
import * as CONST from "../../../includes/Constant";
import { S_FORMAT_INR_CURRENCY_AND_AMOUNT } from "../../../includes/staticData";


const COLORS = ["#3b82f6", "#10b981", "#f59e0b", "#ef4444", "#8b5cf6", "#0ea5e9"];

const InvoiceStatusLineChart = ({ searchParams }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchInvoiceStatusLineChart = async () => {
    try {
      setLoading(true);
      const response = await axios.get(CONST.DASH_INVOICE_STATUS_LINE_CHART, {
        params: searchParams
      });
      if (response.data && Array.isArray(response.data.data)) {
        setChartData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching invoice status chart:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoiceStatusLineChart();
  }, [searchParams]);

  const lineKeys = Object.keys(chartData[0] || {}).filter((key) => key !== "month");
  const STATUS_COLORS = {
    "Vendor Payment": "#E91E63",       // Rose Red
    "Considered": "#6A5ACD",      // Emerald Green
    "Raised": "#FF9800",           // Amber Orange
    "Received": "#27ae60",            // Blue
    "Partially Paid": "#17a2b8", // Purple
    "Dev sale": "#2ecc71",         // Orange
    "Resources": "#03A9F4",         // Orange
  };


  return (
    <>
      {loading ? (
        <div className="row"><BlockLoading /></div>
      ) : (
        <div className="card flex-fill w-100">
          <div class="card-header">

            <h5 class="card-title mb-0">FY Movement</h5>
          </div>
          <div className="card-body pt-2 pb-3">
            <div className="chart chart-sm">
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={chartData} margin={{ top: 20, right: 10, left: 50, bottom: 0 }}>
                  {/* Define gradients dynamically for each line */}
                  <defs>
                    {lineKeys.map((key) => {
                      const color = STATUS_COLORS[key] || "#64748b"; // fallback: Slate gray
                      return (
                        <linearGradient key={key} id={`color-${key}`} x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0%" stopColor={color} stopOpacity={0.4} />
                          <stop offset="100%" stopColor={color} stopOpacity={0} />
                        </linearGradient>
                      );
                    })}
                  </defs>

                  <XAxis dataKey="month" axisLine={false} tickLine={false} />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(value) => S_FORMAT_INR_CURRENCY_AND_AMOUNT(value)}
                  />
                  <Tooltip
                    formatter={(value, name) => [S_FORMAT_INR_CURRENCY_AND_AMOUNT(value), name]}
                    contentStyle={{
                      borderRadius: "8px",
                      background: "#f1f1f1",
                      borderColor: "#e5e7eb"
                    }}
                    cursor={{ stroke: "#d1d5db", strokeWidth: 1 }}
                  />
                  <Legend />
                  {lineKeys.map((statusKey) => {
                    const strokeColor = STATUS_COLORS[statusKey] || "#64748b";
                    return (
                      <Line
                        key={statusKey}
                        type="monotone"
                        dataKey={statusKey}
                        stroke={strokeColor}
                        strokeWidth={3}
                        dot={{ stroke: strokeColor, strokeWidth: 2, r: 4, fill: "#fff" }}
                        activeDot={{ r: 6 }}
                        fill={`url(#color-${statusKey})`}
                      />
                    );
                  })}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceStatusLineChart;
