import React, { useContext, useState, useEffect } from "react";
import { Table, Button, Form, Pagination, Modal, Spinner, Col, Row } from "react-bootstrap";
import axios from "axios";
/* import IsAdmin from "../../../hooks/IsAdmin"; */
import * as CONST from "../../includes/Constant";
import { Link, Navigate } from "react-router-dom";
import Loading from 'react-fullscreen-loading';
import { AuthContext } from '../../context/AuthContext';
import Select from 'react-select';
import { toast } from "react-toastify";
import AddEditUserForm from "./AddEditUser";
import Swal from "sweetalert2";
import useAuthInfo   from "../../../hooks/IsAdmin";


export default function UserList() {
  const { isAdmin, departmentId } = useAuthInfo();

  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("u.id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [loading, setLoading] = useState(false);
  /* SEARCH VARIABLES */
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [searchParams, setSearchParams] = useState({});
  const [department, setDepartment] = useState("");
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [roleData, setRoleData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [currentPage, recordsPerPage, sortColumn, sortOrder, searchParams]);

  useEffect(() => {
    fetchDepartmentData();
    fetchRoles();
  }, []);

  const fetchRoles = () => {
    axios
      .get(CONST.GET_ROLE_LIST, {})
      .then((response) => {
        if (response.data.status === "success") {
          setRoleData(response.data.data);
          setRoles(response.data.data.map(role => ({
            value: role.id,
            label: role.name,
          })));

        }
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);

      });
  };

  const fetchDepartmentData = async () => {
    setLoading(true);
    try {
      const departmentsResponse = await axios.get(CONST.GET_DEPARTMENT_LIST);
      if (departmentsResponse.data.status === 'success') {
        setDepartmentData(departmentsResponse.data.data);
        setDepartments(departmentsResponse.data.data.map(department => ({
          value: department.id,
          label: department.name,
        })));

      }
    } catch (error) {
      toast.error('Error:', error);

    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(CONST.GET_USER_LIST, {
        params: {
          page: currentPage,
          per_page: recordsPerPage,
          sort_by: sortColumn,
          sort_order: sortOrder,
          dept_id: searchParams.dept_id,
          role_id: searchParams.role_id,
          name: searchParams.userName,
          email: searchParams.email
        }
      });
      if (response.data) {
        setData(response.data.data);
        setTotalRecords(response.data.total);
      } else {
        setData([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setData([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };


  /* TABLE FUNCTIONS  */
  const actionFormatter = (cell, row) => {
    return (
      <>
        <Link to="#" className="action" onClick={() => handleEdit(row)}>
          <i class='bx bx-pencil'></i>
        </Link>

        {
          row.status !== 2 && row.role_id !== 1 && (
            <Link to="#" className="action" onClick={() => handleDelete(row.id)}>
              <i class='bx bx-trash'></i>
            </Link>


          )}
      </>
    );
  };
  const columns = [
    {
      dataField: "index",
      text: "Sr.no",
      sortable: true,
      onSort: () => handleSort("index"),
    },
    {
      dataField: "name",
      text: "Name",
      sortable: true,
      onSort: () => handleSort("name"),
    },
    {
      dataField: "email",
      text: "Email",
      sortable: true,
      onSort: () => handleSort("email"),

    },

    {
      dataField: "department_name",
      text: "Department",
      sortable: true,
      onSort: () => handleSort("department_name"),
    }, {
      dataField: "role_name",
      text: "Role",
      sortable: true,
      onSort: () => handleSort("role_name"),
    },

    {
      dataField: "status",
      text: "Status",
      sortable: true,
      onSort: () => handleSort("status"),
      formatter: (cell) => {
        let statusText = "";
        let statusClass = "";

        switch (cell) {
          case 0:
            statusText = "Active";
            statusClass = "bg-success";
            break;
          case 1:
            statusText = "Inactive";
            statusClass = "bg-secondary";
            break;
          case 2:
            statusText = "Deleted";
            statusClass = "bg-danger";
            break;
          default:
            statusText = "Unknown";
            statusClass = "bg-light";
        }
        return <span className={`badge ${statusClass}`}>{statusText}</span>;
      },

    },
    {
      dataField: "actions",
      text: "Actions",
      sortable: false,
      formatter: actionFormatter,
    },


  ];

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(CONST.DELETE_USER, { user_id: id })
          .then((response) => {
            if (response.data.status === "success") {
              fetchData();
              toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            console.error("Error deleting user:", error);
            toast.error("Something went wrong. Please try again.");
          });
      }
    });
  };
  /* HANDLES */
  const handleSearch = () => {
    setSearchParams({
      dept_id: department ? department.value : "",
      role_id: role ? role.value : "",
      name: userName ? userName : "",
      email: email ? email : "",

    });
    fetchData();
  };
  const handleReset = () => {
    setDepartment(null);
    setUserName(null);
    setEmail("")
    setRole(null)
    setSearchParams({});
    fetchData();
    setCurrentPage(1);
  };

  const handleDepartmentChange = (selectedOption) => {
    setDepartment(selectedOption);
  };
  const handleRoleChange = (selectedOption) => {
    setRole(selectedOption);
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSort = (column) => {
    const order = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
  };
  const handleEdit = (user) => {
    setEditUser(user);
    setShowModal(true);
  };

  const handlePageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  //console.log(roles);
  //console.log(departments);

 
  if (!isAdmin) {
    return <Navigate to={CONST.ADMIN_PRE + "no-access"} />;
  }

  return (
    <>
      <Loading loading={loading} background="rgba(0, 0, 0, 0.5)" loaderColor="#fff" />
      <div className="container-fluid p-0">
        <div className="row mb-5">
          <div className="col-8">
            <h1 className="h3 mb-2">Manage <strong>Users</strong></h1>
          </div>
          <div className="col-4 text-right">
            <Link className="btn btn-secondary" to="#" onClick={() => {
              setEditUser(null);
              setShowModal(true);
            }} ><i class='bx bx-user-plus'></i>Add User</Link>
          </div>
        </div>
        <Row className="mb-3">
          {/* Financial Year Dropdown */}
          <Col>
            <Form.Control
              type="text"
              placeholder="Search Name"
              value={userName}
              onChange={handleUserNameChange}
            />
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Search Email"
              value={email}
              onChange={handleEmailChange}
            />
          </Col>

          {/* Client Dropdown */}
          <Col>
            <Select
              options={departments}
              placeholder="Select a department..."
              onChange={handleDepartmentChange}
              value={department}
            />
          </Col>
          <Col>
            <Select
              options={roles}
              placeholder="Select a role..."
              onChange={handleRoleChange}
              value={role}
            />
          </Col>
          {/* Search & Reset Buttons */}
          <Col xs="auto">
            <Button variant="primary" onClick={handleSearch}>
              <i className='bx bx-search-alt'></i> {" "} Search
            </Button>
            <Button
              variant="secondary"
              onClick={handleReset}
              className="ml-2"
            >
              <i className='bx bx-x'></i> {" "} Reset
            </Button>
          </Col>
        </Row>

        <div className="row mb-1">
          <div className="card flex-fill pt-3 pb-2">
            <Table striped bordered hover>
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.dataField}
                      onClick={() => handleSort(col.dataField)}
                      style={{ cursor: col.sortable ? "pointer" : "default" }}
                    >
                      {col.text}
                      {col.sortable && (sortColumn === col.dataField ? (sortOrder === "asc" ? " ▲" : " ▼") : "")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  data.length > 0 ? (data.map((item, index) => (
                    <tr key={index}>
                      {columns.map((col) => (
                        <td key={col.dataField} >
                          {col.formatter ? col.formatter(item[col.dataField], item) : item[col.dataField]}
                        </td>
                      ))}
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        No records found.
                      </td>
                    </tr>
                  )
                }

              </tbody>
            </Table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            Showing total {totalRecords}
            <select className="m-2" onChange={handlePageChange} value={recordsPerPage}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select> records
          </div>
          <div className="col-md-8 text-right">
            <Pagination className="text-right">
              {[...Array(Math.ceil(totalRecords / recordsPerPage)).keys()].map((num) => (
                <Pagination.Item key={num + 1} active={num + 1 === currentPage} onClick={() => setCurrentPage(num + 1)}>
                  {num + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{editUser ? "Edit User" : "Add User"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditUserForm
              user={editUser}
              roleData={roleData}
              departmentData={departmentData}
              onClose={() => setShowModal(false)}
              onSubmit={fetchData}
            />
          </Modal.Body>
        </Modal>

      </div>
    </>
  );
};


