import React, { useState, useEffect } from "react";
import axios from "axios";
import * as CONST from "../../../includes/Constant";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import Form from "../../../includes/Forms";
import TextareaAutosize from "react-textarea-autosize";

import {
  countryData,
  currencyData,
  stateData,
} from "../../../includes/staticData";
import Select from 'react-select';
import { Link } from "react-router-dom";

function ViewClient({ client_id }) {
console.log(client_id);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchClientData = (filters) => {
    setLoading(true);
    axios
      .get(CONST.GET_CLIENT_BY_ID, {
        params: {
          client_id: client_id
        },
      })
      .then((response) => {
        if (response.data) {
          setData(response.data.data);

        } else {
          setData([]);

        }
      })
      .catch((error) => {
        console.error("Error fetching clients:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchClientData();
  }, []);

  const countryOptions = countryData.map((country) => ({
    label: country.name,
    value: country.code,
  }));
  const stateOptions = stateData.map((state) => ({
    label: state.name,
    value: state.code,
  }));
  const currencyOptions = currencyData.map((currency) => ({
    label: `${currency.name} (${currency.code})`,
    value: currency.code,
  }));

  return (
    <>
      {loading && <Loading loading={loading} />}

      <div className="row mb-3">
        {data.map((client) => {
          // Find the country name
          const selectedCountry = countryData.find((c) => c.code === client?.country);
          const countryName = selectedCountry ? selectedCountry.name : "-";

          // Find the state name
          const selectedState = stateData.find((s) => s.code === client?.state);
          const stateName = selectedState ? selectedState.name : "-";

          // Find the currency name
          const selectedCurrency = currencyData.find((cur) => cur.code === client?.currency);
          const currencyName = selectedCurrency ? `${selectedCurrency.name} (${selectedCurrency.code})` : "-";

          return (
            <React.Fragment key={client.id}> {/* Use a unique key */}
              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Client Name</label>
                <p>{client?.name || "-"}</p>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Company Name</label>
                <p>{client?.company_name || "-"}</p>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Email Address</label>
                <p>
                  <Link to={`mailto:${client.email}`}>{client.email}</Link>
                </p>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Phone Number</label>
                <p>{client?.phone_number || "-"}</p>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Currency</label>
                <p>{currencyName}</p>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Country</label>
                <p>{countryName}</p>
              </div>

              {client.country === "IN" && (
                <>
                  <div className="col-md-6 mb-2">
                    <label className="form-label text-muted">State</label>
                    <p>{stateName}</p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label className="form-label text-muted">GST</label>
                    <p>{client?.gst || "-"}</p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label className="form-label text-muted">HSN Number</label>
                    <p>{client?.hsn_number || "-"}</p>
                  </div>
                </>
              )}

              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Billing Address</label>
                <p>{client?.billing_address || "-"}</p>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Account/Bank Details</label>
                <p>{client?.account_details || "-"}</p>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Client Added In</label>
                <p>{client?.added_in || "-"}</p>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Client Channel</label>
                <p>{client?.client_channel || "-"}</p>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Department</label>
                <p>{client?.department_name || "-"}</p>
              </div>
            </React.Fragment>
          );
        })}
      </div >
    </>
  );
}

export default ViewClient;
