import React, { useEffect, useState } from 'react'
import Loading from "react-fullscreen-loading";
import { toast } from 'react-toastify';
import * as CONST from "../../../includes/Constant";
import axios from 'axios';
import { countryData, currencyData, S_DATE_FORMATE, S_FORMAT_CURRENCY_AND_AMOUNT, S_FORMAT_INR_CURRENCY_AND_AMOUNT, S_GET_CURRENCY_SYMBOL, SD_CAP_FIRST_LETTER, SD_INVOICE_STATUS, stateData } from '../../../includes/staticData';
import { format } from "date-fns";
import { Link } from 'react-router-dom';

export default function ViewInvoice({ invoice_id }) {

  const [loading, setLoading] = useState(false);
  const [invoice, setInvoiceData] = useState([]);



  const fetchInvoiceById = async () => {
    console.log(invoice_id)
    try {
      setLoading(true);
      const response = await axios.post(CONST.GET_INVOICE_BY_ID, {
        invoice_id: invoice_id,
      });
      if (response) {
        if (response.data.status === "success") {
          console.log(response.data.data);
          setInvoiceData(response.data.data); // Ensure array
        } else {
          toast.error("Error fetching invoice:", response.data.message);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Please try again.");
    }
  };
  const countryOptions = countryData.map((country) => ({
    label: country.name,
    value: country.code,
  }));
  const stateOptions = stateData.map((state) => ({
    label: state.name,
    value: state.code,
  }));
  const currencyOptions = currencyData.map((currency) => ({
    label: `${currency.name} (${currency.code})`,
    value: currency.code,
  }));

  const getCountry = (countryCode) => {
    const country = countryData.find((s) => s.code === String(countryCode));
    return country.name
  }
  const getInvoiceStatus = (statusCode) => {
    const status = SD_INVOICE_STATUS.find((s) => s.code === String(statusCode));

    return status ? (
      <span className={`badge ${status.color}`}>{status.name}</span>
    ) : (
      <span className="badge bg-dark">Unknown</span>
    );
  };

  useEffect(() => {
    fetchInvoiceById();
  }, []);

  return (
    <>
      {loading && <Loading loading={loading} />}

      {
        invoice && invoice ? (
          <>
            <div className='row'>
              <div className="col-12  mb-2">
                <h5 className="card-title  ">Invoice Information</h5>
                <hr />
              </div>
              <div className="col-md-3 mb-2">
                <label className="form-label text-muted">Invoice Number</label>
                <p>{invoice?.invoice_number || "-"}</p>
              </div>

              <div className="col-md-3 mb-2">
                <label className="form-label text-muted">Invoice Date </label>
                <p>{invoice?.invoice_date ? format(invoice?.invoice_date, "dd/MM/yyyy") : ""}
                </p>
              </div>
              <div className="col-md-3 mb-2">
                <label className="form-label text-muted">Created By</label>
                <p>{invoice?.user_name || "-"}</p>
              </div>
              <div className="col-md-3 mb-2">
                <label className="form-label text-muted">Project Name</label>
                <p>{invoice?.project_name || "-"}</p>
              </div>

              <div className="col-md-3 mb-2">
                <label className="form-label text-muted">Invoice Amount </label>
                <p> {S_FORMAT_CURRENCY_AND_AMOUNT(invoice.currency || "INR", invoice?.total_amount || 0)} </p>
              </div>
              {
                invoice.client && invoice.client.country !== 'IN' ? (
                  <>
                    <div className="col-md-3 mb-2">
                      <label className="form-label text-muted">Exchange Rate </label>
                      <p>{invoice?.exchange_rate || 1}</p>
                    </div>
                    <div className="col-md-3 mb-2">
                      <label className="form-label text-muted">Invoice Amount ({S_GET_CURRENCY_SYMBOL("INR")}) </label>
                      <p>{S_FORMAT_INR_CURRENCY_AND_AMOUNT(invoice?.total_amount_inr || 0)}</p>
                    </div>

                  </>
                ) : (
                  <>
                    <div className="col-md-3 mb-2">
                      <label className="form-label text-muted">Gross ({S_GET_CURRENCY_SYMBOL("INR")}) </label>
                      <p>{S_FORMAT_INR_CURRENCY_AND_AMOUNT(invoice?.total_gross_payment || 0)}</p>
                    </div>
                    <div className="col-md-3 mb-2">
                      <label className="form-label text-muted">GST ({S_GET_CURRENCY_SYMBOL("INR")}) </label>
                      <p>{S_FORMAT_INR_CURRENCY_AND_AMOUNT(invoice?.total_gst_amount || 0)}</p>
                    </div>
                  </>
                )
              }
              <div className="col-md-3 mb-2">
                <label className="form-label text-muted">Status</label>
                <p>{getInvoiceStatus(invoice.status)}</p>
              </div>

              <div className="col-12 mb-2 mt-2">
                <h5 className="card-title ">Client Information</h5>
                <hr />
              </div>
              <div className="col-md-3 mb-2">
                <label className="form-label text-muted">Name </label>
                <p>{invoice.client?.name || "-"}</p>
              </div>
              <div className="col-md-3 mb-2">
                <label className="form-label text-muted">Email </label>
                <p><Link to={`Mailto:${invoice.client?.email}`}>{invoice.client?.email}</Link></p>
              </div>
              <div className="col-md-3 mb-2">
                <label className="form-label text-muted">Company </label>
                <p>{invoice.client?.company_name || "-"}</p>
              </div>
              <div className="col-md-3 mb-2">
                <label className="form-label text-muted">Company </label>
                <p>{getCountry(invoice.client?.country || "US")}</p>
              </div>
              <div className="col-12  mb-2 mt-2">
                <h5 className="card-title ">Payment Information</h5>
                <hr />
              </div>
              {
                invoice.payments && invoice.payments.length > 0 ? (
                  <>
                    <div className='col-12'>
                      <table className="table table-striped table-bordered " style={{ width: "100%" }}>
                        <thead>
                          <tr>
                            <th>Amount ({S_GET_CURRENCY_SYMBOL(invoice.currency || "INR")})</th>
                            <th>Amount ({S_GET_CURRENCY_SYMBOL("INR")})</th>
                            <th>Transaction Date</th>
                            <th >Transaction Id</th>
                            <th>Mode</th>
                            <th>Note</th>
                            <th>Status</th>
                            <th>Update By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            invoice.payments.map((payment, index) => {
                              return (
                                <tr key={payment.id}>
                                  <td className="text-nowrap" >
                                    {S_FORMAT_CURRENCY_AND_AMOUNT(invoice.currency, payment.payment_amount)}
                                  </td>
                                  <td className="text-nowrap" >
                                    {S_FORMAT_INR_CURRENCY_AND_AMOUNT(payment.payment_amount_inr || 0)}
                                  </td>
                                  <td className="text-nowrap">
                                    {S_DATE_FORMATE(payment.received_date)}
                                  </td>
                                  <td className="text-nowrap" >
                                    {payment.transaction_id}
                                  </td>
                                  <td className="text-nowrap">
                                    {SD_CAP_FIRST_LETTER(payment.mode)}
                                  </td>
                                  <td className="text-nowrap">
                                    {payment.note}
                                  </td>
                                  <td className="text-nowrap">
                                    {getInvoiceStatus(payment.status)}
                                  </td>
                                  <td className="text-nowrap"  >
                                    {payment.updated_by_name}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>


                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12 mb-2">
                      <p className="text-center">No Payment</p>
                    </div>
                  </>
                )
              }
              <div className="col-12 mb-2 mt-2">
                <h5 className="card-title  ">Invoice History</h5>
                <hr />
              </div>
              {
                invoice.history && invoice.history.length > 0 ? (
                  <>
                    <div className='col-12'>
                      <table className="table table-striped table-bordered " style={{ width: "100%" }}>
                        <thead>
                          <tr>
                            <th>User</th>
                            <th>Date</th>
                            <th >Type</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            invoice.history.map((history, index) => {
                              return (
                                <tr key={history.id}>
                                  <td className="text-nowrap" >
                                    {history?.updated_by_name || "-"}
                                  </td>
                                  <td className="text-nowrap" >
                                    {S_DATE_FORMATE(history.updated_at)}
                                  </td>

                                  <td className="text-nowrap">
                                    {history.change_type}
                                  </td>

                                  <td className="text-nowrap"  >
                                    {history.history_description}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>


                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12 mb-2">
                      <p className="text-center">No History</p>
                    </div>
                  </>
                )
              }
            </div>
          </>
        ) : (
          <><p>No invoice data available</p></>
        )
      }
    </>
  )
}
