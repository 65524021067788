import React from 'react'

export default function NoAccess() {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="row mb-5">
          <div style={{ padding: "2rem", textAlign: "center" }}>
            <h2>Access Denied</h2>
            <p>You do not have permission to view this page.</p>
          </div>
        </div>
      </div>

    </>

  )
}
