import React, { useState, useEffect } from 'react'
import Form from '../includes/Forms'
import * as CONST from '../includes/Constant'
import axios from "axios";
import { toast } from "react-toastify";
import Loading from 'react-fullscreen-loading';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";


function ResetPassword(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  let [isPasswordEqual, setIsPasswordEqual] = useState({});
  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  // Get the token
  const token = getQueryParam('token');

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const isTokenExpired = decoded.exp < currentTime;

      if (decoded.sub && !isTokenExpired) {
        setEmail(decoded.sub); // Set email only if token is valid
      }

      if (isTokenExpired) {
        toast.error("Link has been expired. Please try again");
        navigate("/forget-password");
        //console.log("Token has expired");
      }
    }
  }, [token, navigate]); // Only run this effect when the token changes

  /* Validate Login */
  const validateForgotPassword = async () => {
    let isValid = true;
    let validator = Form.validator({
      password: {
        value: password,
        isRequired: true,
        minLength: 8,
        label: "new password",
        isSymbol: true,
      },
      confirmPassword: {
        value: confirmPassword,
        isRequired: true,
        minLength: 8,
        isSymbol: true,
        label: "confirm password",
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      if (password !== confirmPassword) {
        setIsPasswordEqual({
          status: "yes",
          msg: "The new password and confirm password are not equal",
        });
        isValid = false;
      } else {
        setValidate({
          validate: "",
        });
        isValid = true;
        setIsPasswordEqual({});
      }
    }
    return isValid;
  };

  const frmAuthenticate = async (e) => {
    e.preventDefault();
    const validate = await validateForgotPassword();
    if (validate) {
      setLoading(true);
      await axios.post(CONST.UPDATE_PASSWORD, {
        email: email,
        password: password,
        token: token,
      })
        .then((response) => {
          setLoading(false);
          if (response) {
            const status = response.data.status;
            if (status === 'success') {
              toast.success(response.data.message);
              navigate("/login");
            } else {
              toast.error(response.data.message);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again.");
        });
    }
  }

  return (
    <>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <main className="d-flex w-100">
        <div className="container d-flex flex-column mt-5">
          <div className="row">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-5">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/eluminous-pvt-ltd.png"
                    }
                    alt="logo"
                    style={{ width: "250px" }}
                    className="img-fluid"
                  />
                  <h1 className="h2 mt-4">Set Password</h1>
                  <p className="lead mt-3">
                    Please enter new password to reset it.
                  </p>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-3">
                      <form
                        className="auth-form"
                        method="POST"
                        onSubmit={frmAuthenticate}
                        autoComplete={"off"}
                      >
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <input
                            type="email"
                            className={`form-control form-control-lg ${
                              validate.validate && validate.validate.email
                                ? "is-invalid"
                                : ""
                            }`}
                            id="email"
                            name="email"
                            value={email}
                            placeholder="your@email.com"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Password</label>
                          <input
                            type="password"
                            className={`form-control form-control-lg ${
                              validate.validate && validate.validate.password
                                ? "is-invalid "
                                : ""
                            }`}
                            name="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Your password"
                          />
                          <div
                            className={`invalid-feedback text-start mb-2 ${
                              validate.validate && validate.validate.password
                                ? "d-block"
                                : "d-none"
                            }`}
                          >
                            {validate.validate && validate.validate.password
                              ? validate.validate.password[0]
                              : ""}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Confirm Password</label>
                          <input
                            type="password"
                            className={`form-control form-control-lg ${
                              validate.validate && validate.validate.password
                                ? "is-invalid "
                                : ""
                            }`}
                            name="confirmPassword"
                            id="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Password again"
                          />
                          <div
                            className={`invalid-feedback text-start mb-2 ${
                              validate.validate &&
                              validate.validate.confirmPassword
                                ? "d-block"
                                : "d-none"
                            }`}
                          >
                            {validate.validate &&
                            validate.validate.confirmPassword
                              ? validate.validate.confirmPassword[0]
                              : ""}
                          </div>
                        </div>
                        <div className="d-grid gap-2 mt-3">
                          <button
                            type="submit"
                            id="signin"
                            className="btn btn-lg btn-primary"
                          >
                            Send me new password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="text-center mb-3">
                  Not Needed. <Link to="/login">Let me login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ResetPassword;
