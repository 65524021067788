import React, { useState, useEffect } from "react";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import Form from "../../includes/Forms";

function AddEditUser({ user, roleData, departmentData, onClose, onSubmit }) {
  //console.log(user);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    department: "",
    role: "",
    status: "0",
  });
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || "",
        email: user.email || "",
        department: user.department_id || "",
        role: user.role_id || "",
        status: user.status || "0",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = async () => {
    let isValid = true;
    let validator = Form.validator({
      name: {
        value: formData.name,
        isRequired: true,
        label: "name",
      },

      email: {
        value: formData.email,
        isRequired: true,
        isEmail: true,
        label: "email",
      },
      department: {
        value: formData.department,
        isRequired: true,
        label: "department",
      },
      role: {
        value: formData.role,
        isRequired: true,
        label: "role",
      },
      status: {
        value: formData.status,
        isRequired: true,
        label: "status",
      },
    });
    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (!isValid) return;

    setLoading(true);

    const mappedFormData = {
      user_id: user ? user.id : "",
      name: formData.name,
      email: formData.email,
      dept_id: formData.department,
      role_id: formData.role,
      status: parseInt(formData.status),
    };

    const request = user
      ? axios.post(CONST.UPDATE_USER, mappedFormData)
      : axios.post(CONST.ADD_USER, mappedFormData);

    request
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          onSubmit();
          onClose();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error saving user:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading loading={loading} />}
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Name</label>
            <input
              type="text"
              className={`form-control ${validate.validate && validate.validate.name
                ? "is-invalid"
                : ""
                }`}
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {validate.validate && validate.validate.name && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.name}
              </div>
            )}
          </div>
          <div className="col-md-6">
            <label className="form-label">Email Address</label>
            <input
              type="email"
              className={`form-control ${validate.validate && validate.validate.email ? "is-invalid" : ""
                }`}
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {validate.validate && validate.validate.email && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.email}
              </div>
            )}
          </div>
        </div>

        <div className="row mb-3">

          <div className="col-md-6">
            <label className="form-label">Department</label>
            <select
              className={`form-control ${validate.validate && validate.validate.department
                ? "is-invalid"
                : ""
                }`}
              name="department"
              value={formData.department}
              onChange={handleChange}
            >
              <option value="">Select Department</option>
              {departmentData.length > 0 ? (
                departmentData.map((dept) => (
                  <option key={dept.id} value={dept.id}>
                    {dept.name}
                  </option>
                ))
              ) : (
                <option disabled>Loading departments...</option>
              )}
            </select>
            {validate.validate && validate.validate.department && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.department}
              </div>
            )}
          </div>
          <div className="col-md-6">
            <label className="form-label">Role</label>
            <select
              className={`form-control ${validate.validate && validate.validate.role ? "is-invalid" : ""
                }`}
              name="role"
              value={formData.role}
              onChange={handleChange}
            >
              <option value="">Select Role</option>
              {roleData.length > 0 ? (
                roleData.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))
              ) : (
                <option disabled>Loading roles...</option>
              )}
            </select>
            {validate.validate && validate.validate.role && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.role}
              </div>
            )}
          </div>
        </div>

        <div className="row mb-3">

          <div className="col-md-6">
            <label className="form-label">Status</label>
            <select
              className={`form-control ${validate.validate && validate.validate.status
                ? "is-invalid"
                : ""
                }`}
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="">Select Status</option>
              <option value="0">Active</option>
              <option value="1">Inactive</option>
              <option value="2">Delete</option>
            </select>
            {validate.validate && validate.validate.status && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.status}
              </div>
            )}
          </div>
        </div>

        <div className="text-left">
          <button type="submit" className="btn btn-primary">
            <i class='bx bx-save'></i> Submit
          </button>
        </div>
      </form>
    </>
  );
}

export default AddEditUser;
