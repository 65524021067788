//console.log(window.location.hostname);
export const APP_LINK = (window.location.hostname === 'localhost') ? 'http://localhost:3000/' :
    (window.location.hostname === 'billing.betaeserver.com') ? 'https://billing.betaeserver.com/' :
        'http://localhost:3000/';
export const ADMIN_PRE = "/admin/";
export const API_LINK = (window.location.hostname === 'localhost') ? 'http://127.0.0.1:5000/' :
    (window.location.hostname === 'billing.betaeserver.com') ? 'https://api.betaeserver.com/' :
        'http://localhost:5000/';
/* Auth Route */
export const PDF_LINK = API_LINK + "static/invoices/";
export const LOGIN = API_LINK + "auth/login";
export const FORGOT_PASSWORD = API_LINK + "auth/forgot-password";
export const UPDATE_PASSWORD = API_LINK + "auth/update-password";
/* Pages Links  */
export const RESET_PASSWORD = APP_LINK + "reset-password";
/* Comman Links */
export const GET_ROLE_LIST = API_LINK + "role/list";
export const GET_DEPARTMENT_LIST = API_LINK + "department/list";
/* Client Links */
export const GET_CLIENT_LIST = API_LINK + "client/list";
export const UPDATE_CLIENT = API_LINK + "client/update";
export const ADD_CLIENT = API_LINK + "client/add";
export const DELETE_CLIENT = API_LINK + "client/delete";
export const GET_ALL_CLIENT = API_LINK + "client/all";
export const GET_CLIENT_BY_ID = API_LINK + "client/get-client-details";

/* Vendor Links */
export const GET_VENDOR_LIST = API_LINK + "vendor/list";
export const UPDATE_VENDOR = API_LINK + "vendor/update";
export const ADD_VENDOR = API_LINK + "vendor/add";
export const DELETE_VENDOR = API_LINK + "vendor/delete";
export const GET_ALL_VENDOR = API_LINK + "vendor/all";

export const ADD_VENDOR_PAYMENTS = API_LINK + "vendor/add-vendor-payments";
export const GET_VENDOR_PAYMENT_HISTORY = API_LINK + "vendor/get-vendor-payment-history";
export const UPDATE_VENDOR_PAYMENT = API_LINK + "vendor/update-vendor-payments";
export const DELETE_VENDOR_PAYMENT  = API_LINK + "vendor/delete-vendor-payments";

/* User Links */
export const GET_USER_LIST = API_LINK + "user/list";
export const UPDATE_USER_PROFILE = API_LINK + "profile/update";
export const UPDATE_USER_PASSWORD = API_LINK + "profile/update-password";
export const DELETE_USER = API_LINK + "user/delete";
export const UPDATE_USER = API_LINK + "user/update";
export const ADD_USER = API_LINK + "user/add";
/* Projects Links */
export const GET_PROJECT_LIST = API_LINK + "project/list";
export const ADD_PROJECT = API_LINK + "project/add";
export const UPDATE_PROJECT = API_LINK + "project/update";
export const DELETE_PROJECT = API_LINK + "project/delete";
export const GET_CLIENT_PROJECTS = API_LINK + "project/get-client-projects";
export const GET_PROJECTS_DETAILS = API_LINK + "project/get-project-details";
/* Setting Links */
export const GET_SETTING_LIST = API_LINK + "setting/list";
export const UPDATE_SETTING_LIST = API_LINK + "setting/update";

/* Invoice  */

export const GET_INVOICE_LIST = API_LINK + "invoice/list";
export const ADD_INVOICE = API_LINK + "invoice/add";
export const UPDATE_INVOICE = API_LINK + "invoice/update";
export const GET_INVOICE_BY_ID = API_LINK + "invoice/get-invoice";
export const UPDATE_INVOICE_PAYMENTS = API_LINK + "invoice/update-invoice-payments";
export const ADD_INVOICE_PAYMENTS = API_LINK + "invoice/add-invoice-payments";
export const GET_INVOICE_PAYMENTS = API_LINK + "invoice/get-invoice-payments";
export const GET_INVOICE_HISTORY = API_LINK + "invoice/get-invoice-history";
export const DELETE_INVOICE_PAYMENT = API_LINK + "invoice/delete-invoice-payments";

/* RESOURCES */
export const ADD_RESOURCES = API_LINK + "resources/add";
export const GET_RESOURCES = API_LINK + "resources/list";
export const UPDATE_RESOURCES = API_LINK + "resources/update";
export const DELETE_RESOURCE = API_LINK + "resources/delete";
/* EXCHANGE  */
export const GET_EXCHANGE_LIST = API_LINK + "exchange/list";


/* DASHBOARD */
export const DASH_RAISED_VS_RECEIVED = API_LINK + "dashboard/raised-vs-received";
export const DASH_MONTHLY_FIANCIAL_SUMMARY = API_LINK + "dashboard/monthly-financial-summary";
export const DASH_VENDOR_SUMMERY = API_LINK + "dashboard/vendor-summary";
export const DASH_VENDOR_PAYMENT_SUMMARY_DETAILS = API_LINK + "dashboard/vendor-summary-details";
export const DASH_INVOICE_STATUS = API_LINK + "dashboard/invoice-status-summary";
export const DASH_INVOICE_STATUS_LINE_CHART = API_LINK + "dashboard/invoice-status-line-chart";
export const DASH_FIXED_VS_DEDICATED_BAR_CHART = API_LINK + "dashboard/fixed-vs-dedicated-bar-chart";
export const DASH_LATEST_VENDOR_PAYMENT_HISTORY = API_LINK + "dashboard/latest-vendor-payment-history";
export const DASH_FIXED_VS_DEDICATED = API_LINK + "dashboard/fixed-vs-dedicated";
export const DASH_FIXED_MONTHLY_SUMMARY = API_LINK + "dashboard/fixedcost-monthly-summary";
export const DASH_DEDICATED_MONTHLY_SUMMARY = API_LINK + "dashboard/dedicated-monthly-summary";