import React, { useState, useEffect } from "react";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import Form from "../../includes/Forms";
import Select from "react-select";
import useAuthInfo from "../../../hooks/IsAdmin";
import { SD_PROJECT_TYPE, SD_STATUS } from "../../includes/staticData";

function AddEditProject({ project, onClose, onSubmit }) {
  console.log(project);
  const [formData, setFormData] = useState({
    name: "",
    client_id: "",
    description: "",
    status: "0",
    client_departments: null,
    project_type: "",
  });
  const { isAdmin, eDepartmentId } = useAuthInfo();
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [clients, setClients] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [department, setDepartment] = useState(null);
  const [client, setClient] = useState(null);
  const [clientLoading, setClientLoading] = useState(false);

  useEffect(() => {
    fetchDepartments();
  }, []);

  useEffect(() => {
    if (!isAdmin && departmentList.length > 0) {
      const matchedDept = departmentList.find((d) => d.value === eDepartmentId);
      if (matchedDept) {
        setDepartment(matchedDept);
        setFormData((prev) => ({
          ...prev,
          client_departments: matchedDept,
        }));
        fetchClients(eDepartmentId);
      }
    }
  }, [isAdmin, departmentList]);


  useEffect(() => {
    if (!isAdmin) {
      //console.log(isAdmin);
      //console.log(eDepartmentId);
      setDepartment(eDepartmentId);
      fetchClients(eDepartmentId);
    }
    if (project) {
      //console.log(project.department);
      const selectedDept = project.department
        ? { value: project.department.department_id, label: project.department.department_name }
        : null;

      const selectedClient = project.client?.client_id || "";

      setFormData({
        name: project.project_name || "",
        client_id: selectedClient,
        description: project.project_description || "",
        status: project.project_status || "0",
        client_departments: selectedDept,
        project_type: project.project_type || "",
      });
      if (selectedDept) {
        setDepartment(selectedDept);
        fetchClients(selectedDept.value);
      }
    }
  }, [project]);

  const fetchDepartments = async () => {
    try {
      const res = await axios.get(CONST.GET_DEPARTMENT_LIST);
      if (res.data.status === "success") {
        const formatted = res.data.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setDepartmentList(formatted);
      }
    } catch (err) {
      toast.error("Error fetching departments");
    }
  };

  const fetchClients = async (departmentId) => {
    setClientLoading(true);
    try {
      const res = await axios.get(CONST.GET_ALL_CLIENT, {
        params: departmentId ? { department_id: departmentId } : {},
      });
      if (res.data.status === "success") {
        const formatted = res.data.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setClients(formatted);
      } else {
        setClients([]);
      }
    } catch (err) {
      console.error("Error fetching clients:", err);
      setClients([]);
    } finally {
      setClientLoading(false);
    }
  };

  const projectTypeOptions = SD_PROJECT_TYPE.map((projectType) => ({
    label: projectType.name,
    value: projectType.code,
  }));
  const handleDepartmentChange = (selectedDept) => {
    setDepartment(selectedDept);
    setFormData((prev) => ({
      ...prev,
      client_departments: selectedDept,
      client_id: "", // reset client selection
    }));
    fetchClients(selectedDept?.value);
  };
  const handleClientChange = (selectedDept) => {
    console.log(clients);
    console.log(selectedDept);
    setClient(selectedDept?.value);
    setFormData((prev) => ({
      ...prev,
      client_id: selectedDept?.value,// reset client selection
    }));
    //fetchClients(selectedDept?.value);
  };
  const handleProjectTypeChange = (selectedDept) => {
    setFormData((prev) => ({
      ...prev,
      project_type: selectedDept?.value,// reset client selection
    }));
    //fetchClients(selectedDept?.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = async () => {
    const validator = Form.validator({
      name: { value: formData.name, isRequired: true, label: "name" },
      client_id: { value: formData.client_id, isRequired: true, label: "client" },
      description: { value: formData.description, isRequired: true, label: "description" },
      status: { value: formData.status, isRequired: true, label: "status" },
      client_departments: { value: formData.client_departments, isRequired: true, label: "department" },
    });

    const validationErrors = validator?.errors || {};
    setValidate({ validate: validationErrors });

    return Object.keys(validationErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(await validateForm())) return;

    setLoading(true);
    const payload = {
      project_id: project?.project_id || "",
      name: formData.name,
      client_id: parseInt(formData.client_id),
      project_type: formData.project_type,
      description: formData.description,
      status: parseInt(formData.status),
      dept_id: parseInt(formData.client_departments ? formData.client_departments.value : 0)
    };


    const apiCall = project
      ? axios.post(CONST.UPDATE_PROJECT, payload)
      : axios.post(CONST.ADD_PROJECT, payload);

    apiCall
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(res.data.message);
          onSubmit();
          onClose();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error("Error saving project:", err);
        toast.error("Failed to save project");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <Loading loading={loading} />}
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Project Name</label>
            <input
              type="text"
              className={`form-control ${validate.validate?.name ? "is-invalid" : ""}`}
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Project Name"
            />
            {validate.validate?.name && <div className="invalid-feedback">{validate.validate.name}</div>}
          </div>

          <div className="col-md-6">
            <label className="form-label">Department</label>
            <Select
              name="client_departments"
              options={departmentList}
              value={formData.client_departments}
              onChange={handleDepartmentChange}
              className={`react-select-container ${validate.validate?.client_departments ? "is-invalid" : ""}`}
              placeholder="Select Department"
              isDisabled={!isAdmin}
            />
            {validate.validate?.client_departments && (
              <div className="invalid-feedback d-block">{validate.validate.client_departments}</div>
            )}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Client</label>
            {clientLoading ? (
              <div className="form-control d-flex align-items-center">
                <span className="spinner-border spinner-border-sm me-2" role="status" />
                Loading clients...
              </div>
            ) : (
              <Select
                name="client_id"
                options={clients}
                value={clients.find((opt) => opt.value === formData.client_id) || null}
                onChange={handleClientChange}
                className={`react-select-container ${validate.validate?.client_id ? "is-invalid" : ""}`}
                placeholder="Select Client"
              />

            )}

            {validate.validate?.client_id && <div className="invalid-feedback">{validate.validate.client_id}</div>}
          </div>
          <div className="col-md-6">
            <label className="form-label">Project Type</label>
            <Select
              name="project_type"
              classNamePrefix="react-select"
              options={projectTypeOptions}
              value={projectTypeOptions.find((opt) => opt.value === formData.project_type)}
              onChange={handleProjectTypeChange}
              className='react-select-container'
              placeholder="Select type"
            />
            {validate.validate?.project_type && <div className="invalid-feedback">{validate.validate.project_type}</div>}
          </div>

        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Status</label>
            <select
              className={`form-control ${validate.validate?.status ? "is-invalid" : ""}`}
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="0">Active</option>
              <option value="1">Inactive</option>
              <option value="2">Deleted</option>
            </select>
            {validate.validate?.status && <div className="invalid-feedback">{validate.validate.status}</div>}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <label className="form-label">Description</label>
            <textarea
              className={`form-control ${validate.validate?.description ? "is-invalid" : ""}`}
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Project Description"
            />
            {validate.validate?.description && <div className="invalid-feedback">{validate.validate.description}</div>}
          </div>
        </div>

        <div className="text-left">
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </>
  );
}

export default AddEditProject;
