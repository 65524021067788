import React from 'react'
import UserProfile from './UserProfile'
import ChangePassword from './ChangePassword'

export default function Profile() {
  return (
    <div className="container-fluid p-0">
      <div className="row">
        <h1 className="h3 mb-5">Profile <strong>Update</strong></h1>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="card">
            <div className="card-header m-0 p-0">
              <div className="list-group list-group-flush" role="tablist">
                <a className="list-group-item list-group-item-action active" data-bs-toggle="list" href="#profile" role="tab" aria-selected="true">
                  Account
                </a>
                <a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#password" role="tab" aria-selected="false" tabIndex="-1">
                  Update Password
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="tab-content">
            <div className="tab-pane fade show active" id="profile" role="tabpanel">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">User Profile</h5>
                </div>
                <div className="card-body">
                  <UserProfile />
                </div>
              </div>
            </div>
            <div className="tab-pane fade show" id="password" role="tabpanel">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">User Profile</h5>
                </div>
                <div className="card-body">
                  <ChangePassword />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
