import React, { useContext, useEffect, useState } from 'react'
import * as CONST from "../../../includes/Constant";
import axios from 'axios';
import { Modal, Table } from 'react-bootstrap';
import { S_FORMAT_INR_CURRENCY_AND_AMOUNT, S_INR_CURRENCY_SYMBOL, SD_INVOICE_STATUS } from '../../../includes/staticData';
import { Link } from 'react-router-dom';
import BlockLoading from '../../../blocks/BlockLoading';
import InvoicePayments from '../../invoice/InvoicePayments';
import InvoiceHistory from '../../invoice/InvoiceHistory';
import useAuthInfo from '../../../../hooks/IsAdmin';
import { AuthContext } from '../../../context/AuthContext';

import { setGlobalModal } from '../../modal/GlobalModal';
import ViewClient from '../../modal/client/ViewClient';
import ViewInvoice from '../../modal/invoice/ViewInvoice';



export default function TopPendingInvoices({ searchParams }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { isAdmin, eDepartmentId } = useAuthInfo();
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("inv.id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [paymentsInvoiceId, setPaymentsInvoiceId] = useState();
  const [invoiceStatus, setInvoiceStatus] = useState();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showInvoiceHistoryModal, setShowInvoiceHistoryModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(authUserData ? authUserData[0].user_id : 1);
  /* SEARCH VARIABLES */
  const [financialYears, setFinancialYears] = useState([]);
  const [currentFinancialYears, setCurrentFinancialYears] = useState([]);
  const [status, setStatus] = useState('pending');
  const [selectedYear, setSelectedYear] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [departments, setDepartments] = useState(null);
  const [userDepartment, setUserDepartment] = useState("");
  const [invoiceId, setInvoiceId] = useState("");

  useEffect(() => {
    fetchTopPendingInvoices();
  }, [searchParams]);

  const fetchTopPendingInvoices = async () => {
    setLoading(true);
    try {
      const response = await axios.get(CONST.GET_INVOICE_LIST, {
        params: {
          sort_by: "inv.id",
          sort_order: "asc",
          financial_year: searchParams.year || "",
          department_id: searchParams.department_id || "",
          limit: 5,
          status: 0
        }
      });
      if (response.data) {
        setData(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  }
  const actionFormatter = (cell, row) => {
    return (
      <>
        <Link to="#" className="action" onClick={() => handlePayment(row.invoice_id, row.status, row)}>
          <i className='bx bx-wallet'></i>
        </Link>

        <Link to="#" className="action" onClick={() => handleInvoiceHistory(row.invoice_id)}>
          <i className='bx bx-history'></i>
        </Link>

      </>
    );
  };
  const columns = [
    {
      dataField: "invoice_number",
      text: "Invoice #",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <> {/* <Link to="#" className="action" onClick={() => handleView(row.pdf_link)}>
            {row.invoice_number}
          </Link> */}
            <Link to="#" className="action"
              onClick={() =>
                setGlobalModal(
                  ViewInvoice,
                  {
                    invoice_id: row.invoice_id,
                  },
                  {  // Modal Props
                    size: "xl",
                    title: "#" + row.invoice_number + " - " + row.client?.name
                  }
                )
              }
            >
              {row.invoice_number}
            </Link >
          </>
        )
      }
    },
    {
      dataField: "client.name",
      text: "Client Info",

      formatter: (cell, row) => {
        const client_name = row.client?.name || "N/A";
        const client_email = row.client?.email || "N/A";
        const client_company_name = row.client?.company_name || "N/A";
        return (
          <>
            <Link to="#" className="action"
              onClick={() =>
                setGlobalModal(
                  ViewClient,
                  {
                    client_id: row.client_id,
                  },
                  {  // Modal Props
                    size: "lg",
                    title: "View Client " + client_name
                  }
                )
              }
            >
              {client_name}
            </Link >

          </>

        );
      }
    },
    {
      dataField: "total_amount",
      text: "Amount",

      formatter: (cell, row) => {
        const total_gross_payment = row.total_gross_payment;
        const total_gst_amount = row.total_gst_amount;
        const total_amount = row.total_amount;
        const currency = S_INR_CURRENCY_SYMBOL(row.currency) ?? "$";
        if (row.client?.country === "IN") {
          return (
            <div style={{ textAlign: "right", fontSize: "12px" }}>
              <div><strong>Gross:</strong>  {S_FORMAT_INR_CURRENCY_AND_AMOUNT(total_gross_payment)}</div>
              <div><strong>GST:</strong> {S_FORMAT_INR_CURRENCY_AND_AMOUNT(total_gst_amount)}</div>
              <div><strong>Total:</strong>  {S_FORMAT_INR_CURRENCY_AND_AMOUNT(total_amount)}</div>
            </div>
          );
        } else {
          return <div style={{ textAlign: "right" }}>{currency}{total_amount}</div>;
        }
      }
    },
    {
      dataField: "total_amount_inr",
      text: "Amount (INR)",

      formatter: (cell, row) => {
        const exchange_rate = row.exchange_rate || 0;
        const inr_payment = row.total_amount_inr || 0;

        if (row.client?.country === "IN") {
          return (
            <>
              {S_FORMAT_INR_CURRENCY_AND_AMOUNT(inr_payment)}
            </>
          );
        } else {
          return (
            <>
              {S_FORMAT_INR_CURRENCY_AND_AMOUNT(inr_payment)}
              <br />
              <small>ex rate: ({exchange_rate})</small>
            </>
          );
        }
      }
    }
    ,
    {
      dataField: "invoice_date",
      text: "Invoice Date",

      formatter: (cell) => {
        if (!cell) return "";
        const date = new Date(cell);
        return date.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" });
      }
    },

    {
      dataField: "actions",
      text: "Actions",
      sortable: false,
      formatter: actionFormatter,
    },


  ];

  /* PDF VIEW & DOWNLOAD */
  const handleView = (pdf) => {
    setLoading(true);
    const iLink = CONST.PDF_LINK + pdf;
    window.open(iLink, "_blank");
    setLoading(false);
  };

  const handleDownload = (pdf) => {
    setLoading(true);
    const iLink = CONST.PDF_LINK + pdf;
    const link = document.createElement("a");
    link.href = iLink;

    // Extract filename from URL
    const fileName = iLink.split("/").pop();

    // using Java Script method to get PDF file
    fetch(iLink).then((response) => {
      response.blob().then((blob) => {

        // Creating new object of PDF file
        const fileURL =
          window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();

      });
    });
    setLoading(false);
  };
  /* Modal */
  const handlePayment = (invoice_id, invoice_status, row) => {
    setPaymentsInvoiceId(invoice_id);
    setInvoiceStatus(invoice_status);
    setSelectedInvoice(row);
    setShowPaymentModal(true);
  };
  const handleInvoiceHistory = (invoice_id) => {

    setPaymentsInvoiceId(invoice_id);
    setShowInvoiceHistoryModal(true);
  };
  const handlePaymentClose = () => {
    setShowPaymentModal(false);
    setSelectedInvoice("");
    fetchTopPendingInvoices();
  };
  const handleInvoiceHistoryModalClose = () => {
    setShowInvoiceHistoryModal(false);

  };
  const handlePageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleOpenGlobalModal = (Component, client_id) => {
    // openModal(Component, { client_id });
  };

  return (
    <>
      {loading ? (
        <div className="row"><BlockLoading /></div>
      ) : (
        <div className="card flex-fill">
          <div className="card-header">
            <div className="card-actions float-end">
              <div className="dropdown position-relative">
                <Link href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" className="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-horizontal align-middle"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                </Link>

                <div className="dropdown-menu dropdown-menu-end">
                  <Link className="dropdown-item" to={CONST.ADMIN_PRE + "vendor/payments"}>View All</Link>

                </div>
              </div>
            </div>
            <h5 className="card-title mb-0">Top Pending Invoices</h5>
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <Table className="striped bordered hover responsive">
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.dataField}
                      style={{ cursor: col.sortable ? "pointer" : "default" }}
                    >
                      {col.text}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  data.length > 0 ? (data.map((item, index) => (
                    <tr key={index}>
                      {columns.map((col) => (
                        <td key={col.dataField} >
                          {col.formatter ? col.formatter(item[col.dataField], item) : item[col.dataField]}
                        </td>
                      ))}
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        No records found.
                      </td>
                    </tr>
                  )
                }

              </tbody>
            </Table>

          )}

        </div>
      )
      }
      {/* Invoice Payment Modal */}
      <Modal
        show={showPaymentModal}
        onHide={() => handlePaymentClose()}
        size="xl"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Invoice Payments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InvoicePayments
            invoice_id={paymentsInvoiceId}
            onClose={() => handlePaymentClose()}
            currentUser={currentUser}
            invoiceStatus={invoiceStatus}
            selectedInvoice={selectedInvoice}
          />
        </Modal.Body>
      </Modal>
      {/* Invoice History Modal */}
      <Modal
        show={showInvoiceHistoryModal}
        onHide={() => handleInvoiceHistoryModalClose()}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Invoice History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InvoiceHistory
            invoice_id={paymentsInvoiceId}
            invoiceStatus={invoiceStatus}
          />
        </Modal.Body>
      </Modal>

    </>
  )
}
