import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import * as CONST from "../../includes/Constant";
import { toast } from "react-toastify";

const UserProfile = () => {
  const [authUserData, setAuthUserData] = useContext(AuthContext);

  const [formData, setFormData] = useState({
    name: authUserData ? authUserData[0].name : "",
    email: authUserData ? authUserData[0].email : "",
    dpt_id: authUserData ? authUserData[0].dept_id : "",
    role_id: authUserData ? authUserData[0].role_id : "",
    user_id: authUserData ? authUserData[0].user_id : "",
    // token: authUserData ? authUserData[0].token : "",
    status:
      authUserData && authUserData[0].status
        ? String(authUserData[0].status)
        : 0,
  });

  const [errors, setErrors] = useState({});
  const [roleData, setRoleData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  useEffect(() => {
    getRole();
    getDepartment();
  }, []);

  const getRole = async () => {
    try {
      const response = await axios.get(CONST.GET_ROLE_LIST, {});

      if (response.data.status === "success") {
        setRoleData(response.data.data);
      } else {
        setRoleData([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getDepartment = async () => {
    try {
      const response = await axios.get(CONST.GET_DEPARTMENT_LIST, {});

      if (response.data.status === "success") {
        setDepartmentData(response.data.data);
      } else {
        setDepartmentData([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "First name is required";

    // if (!formData.email) newErrors.email = "Email is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailPattern.test(formData.email)) {
        newErrors.email = "Please enter a valid email address";
      }
    }
    if (!formData.dpt_id) newErrors.dpt_id = "dpt_id is required";
    /*  if (!formData.role_id) newErrors.role_id = "Role is required"; */
    // if (!formData.status) newErrors.status = "Status is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.post(CONST.UPDATE_USER_PROFILE, formData);

        if (response.data.status === "success") {
          const UserData = [
            {
              name: formData.name,
              email: formData.email,
              token: authUserData[0].token,
              user_id: formData.user_id,
              dept_id: formData.dept_id,
            },
          ];

          setAuthUserData(UserData);
          const access_token = response.data.token;
          localStorage.setItem("etIMSToken", access_token);
          toast.success(response.data.message);
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form", error);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Name</label>
            <input
              type="text"
              className={`form-control ${errors.name ? "is-invalid" : ""
                }`}
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name}</div>
            )}
          </div>

        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Email Address</label>
            <input
              type="email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </div>

        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Department</label>
            <select
              className={`form-control ${errors.dpt_id ? "is-invalid" : ""}`}
              name="dpt_id"
              value={formData.dpt_id}
              onChange={handleChange}
            >
              <option value="">Select dpt_id</option>
              {departmentData.length > 0 ? (
                departmentData.map((dept) => (
                  <option key={dept.id} value={dept.id}>
                    {dept.name}
                  </option>
                ))
              ) : (
                <option disabled>Loading departments...</option>
              )}
            </select>
            {errors.dpt_id && (
              <div className="invalid-feedback">{errors.dpt_id}</div>
            )}
          </div>
        </div>

        {/*  <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Role</label>
            <select
              className={`form-control ${errors.role_id ? "is-invalid" : ""}`}
              name="role_id"
              value={formData.role_id}
              onChange={handleChange}
            >
              <option value="">Select Role</option>
              {roleData.length > 0 ? (
                roleData.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))
              ) : (
                <option disabled>Loading roles...</option>
              )}
            </select>
            {errors.role_id && (
              <div className="invalid-feedback">{errors.role_id}</div>
            )}
          </div>  <div className="col-md-6">
            <label className="form-label">Status</label>
            <select
              className={`form-control ${errors.status ? "is-invalid" : ""}`}
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="">Select Status</option>
              <option value="0">Active</option>
              <option value="1">Inactive</option>
            </select>
            {errors.status && (
              <div className="invalid-feedback">{errors.status}</div>
            )}
          </div> 
        </div>*/}

        <div className="text-left">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default UserProfile;