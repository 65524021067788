import React from 'react'

export default function BlockLoading() {
  return (
    <div>
      <span className="spinner-border spinner-border-sm mr-2" role="status" />
      <span>Loading...</span>
    </div>
  )
}
