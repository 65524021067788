import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";


let setGlobalModal;

const GlobalModal = () => {
  const [modalData, setModalDataState] = useState({
    isOpen: false,
    Component: null,
    componentProps: {},
    modalProps: {}, // Modal-specific properties like size, backdrop, etc.
  });

  setGlobalModal = (Component = null, componentProps = {}, modalProps = {}) => {
    setModalDataState({
      isOpen: !!Component,
      Component,
      componentProps,
      modalProps,
    });
  };

  const closeModal = () =>
    setModalDataState({ isOpen: false, Component: null, componentProps: {}, modalProps: {} });

  if (!modalData.isOpen) return null;

  return ReactDOM.createPortal(
    <Modal show={modalData.isOpen} onHide={closeModal} {...modalData.modalProps}
      centered
      backdrop="static"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalData.modalProps.title || "Modal Title"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalData.Component && <modalData.Component {...modalData.componentProps} onClose={closeModal} />}
      </Modal.Body>
    </Modal>,
    document.body
  );
};

export { GlobalModal, setGlobalModal };
