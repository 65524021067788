import React, { useContext, useState, useEffect } from "react";
import { Table, Button, Form, Pagination, Modal, Spinner, Col, Row } from "react-bootstrap";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import { Link } from "react-router-dom";
import Loading from 'react-fullscreen-loading';
import { AuthContext } from '../../context/AuthContext';
import Select from 'react-select';
import { toast } from "react-toastify";
import AddEditVendor from "./AddEditVendor";
import useAuthInfo from "../../../hooks/IsAdmin";
import VendorPayments from "./VendorPayments";
import VendorPaymentHistory from "./VendorPaymentHistory";

export default function ManageVendors() {
  const { isAdmin, eDepartmentId, eUser_id } = useAuthInfo();
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("u.id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [loading, setLoading] = useState(false);
  /* SEARCH VARIABLES */
  const [userName, setUserName] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [email, setEmail] = useState("");
  const [searchParams, setSearchParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState([]);
  const [editVendor, setEditVendor] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showInvoiceHistoryModal, setShowInvoiceHistoryModal] = useState(false);
  const [paymentVendorId, setPaymentVendorId] = useState();

  useEffect(() => {
    if (isAdmin || departmentId) {
      fetchData();
    }
  }, [currentPage, recordsPerPage, sortColumn, sortOrder, searchParams, departmentId]);

  useEffect(() => {
    if (!isAdmin) {
      setDepartmentId(eDepartmentId);
    }

  }, [isAdmin]);

  const fetchData = async () => {
    setLoading(true);
    try {
      //console.log(searchParams);
      const response = await axios.get(CONST.GET_VENDOR_LIST, {
        params: {
          page: currentPage,
          per_page: recordsPerPage,
          sort_by: sortColumn,
          sort_order: sortOrder,
          name: searchParams.name || "",
          status: searchParams.status || "",
          department_id: departmentId || ""
        }
      });
      if (response.data) {
        setData(response.data.data);
        setTotalRecords(response.data.total);
      } else {
        setData([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setData([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };


  /* TABLE FUNCTIONS  */
  const actionFormatter = (cell, row) => {
    return (
      <>
        <Link to="#" className="action" onClick={() => handleEdit(row)}>
          <i class='bx bx-pencil'></i>
        </Link>
        <Link to="#" className="action" onClick={() => handlePayment(row.id, row.name)}>
          <i class='bx bx-wallet'></i>
        </Link>
        <Link to="#" className="action" onClick={() => handleVendorPaymentHistory(row.id, row.name)}>
          <i class='bx bx-history'></i>
        </Link>

      </>
    );
  };
  const columns = [
    {
      dataField: "index",
      text: "Sr.no",
      sortable: true,
      onSort: () => handleSort("index"),
    },
    {
      dataField: "name",
      text: "Name",
      sortable: true,
      onSort: () => handleSort("name"),
    },

    {
      dataField: "departments",
      text: "Departments",
      sort: false,
      formatter: (cell) => {
        return cell?.split(',').map((dept, index) => (
          <div key={index}>{dept.trim()}</div>
        ));
      }
    },


    {
      dataField: "status",
      text: "Status",
      sortable: true,
      onSort: () => handleSort("status"),
      formatter: (cell) => {
        let statusText = "";
        let statusClass = "";

        switch (cell) {
          case 0:
            statusText = "Active";
            statusClass = "bg-success";
            break;
          case 1:
            statusText = "Inactive";
            statusClass = "bg-secondary";
            break;
          case 2:
            statusText = "Deleted";
            statusClass = "bg-danger";
            break;
          default:
            statusText = "Unknown";
            statusClass = "bg-light";
        }
        return <span className={`badge ${statusClass}`}>{statusText}</span>;
      },

    },
    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      onSort: () => handleSort("created_at"),
      formatter: (cell) => {
        if (!cell) return "";
        const date = new Date(cell);
        return date.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" });
      }
    },
    {
      dataField: "actions",
      text: "Actions",
      sortable: false,
      formatter: actionFormatter,
    },


  ];
  /* HANDLES */
  const handleSearch = () => {
    setSearchParams({
      name: userName ? userName : "",
      status: status ? status.value : "",
    });
    // fetchData();
  };
  const handleReset = () => {
    setUserName("");
    setStatus("")
    setSearchParams({});
    setCurrentPage(1);
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSort = (column) => {
    const order = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
  };
  const handleEdit = (data) => {
    setEditVendor(data);
    setShowModal(true);
  };

  const handlePageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  const handlePayment = (vendor_id, vendor_name) => {
    setPaymentVendorId(vendor_id);
    setShowPaymentModal(true);
    setVendorName(vendor_name)
  };

  const handlePaymentClose = () => {
    setShowPaymentModal(false);
    fetchData();
    setVendorName()
  };
  const handleVendorPaymentHistory = (vendor_id, vendor_name) => {
    setVendorName(vendor_name)
    setPaymentVendorId(vendor_id);
    setShowInvoiceHistoryModal(true);
  };
  const handleVendorPaymentHistoryModalClose = () => {
    setShowInvoiceHistoryModal(false);
    setVendorName("");

  };
  return (
    <>
      <Loading loading={loading} background="rgba(0, 0, 0, 0.5)" loaderColor="#fff" />
      <div className="container-fluid p-0">
        <div className="row mb-5">
          <div className="col-8">
            <h1 className="h3 mb-2">Manage <strong>Vendors</strong></h1>
          </div>
          <div className="col-4 text-right">
            <Link className="btn btn-secondary" to="#" onClick={() => {
              setEditVendor(null);
              setShowModal(true);
            }} ><i class='bx bx-user-plus'></i>Add Vendor</Link>
          </div>
        </div>
        <Row className="mb-3">
          {/* Financial Year Dropdown */}
          <Col>
            <Form.Control
              type="text"
              placeholder="Search Name"
              value={userName}
              onChange={handleUserNameChange}
            />
          </Col>

          <Col>
            <Select
              options={[
                { value: "0", label: "Active" },
                { value: "1", label: "InActive" },
                { value: "2", label: "Deleted" },
              ]}
              placeholder="Select Status..."
              onChange={handleStatusChange}
              value={status}
            />
          </Col>

          {/* Search & Reset Buttons */}
          <Col xs="auto">
            <Button variant="primary" onClick={handleSearch}>
              <i className='bx bx-search-alt'></i> {" "} Search
            </Button>
            <Button
              variant="secondary"
              onClick={handleReset}
              className="ml-2"
            >
              <i className='bx bx-x'></i> {" "} Reset
            </Button>
          </Col>
        </Row>

        <div className="row mb-1">
          <div className="card flex-fill pt-3 pb-2">
            <Table striped bordered hover>
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.dataField}
                      onClick={() => col.sortable && handleSort(col.dataField)}
                      style={{ cursor: col.sortable ? "pointer" : "default" }}
                    >
                      {col.text}
                      {col.sortable && (sortColumn === col.dataField ? (sortOrder === "asc" ? " ▲" : " ▼") : "")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  data.length > 0 ? (data.map((item, index) => (
                    <tr key={index}>
                      {columns.map((col) => (
                        <td key={col.dataField} >
                          {col.formatter ? col.formatter(item[col.dataField], item) : item[col.dataField]}
                        </td>
                      ))}
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        No records found.
                      </td>
                    </tr>
                  )
                }

              </tbody>
            </Table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            Showing total {totalRecords}
            <select className="m-2" onChange={handlePageChange} value={recordsPerPage}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select> records
          </div>
          <div className="col-md-8 text-right">
            <Pagination className="text-right">
              {[...Array(Math.ceil(totalRecords / recordsPerPage)).keys()].map((num) => (
                <Pagination.Item key={num + 1} active={num + 1 === currentPage} onClick={() => setCurrentPage(num + 1)}>
                  {num + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
        {/* ADD EDIT MODAL */}
        <Modal show={showModal} onHide={() => setShowModal(false)} size="md" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{editVendor ? "Edit Vendor" : "Add Vendor"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditVendor
              vendor={editVendor}
              onClose={() => setShowModal(false)}
              onSubmit={fetchData}
            />
          </Modal.Body>
        </Modal>
        {/* Invoice Payment Modal */}
        <Modal
          show={showPaymentModal}
          onHide={() => handlePaymentClose()}
          size="md"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Manage Payments - {vendorName || ""}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <VendorPayments
              vendor_id={paymentVendorId}
              onClose={() => handlePaymentClose()}
              currentUser={eUser_id}

            />
          </Modal.Body>
          {/* HISTORY MODEL */}
        </Modal>
        <Modal
          show={showInvoiceHistoryModal}
          onHide={() => handleVendorPaymentHistoryModalClose()}
          size="lg"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Vendor History - {vendorName || ""}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <VendorPaymentHistory
              vendor_id={paymentVendorId}
              currentUser={eUser_id}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};


