import React, { useState, useContext } from "react";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [authUserData, setAuthUserData] = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const user_id = authUserData ? authUserData[0].user_id : "";

  const validate = () => {
    const newErrors = {};
    if (!formData.newPassword)
      newErrors.newPassword = "New password is required";
    if (formData.newPassword && formData.newPassword.length < 6)
      newErrors.newPassword = "New password must be at least 6 characters long";
    if (formData.newPassword !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const userData = {
        user_id: user_id,
        password: formData.newPassword,
      };

      axios
        .post(CONST.UPDATE_USER_PASSWORD, userData)
        .then((response) => {
          if (response.data.status === "success") {
            setFormData({
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
            toast.success(response.data.message);
          } else {
            toast.error("Something went wrong. Please try again.");
          }
        })
        .catch((error) => {
          console.error("Error changing password", error);
          toast.error("An error occurred while changing the password.");
        });
    }
  };


  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">New Password</label>
          <input
            type="password"
            className={`form-control ${errors.newPassword ? "is-invalid" : ""
              }`}
            placeholder="New Password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
          />
          {errors.newPassword && (
            <div className="invalid-feedback">{errors.newPassword}</div>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Confirm Password</label>
          <input
            type="password"
            className={`form-control ${errors.confirmPassword ? "is-invalid" : ""
              }`}
            placeholder="Confirm New Password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
          {errors.confirmPassword && (
            <div className="invalid-feedback">
              {errors.confirmPassword}
            </div>
          )}
        </div>
        <div className="text-left">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
