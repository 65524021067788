import React, { useEffect, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import feather from "feather-icons";
import * as CONST from "../includes/Constant";
import { AuthContext } from "../context/AuthContext";
import { jwtDecode } from "jwt-decode";
import useAuthInfo from "../../hooks/IsAdmin";

function SidebarMenu() {
  const { isAdmin, eDepartmentId } = useAuthInfo();
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  let isToken = "";
  if (authUserData) {
    isToken = authUserData[0].token ? authUserData[0].token : "";
  }
  const logoStyles = {
    filter: "brightness(0) invert(1)",
    height: "32px",
  };

  const location = useLocation();
  useEffect(() => {
    feather.replace();
  }, [authUserData, location.pathname]);


  return (
    <>
      {isToken && isToken ? (
        <>
          <nav id="sidebar" className="sidebar js-sidebar">
            <div className="sidebar-content js-simplebar">
              <Link className="sidebar-brand" to={CONST.ADMIN_PRE + "dashboard"}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/eluminous-pvt-ltd.png"
                  }
                  alt="eLuminous Logo"
                  className="logo"
                  style={logoStyles}
                />
              </Link>
              <ul className="sidebar-nav">
                <li className="sidebar-header">Dashboard</li>
                <li
                  className={`sidebar-item ${location.pathname === CONST.ADMIN_PRE + "dashboard"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link
                    className="sidebar-link"
                    to={CONST.ADMIN_PRE + "dashboard"}
                  >
                    <i class='bx bxs-dashboard' ></i>{" "}
                    <span className="align-middle">Dashboard</span>
                  </Link>
                </li>
                <li
                  className={`sidebar-item  
                    ${(location.pathname === CONST.ADMIN_PRE + "invoice" ||
                      location.pathname === CONST.ADMIN_PRE + "invoice/add" ||
                      location.pathname.startsWith(CONST.ADMIN_PRE + "invoice/edit/"))
                      ? "active"
                      : ""
                    }`}
                >
                  <Link data-bs-target="#pages" data-bs-toggle="collapse" class="sidebar-link collapsed" aria-expanded="false">
                    <i class='bx bx-rupee' ></i>{" "}<span className="align-middle">Invoice</span>
                  </Link>
                  <ul id="pages" class="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar" >
                    <li class={`sidebar-item ${location.pathname === CONST.ADMIN_PRE + "invoice"
                      ? "active"
                      : ""
                      }`}>
                      <Link class="sidebar-link" to={CONST.ADMIN_PRE + "invoice"}>All Invoices</Link>
                    </li>
                    <li class={`sidebar-item ${location.pathname === CONST.ADMIN_PRE + "invoice/add"
                      ? "active"
                      : ""
                      }`}>
                      <Link class="sidebar-link" to={CONST.ADMIN_PRE + "invoice/add"}>Create Invoice</Link>
                    </li>

                  </ul>

                </li>
                <li
                  className={`sidebar-item ${location.pathname === CONST.ADMIN_PRE + "manage-client"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link
                    className="sidebar-link"
                    to={CONST.ADMIN_PRE + "manage-clients"}
                  >
                    <i className="align-middle" data-feather="users"></i>{" "}
                    <span className="align-middle">Client</span>
                  </Link>
                </li>
                {/* <li className="sidebar-header">Projects</li> */}
                <li
                  className={`sidebar-item ${location.pathname === CONST.ADMIN_PRE + "manage-projects"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link
                    className="sidebar-link"
                    to={CONST.ADMIN_PRE + "manage-projects"}
                  >
                    <i className="align-middle" data-feather="airplay"></i>{" "}
                    <span className="align-middle">Projects</span>
                  </Link>
                </li>
                {/* <li className="sidebar-header">Users</li> */}

                {
                  (isAdmin) ? (
                    <>
                      <li
                        className={`sidebar-item ${location.pathname === CONST.ADMIN_PRE + "manage-users"
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          className="sidebar-link"
                          to={CONST.ADMIN_PRE + "manage-users"}
                        >
                          <i class='bx bx-user-plus'></i>{" "}
                          <span className="align-middle">Users</span>
                        </Link>
                      </li>
                    </>
                  ) : ""
                }
                <li
                  className={`sidebar-item ${location.pathname === CONST.ADMIN_PRE + "manage-resources"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link
                    className="sidebar-link"
                    to={CONST.ADMIN_PRE + "manage-resources"}
                  >
                    <i class='bx bx-user-check'></i>
                    <span className="align-middle">Resources</span>
                  </Link>
                </li>
                {/* <li className="sidebar-header">Users</li> */}
                <li
                  className={`sidebar-item  
                    ${(location.pathname === CONST.ADMIN_PRE + "manage-vendors" ||
                      location.pathname === CONST.ADMIN_PRE + "vendor/payments"
                    )
                      ? "active"
                      : ""
                    }`}
                >
                  <Link data-bs-target="#vendor" data-bs-toggle="collapse" class="sidebar-link collapsed" aria-expanded="false">
                    <i class='bx bx-user-voice'></i>{" "}<span className="align-middle">Vendors</span>
                  </Link>
                  <ul id="vendor" class="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar" >
                    <li class={`sidebar-item ${location.pathname === CONST.ADMIN_PRE + "invoice"
                      ? "active"
                      : ""
                      }`}>
                      <Link class="sidebar-link" to={CONST.ADMIN_PRE + "manage-vendors"}>Vendors</Link>
                    </li>
                    <li class={`sidebar-item ${location.pathname === CONST.ADMIN_PRE + "vendor/payments"
                      ? "active"
                      : ""
                      }`}>
                      <Link class="sidebar-link" to={CONST.ADMIN_PRE + "vendor/payments"}>View Payments</Link>
                    </li>

                  </ul>

                </li>

                <li
                  className={`sidebar-item ${location.pathname === CONST.ADMIN_PRE + "manage-exchange"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link
                    className="sidebar-link"
                    to={CONST.ADMIN_PRE + "manage-exchange"}
                  >
                    <i class='bx bx-dollar-circle' ></i>
                    <span className="align-middle">Exchange Rate</span>
                  </Link>
                </li>

                {
                  (isAdmin) ? (
                    <>
                      {/* <li className="sidebar-header">Settings</li> */}
                      <li
                        className={`sidebar-item ${location.pathname === CONST.ADMIN_PRE + "setting"
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          className="sidebar-link"
                          to={CONST.ADMIN_PRE + "setting"}
                        >
                          <i class='bx bx-cog' ></i>{" "}
                          <span className="align-middle">Settings</span>
                        </Link>
                      </li>
                    </>
                  ) : ""
                }

              </ul>
            </div>
          </nav>
        </>
      ) : (
        ""
      )
      }
    </>
  );
}

export default SidebarMenu;
