import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import TextareaAutosize from "react-textarea-autosize";
import Form from "../../includes/Forms";
import Loading from 'react-fullscreen-loading';
import { format } from "date-fns";
import axios from 'axios';
import * as CONST from '../../includes/Constant';
import { toast } from 'react-toastify';
import '../../../assets/css/datepicker.css';
import { S_INR_CURRENCY_SYMBOL, S_FORMAT_INR_CURRENCY_AND_AMOUNT } from "../../includes/staticData";
import useAuthInfo from "../../../hooks/IsAdmin";
import { Col } from 'react-bootstrap';
import Select from 'react-select';

export default function AddResources() {
  const { isAdmin, eDepartmentId, eUser_id } = useAuthInfo();
  const [departmentData, setDepartmentData] = useState([]);
  const [departments, setDepartments] = useState(null);
  const [formData, setFormData] = useState({
    no_of_resources: "",
    month: "",
  });

  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = async () => {
    let isValid = true;
    let validator = Form.validator({
      no_of_resources: {
        value: formData.no_of_resources,
        isRequired: true,
        label: "no of resources",
      },
      month: {
        value: formData.month,
        isRequired: true,
        label: "month",
      },

    });
    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };

  useEffect(() => {
    if (!isAdmin) {
      const matchedDept = departmentData.find((d) => d.dep_id === eDepartmentId);
      setDepartments(matchedDept);

    }
  }, [isAdmin, departmentData]);

  useEffect(() => {
    fetchDepartmentData();

  }, []);

  const fetchDepartmentData = async () => {
    try {
      const departmentResponse = await axios.get(CONST.GET_DEPARTMENT_LIST);
      if (departmentResponse.data.status === 'success') {
        setDepartmentData(departmentResponse.data.data.map(department => (
          {
            value: department.id,
            label: department.name,
          }
        )));
      }
    } catch (error) {
      toast.error('Error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = await validateForm();
    if (validate) {
      setLoading(true);
      const formattedDate = formData.month.toISOString().slice(0, 7); // YYYY-MM
      //console.log(departments);
      await axios.post(CONST.ADD_RESOURCES, {
        added_by: eUser_id,
        no_of_resources: formData.no_of_resources,
        month: formattedDate,
        dept_id: departments?.value || eDepartmentId
      }).then((response) => {
        if (response) {
          const status = response.data.status;
          if (status === 'error') {
            toast.error(response.data.message);
          } else {
            //fetchPayments();
            toast.success(response.data.message);
          }
        }
      }).catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });

      setLoading(false);
    }
  }
  /* HANDLES */
  const handleDepartmentChange = (dpt) => {
    //console.log(dpt);
    setDepartments(dpt);

  };
  return (
    <>
      {loading && <Loading loading={loading} />}
      <div className='row'>
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            {
              (isAdmin) &&
              (
                <>
                  <Col className={`col-md-${(isAdmin) ? "4" : "6"}`} >
                    <label className="form-label">Department</label>
                    <Select
                      options={departmentData}
                      placeholder="Select a Department..."
                      onChange={handleDepartmentChange}
                      value={departments}
                      isDisabled={!isAdmin}
                    />
                    {validate.department && <div className="text-danger">{validate.department}</div>}
                  </Col>
                </>
              )
            }

            <Col className={`col-md-${(isAdmin) ? "4" : "6"}`} >
              <label className="form-label">No. of resources </label>
              <input
                type="text"
                className={`form-control ${validate.validate && validate.validate.no_of_resources
                  ? "is-invalid"
                  : ""
                  }`}
                placeholder="Resources"
                name="no_of_resources"
                value={formData.no_of_resources}
                onChange={handleChange}
              />
              {validate.validate && validate.validate.no_of_resources && (
                <div className="invalid-feedback">
                  {validate.validate && validate.validate.no_of_resources}
                </div>
              )}
            </Col>

            <Col className={`col-md-${(isAdmin) ? "4" : "6"}`} >
              <label className="form-label">Month</label>
              <DatePicker
                dateFormat="MM/yyyy"
                selected={formData.month}
                showMonthYearPicker
                showFullMonthYearPicker
                onChange={(date) => setFormData((prevData) => ({ ...prevData, month: date }))}
                name="month"
                className={`form-control ${validate.validate && validate.validate.month
                  ? "is-invalid"
                  : ""
                  }`}
                placeholderText="Month"

              />
              {validate.validate && validate.validate.month && (
                <div className="invalid-feedback">
                  {validate.validate && validate.validate.month}
                </div>
              )}
            </Col>
          </div>

          <div className="row">
            <div className='col-md-12 text-right'>
              <button type="submit" value="Save" className="btn btn-primary">
                <i class='bx bx-save'></i> {" "}Save</button>
            </div>
          </div>
        </form >
      </div >
      <hr className='py-2' />

    </>
  )
}
