import React, { useState, useEffect } from "react";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import Form from "../../includes/Forms";
import TextareaAutosize from "react-textarea-autosize";
import Select from 'react-select';

function AddEditVendor({ vendor, onClose, onSubmit }) {

  const [formData, setFormData] = useState({
    name: "",
    status: "0",
    vendor_departments: []
  });
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [depertmentList, setDepertmentList] = useState(null);
  const [departments, setDepartments] = useState(null);

  useEffect(() => {
    fetchDepartmentData();
  }, []);
  useEffect(() => {
    setLoading(true);
    if (vendor) {
      setFormData({
        name: vendor.name || "",
        status: vendor.status || "0",
        vendor_departments: vendor.vendor_departments
          ? vendor.vendor_departments.map((dep) => ({
            value: dep.value,
            label: dep.label,
          }))
          : [],
      });
      setDepartments(vendor.vendor_departments || []);
    }
    setLoading(false);
  }, [vendor]);

  /* HANDLES  */
  const fetchDepartmentData = async () => {
    try {
      const departmentResponse = await axios.get(CONST.GET_DEPARTMENT_LIST);
      if (departmentResponse.data.status === 'success') {
        setDepertmentList(departmentResponse.data.data.map(department => (
          {
            value: department.id,
            label: department.name,
          }
        )));
      }
    } catch (error) {
      toast.error('Error:', error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = async () => {
    let isValid = true;
    let errors = {};

    const validator = Form.validator({
      name: {
        value: formData.name,
        isRequired: true,
        label: "name",
      },
      status: {
        value: formData.status,
        isRequired: true,
        label: "status",
      },
      vendor_departments: {
        value: formData.vendor_departments,
        isRequired: true,
        label: "depertments",
      },
    });

    if (validator && validator.errors) {
      errors = { ...errors, ...validator.errors };
      isValid = false;
    }
    setValidate({
      validate: errors,
    });

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (!isValid) return;

    setLoading(true);

    const mappedFormData = {
      vendor_id: vendor ? vendor.id : "",
      name: formData.name,
      status: formData.status,
      vendor_departments: formData.vendor_departments,

    };
    //console.log(formData);
    //console.log(mappedFormData);

    const request = vendor
      ? axios.post(CONST.UPDATE_VENDOR, mappedFormData)
      : axios.post(CONST.ADD_VENDOR, mappedFormData);

    request
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          onSubmit();
          onClose();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error saving vendor:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleReactSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };
  const handleDepartmentChange = (dpt) => {
    setDepartments(dpt);
    setFormData((prev) => ({
      ...prev,
      vendor_departments: dpt
    }));
  };
  return (
    <>
      {loading && <Loading loading={loading} />}
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          {/* Vendor Name */}
          <div className="col-md-12">
            <label className="form-label">Vendor Name</label>
            <input
              type="text"
              className={`form-control ${validate.validate && validate.validate.name ? "is-invalid" : ""
                }`}
              placeholder="Vendor Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {validate.validate && validate.validate.name && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.name}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <label className="form-label">Department</label>
            <Select
              isMulti
              name="vendor_departments"
              options={depertmentList}
              value={formData.vendor_departments}
              onChange={handleDepartmentChange}
              className={`react-select-container ${validate.validate && validate.validate.vendor_departments ? "is-invalid" : ""
                }`}
              placeholder="Select Department(s)"
            />
            {validate.validate && validate.validate.vendor_departments && (
              <div className="invalid-feedback d-block">
                {validate.validate.vendor_departments}
              </div>
            )}

          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <label className="form-label">Status</label>
            <select
              className={`form-control ${validate.validate && validate.validate.status
                ? "is-invalid"
                : ""
                }`}
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="0">Active</option>
              <option value="1">Inactive</option>
              <option value="2">Delete</option>
            </select>
            {validate.validate && validate.validate.status && (
              <div className="invalid-feedback">
                {validate.validate && validate.validate.status}
              </div>
            )}
          </div>
        </div>
        <div className="text-left">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form >
    </>
  );
}

export default AddEditVendor;
