import { useState, React, createContext, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();
export const AuthProvider = (props) => {
  let authUserFirstName = "";
  let authUserStatus = "";
  let authUseremail = '';
  let user_id = '';
  let dept_id = "";
  let role_id = '';
  let isAdmin = 'no';
  let authUserToken = localStorage.getItem("etIMSToken")
    ? localStorage.getItem("etIMSToken")
    : "";


  if (authUserToken) {
    const decodedToken = jwtDecode(authUserToken);
    const tokenData = decodedToken ? decodedToken : "";
    if (tokenData) {
      authUserFirstName = tokenData.sub.name
        ? tokenData.sub.name
        : "";

      authUseremail = tokenData.sub.email
        ? tokenData.sub.email
        : "";
      authUserStatus = tokenData.sub.status
        ? tokenData.sub.status
        : "";
      user_id = tokenData.sub.user_id ? tokenData.sub.user_id : "";
      role_id = tokenData.sub.role_id ? tokenData.sub.role_id : "";
      dept_id = tokenData.sub.dept_id ? tokenData.sub.dept_id : "";
      if (role_id === 1) {
        isAdmin = 'yes';
      }
    }

  }
  const [authUserData, setAuthUserData] = useState([
    {
      name: authUserFirstName,
      status: authUserStatus,
      token: authUserToken,
      email: authUseremail,
      user_id: user_id,
      role_id: role_id,
      dept_id: dept_id,
      isAdmin:isAdmin,
    },
  ]);
  return (
    <AuthContext.Provider value={[authUserData, setAuthUserData]}>
      {" "}
      {props.children}{" "}
    </AuthContext.Provider>
  );
};