import React, { useContext, useEffect, useState } from 'react'
import Form from '../includes/Forms'
import * as CONST from '../includes/Constant'
import axios from "axios";
import { toast } from "react-toastify";
import Loading from 'react-fullscreen-loading';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";
import { jwtDecode } from "jwt-decode";
import feather from "feather-icons";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  let [isExpired, setIsExpired] = useState(false);
  let access_token = '';
  /* Validate Login */
  const validateLogin = async () => {
    let isValid = true;
    let validator = Form.validator({
      email: {
        value: email,
        isRequired: true,
        isEmail: true,
        label: "email",
      },
      password: {
        value: password,
        isRequired: true,
        minLength: 6,
        label: "password",
      },
    });
    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };
  const frmAuthenticate = async (e) => {
    e.preventDefault();
    const validate = await validateLogin();
    if (validate) {
      setLoading(true);
      await axios.post(CONST.LOGIN, {
        email: email,
        password: password,
      })
        .then((response) => {
          if (response) {
            setLoading(false);
            const status = response.data.status;
            if (status === 'error') {
              toast.error(response.data.message);
            } else if (status === "success") {
              access_token = response.data.token ? response.data.token : "";
              // //console.log(access_token);
              const decodedToken = jwtDecode(access_token);
              ////console.log("Decoded JWT:", decodedToken);
              const tokenData = decodedToken ? decodedToken : "";
              if (tokenData) {
                localStorage.setItem("etIMSToken", access_token);
                const name = tokenData.sub.name
                  ? tokenData.sub.name
                  : "";


                const email = tokenData.sub.email
                  ? tokenData.sub.email
                  : "";

                const status = tokenData.sub.status
                  ? tokenData.sub.status
                  : "";
                const user_id = tokenData.sub.user_id
                  ? tokenData.sub.user_id
                  : "";
                const role_id = tokenData.sub.role_id
                  ? tokenData.sub.role_id
                  : "";
                const dept_id = tokenData.sub.dept_id
                  ? tokenData.sub.dept_id
                  : "";

                const UserData = [
                  {
                    name: name,
                    email: email,
                    status: status,
                    token: access_token,
                    user_id: user_id,
                    role_id: role_id,
                    dept_id: dept_id,
                  },
                ];
                setAuthUserData(UserData);
                toast.success("Login Successfully.. Please wait");
                navigate("/admin/dashboard");
              }

            }
            else {
              toast.error("Something went wrong. Please try again.");
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again.");
        });
    }
  }
  //console.log(authUserData);


  useEffect(() => {
    feather.replace();
    if (authUserData) {
      let isToken = authUserData[0]?.token || "";
      //console.log(isToken);
      if (isToken) {
        navigate("/admin/dashboard");
      }
    }
  }, [access_token]);
  return (
    <>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <main className="d-flex w-100">
        <div className="container d-flex flex-column mt-5">
          <div className="row">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-5">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/eluminous-pvt-ltd.png"
                    }
                    alt="logo"
                    style={{ width: "250px" }}
                    className="img-fluid"
                  />
                  {/* <h1 className="h3 mt-3">Welcome back!</h1> */}
                  <p className="lead mt-3">
                    Sign in to your account to continue
                  </p>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-3">
                      <form
                        className="auth-form"
                        method="POST"
                        onSubmit={frmAuthenticate}
                        autoComplete={"off"}
                      >
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <input
                            type="email"
                            className={`form-control form-control-lg ${validate.validate && validate.validate.email
                              ? "is-invalid "
                              : ""
                              }`}
                            id="email"
                            name="email"
                            value={email}
                            placeholder="your@email.com"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <div
                            className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.email
                              ? "d-block"
                              : "d-none"
                              }`}
                          >
                            {validate.validate && validate.validate.email
                              ? validate.validate.email[0]
                              : ""}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Password</label>
                          <input
                            type="password"
                            className={`form-control form-control-lg ${validate.validate && validate.validate.password
                              ? "is-invalid "
                              : ""
                              }`}
                            name="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Your password"
                          />
                          <div
                            className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.password
                              ? "d-block"
                              : "d-none"
                              }`}
                          >
                            {validate.validate && validate.validate.password
                              ? validate.validate.password[0]
                              : ""}
                          </div>
                        </div>
                        <div>
                          <div className="form-check align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              value="remember-me"
                              name="remember-me"
                              id="remember"
                              checked={remember}
                              onChange={(e) =>
                                setRemember(e.currentTarget.checked)
                              }
                            />
                            <label
                              className="form-check-label text-small"
                              for="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                          <span className="form-label-description mt-2"></span>
                        </div>
                        <div className="d-grid gap-2 mt-3">
                          <button
                            type="submit"
                            id="signin"
                            className="btn btn-lg btn-primary"
                          >
                            Sign in
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="text-center mb-3">
                  Sorry, But{" "}
                  <Link to="/forget-password">I forgot password..</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Login