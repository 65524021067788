import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import TextareaAutosize from "react-textarea-autosize";
import Form from "../../includes/Forms";
import Loading from 'react-fullscreen-loading';
import { format } from "date-fns";
import axios from 'axios';
import * as CONST from '../../includes/Constant';
import { toast } from 'react-toastify';
import '../../../assets/css/datepicker.css';
import Swal from "sweetalert2";
 

import { S_INR_CURRENCY_SYMBOL, S_FORMAT_INR_CURRENCY_AND_AMOUNT } from "../../includes/staticData";
export default function VendorPayments({ vendor_id, onClose, currentUser }) {
  const [payments, setPayments] = useState([]);
  const [formData, setFormData] = useState({
    amount: "",
    payment_date: "",

  });

  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = async () => {
    let isValid = true;
    let validator = Form.validator({
      amount: {
        value: formData.amount,
        isRequired: true,
        label: "amount",
      },
      payment_date: {
        value: formData.payment_date,
        isRequired: true,
        label: "received date",
      },

    });
    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = await validateForm();
    if (validate) {
      setLoading(true);
      //const formattedDate = formData.payment_date.toISOString().slice(0, 7); // YYYY-MM
      const date = new Date(formData.payment_date);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;

      const mappedFormData = {
        amount: formData.amount,
        payment_date: formattedDate,
      };
      /* setLoading(false);
      console.log(formData.payment_date);
      console.log(mappedFormData); 
      return "";*/

      if (mappedFormData) {
        await axios.post(CONST.ADD_VENDOR_PAYMENTS, {
          vendor_id: vendor_id,
          updated_by: currentUser,
          paymentData: mappedFormData,
        }).then((response) => {
          if (response) {
            const status = response.data.status;
            if (status === 'error') {
              toast.error(response.data.message);
            } else {
              //fetchPayments();
              toast.success(response.data.message);
            }
          }
        }).catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again.");
        });
      }

      //console.log(mappedFormData);
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <Loading loading={loading} />}
      <div className='row'>
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="form-label">Amount <strong>({S_INR_CURRENCY_SYMBOL('inr') || ""})</strong></label>
              <input
                type="text"
                className={`form-control ${validate.validate && validate.validate.amount
                  ? "is-invalid"
                  : ""
                  }`}
                placeholder="Amount"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
              />
              {validate.validate && validate.validate.amount && (
                <div className="invalid-feedback">
                  {validate.validate && validate.validate.amount}
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label className="form-label">Transaction Date</label>
              <DatePicker
                dateFormat="MM/yyyy"
                selected={formData.payment_date}
                showMonthYearPicker
                showFullMonthYearPicker
                onChange={(date) => setFormData((prevData) => ({ ...prevData, payment_date: date }))}
                name="payment_date"
                className={`form-control ${validate.validate && validate.validate.payment_date
                  ? "is-invalid"
                  : ""
                  }`}
                placeholderText="Invoice Date"

              />
              {validate.validate && validate.validate.payment_date && (
                <div className="invalid-feedback">
                  {validate.validate && validate.validate.payment_date}
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className='col-md-12 text-left'>
              <button type="submit" value="Save" className="btn btn-primary">
                <i class='bx bx-save'></i> {" "}Save</button>
            </div>
          </div>
        </form>
      </div>
      <hr className='py-2' />

    </>
  )
}
