import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import TextareaAutosize from "react-textarea-autosize";
import Form from "../../includes/Forms";
import Loading from 'react-fullscreen-loading';
import { format } from "date-fns";
import axios from 'axios';
import * as CONST from '../../includes/Constant';
import { toast } from 'react-toastify';
import { SD_INVOICE_STATUS, SD_MODE_OF_PAYMENT, S_FORMAT_INR_CURRENCY_AND_AMOUNT, S_GET_CURRENCY_SYMBOL } from "../../includes/staticData";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Col } from 'react-bootstrap';

export default function InvoicePayments({ invoice_id, onClose, currentUser, invoiceStatus, selectedInvoice }) {
  const [payments, setPayments] = useState([]);
  const [formData, setFormData] = useState({
    amount: "",
    exchange_rate: "",
    received_date: "",
    transaction_id: "",
    mode: "",
    note: "",
    status: "",
    platform_fee: ""
  });

  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  /* Inline Editing */
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editAmount, setEditAmount] = useState("");
  const [editPlatformFee, setEditPlatformFee] = useState("");
  const [editExRate, setEditExRate] = useState("");
  const [editDate, setEditDate] = useState("");
  const [editTransactionId, setEditTransactionId] = useState("");
  const [editMode, setEditMode] = useState("");
  const [editNote, setEditNote] = useState("");
  const [editStatus, setEditStatus] = useState("");


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = async () => {
    let isValid = true;
    let validator = Form.validator({
      amount: {
        value: formData.amount,
        isRequired: true,
        label: "amount",
      },
      received_date: {
        value: formData.received_date,
        isRequired: true,
        label: "received date",
      },
      transaction_id: {
        value: formData.transaction_id,
        isRequired: true,
        label: "transaction id",
      },
      mode: {
        value: formData.mode,
        isRequired: true,
        label: "mode",
      },
      status: {
        value: formData.status,
        isRequired: true,
        label: "status",
      },
    });
    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = await validateForm();
    if (validate) {
      setLoading(true);
      const mappedFormData = {
        amount: formData.amount,
        received_date: format(formData.received_date, "dd/MM/yyyy"),
        transaction_id: formData.transaction_id,
        mode: formData.mode,
        note: formData.note,
        status: formData.status,
        exchange_rate: formData.exchange_rate,
        platform_fee: formData.platform_fee
      };

      if (mappedFormData) {
        await axios.post(CONST.ADD_INVOICE_PAYMENTS, {
          invoice_id: invoice_id,
          updated_by: currentUser,
          paymentData: mappedFormData,
          invoice_currency: selectedInvoice.currency || "INR"
        }).then((response) => {
          if (response) {
            const status = response.data.status;
            if (status === 'error') {
              toast.error(response.data.message);
            } else {
              fetchPayments();
              toast.success(response.data.message);
            }
          }
        }).catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again.");
        });
      }

      console.log(mappedFormData);
      setLoading(false);
    }
  }
  const fetchPayments = async () => {
    try {
      setLoading(true);
      const response = await axios.post(CONST.GET_INVOICE_PAYMENTS, {
        invoice_id: invoice_id,
      });
      if (response) {
        if (response.data.status === "success" && response.data.data.length > 0) {
          let ePayments = response.data.data;
          console.log(ePayments);
          setPayments(ePayments);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Please try again.");
    }
  }
  useEffect(() => {
    fetchPayments();
  }, [])
  const handleInlineEdit = (index, payment) => {
    setEditingRowIndex(index);
    setEditAmount(payment.amount);
    setEditExRate(payment.exchange_rate);
    setEditPlatformFee(payment.platform_fee);
    setEditDate(new Date(payment.received_date));
    setEditTransactionId(payment.transaction_id);
    setEditMode(payment.mode);
    setEditStatus(payment.status);

  };

  const handleInlineSave = async (paymentId) => {
    const updatedData = {
      amount: editAmount,
      exchange_rate: editExRate,
      platform_fee: editPlatformFee,
      received_date: format(editDate, "dd/MM/yyyy"),
      transaction_id: editTransactionId,
      mode: editMode,
      note: editNote,
      status: invoiceStatus,
    };

    try {
      setLoading(true);
      const response = await axios.post(CONST.UPDATE_INVOICE_PAYMENTS, {
        invoice_id,
        updated_by: currentUser,
        paymentData: updatedData,
        payment_id: paymentId,
      });

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchPayments();
        setEditingRowIndex(null);
      } else {
        toast.error(response.data.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const handleDeletePayment = (paymentId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this payment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const response = await axios.post(CONST.DELETE_INVOICE_PAYMENT, {
            payment_id: paymentId,
            updated_by: currentUser,
          });

          if (response.data.status === "success") {
            toast.success(response.data.message);
            fetchPayments(); // refresh the payment list
          } else {
            toast.error(response.data.message);
          }
        } catch (err) {
          toast.error("Something went wrong while deleting payment");
        } finally {
          setLoading(false);
        }
      }
    });
  };




  return (
    <>
      {loading && <Loading loading={loading} />}
      <div className='row'>
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <Col md={3} sm={6} lg={2} >
              <label className="form-label">Amount in <strong>({S_GET_CURRENCY_SYMBOL(selectedInvoice.currency || "INR")})</strong></label>
              <input
                type="text"
                className={`form-control ${validate.validate && validate.validate.amount
                  ? "is-invalid"
                  : ""
                  }`}
                placeholder="Amount"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
              />
              {validate.validate && validate.validate.amount && (
                <div className="invalid-feedback">
                  {validate.validate && validate.validate.amount}
                </div>
              )}
            </Col>
            <Col md={3} sm={6} lg={2} >
              <label className="form-label">Exchange rate</label>
              <input
                type="text"
                className="form-control"
                placeholder="Ex. Rate"
                name="exchange_rate"
                value={formData.exchange_rate}
                onChange={handleChange}
              />

            </Col>
            <Col md={3} sm={6} lg={2} >
              <label className="form-label">Flatform fee</label>
              <input
                type="text"
                className="form-control"
                placeholder="Fee"
                name="platform_fee"
                value={formData.platform_fee}
                onChange={handleChange}
              />

            </Col>
            <Col md={3} sm={6} lg={2} >
              <label className="form-label">Transaction Date</label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={formData.received_date}
                onChange={(date) => setFormData((prevData) => ({ ...prevData, received_date: date }))}
                name="received_date"
                className={`form-control ${validate.validate && validate.validate.received_date
                  ? "is-invalid"
                  : ""
                  }`}
                placeholderText="Transaction Date"

              />
              {validate.validate && validate.validate.received_date && (
                <div className="invalid-feedback">
                  {validate.validate && validate.validate.received_date}
                </div>
              )}
            </Col>

            <Col md={3} sm={6} lg={2} >
              <label className="form-label">Transaction ID</label>
              <input
                type="text"
                className={`form-control ${validate.validate && validate.validate.transaction_id
                  ? "is-invalid"
                  : ""
                  }`}
                placeholder="Transaction Id"
                name="transaction_id"
                value={formData.transaction_id}
                onChange={handleChange}
              />
              {validate.validate && validate.validate.transaction_id && (
                <div className="invalid-feedback">
                  {validate.validate && validate.validate.transaction_id}
                </div>
              )}
            </Col>

            <Col md={3} sm={6} lg={2} >
              <label className="form-label">Mode</label>
              <select
                className={`form-control ${validate.validate && validate.validate.mode
                  ? "is-invalid"
                  : ""
                  }`}
                placeholder="Mode"
                name="mode"
                value={formData.mode}
                onChange={handleChange}
              >
                <option value="">Select Mode</option>
                {SD_MODE_OF_PAYMENT.map((statusItem) => (
                  <option key={statusItem.code} value={statusItem.code}>
                    {statusItem.name}
                  </option>
                ))}
              </select>
              {validate.validate && validate.validate.mode && (
                <div className="invalid-feedback">
                  {validate.validate && validate.validate.mode}
                </div>
              )}
            </Col>
          </div>
          <div className="row mb-3">
            <Col md={6}  >
              <label className="form-label">Note</label>
              <TextareaAutosize
                className="form-control"
                placeholder="Please enter internal note"
                name="note"
                value={formData.note}
                onChange={handleChange}
                minRows={2} // Minimum number of rows
                maxRows={10} // Maximum number of rows before scrolling
                style={{
                  width: "100%",
                  resize: "none",
                }}
              />

            </Col>
            <Col md={3} sm={6} lg={2} >
              <label className='form-label'>Payment Status</label>
              <select
                name='status'
                id="select"
                className={`form-control ${validate.validate && validate.validate.status ? "is-invalid" : ""}`}
                value={formData.status}
                onChange={handleChange}
              >
                <option value="">Payment Status</option>
                {SD_INVOICE_STATUS.map((statusItem) => (
                  <option key={statusItem.code} value={statusItem.code}>
                    {statusItem.name}
                  </option>
                ))}
              </select>

              {validate.validate && validate.validate.status && (
                <div className="invalid-feedback">
                  {validate.validate && validate.validate.status}
                </div>
              )}
            </Col>
          </div>
          <div className="row">
            <div className='col-md-12 text-right'>
              <button type="submit" value="Save" className="btn btn-primary"><i class='bx bx-save'></i> {" "}Save</button>
            </div>
          </div>
        </form>
      </div>
      <hr className='py-2' />
      <div className='row'>
        <h4>Payments</h4>
        {
          payments && (
            <>
              <div className='col-12'>
                <table className="table table-striped table-bordered " style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>Amount ({S_GET_CURRENCY_SYMBOL(selectedInvoice.currency || "INR")})</th>

                      {selectedInvoice && selectedInvoice.currency !== 'INR' && (
                        <>
                          <th>Amount ({S_GET_CURRENCY_SYMBOL("INR")})</th>
                          <th>Ex. rate</th>

                        </>
                      )}
                      <th>Fee</th>
                      <th>Txn Date</th>
                      <th >Txn Id</th>
                      <th>Mode</th>
                      <th>Note</th>
                      <th>Status</th>
                      <th>Update By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((payment, index) => {
                      const isEditing = editingRowIndex === index;
                      return (
                        <tr key={payment.id}>
                          <td className="text-nowrap" >
                            {
                              isEditing ? (
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={editAmount}
                                  onChange={(e) => setEditAmount(e.target.value)}
                                />
                              ) : (
                                <>
                                  {S_GET_CURRENCY_SYMBOL(selectedInvoice.currency || "INR")}{(payment.amount || 0)}
                                </>

                              )}
                          </td>
                          {selectedInvoice && selectedInvoice.currency !== 'INR' && (
                            <>
                              <td className="text-nowrap" >
                                {S_FORMAT_INR_CURRENCY_AND_AMOUNT(payment.inr_amount || 0)}

                              </td>
                              <td>
                                {
                                  isEditing ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      value={editExRate}
                                      onChange={(e) => setEditExRate(e.target.value)}
                                    />
                                  ) : (
                                    <>
                                      {payment.exchange_rate || "1.0"}
                                    </>

                                  )
                                }
                              </td>
                            </>
                          )}
                          <td>
                            {
                              isEditing ? (
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={editPlatformFee}
                                  onChange={(e) => setEditPlatformFee(e.target.value)}
                                />
                              ) : (
                                <>
                                  {payment.platform_fee || "0.00"}
                                </>

                              )
                            }
                          </td>
                          <td className="text-nowrap" >
                            {isEditing ? (
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={editDate}
                                onChange={(date) => setEditDate(date)}
                                className="form-control form-control-sm"
                              />
                            ) : (
                              new Date(payment.received_date).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })
                            )}
                          </td>
                          <td className="text-nowrap" >
                            {isEditing ? (
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={editTransactionId}
                                onChange={(e) => setEditTransactionId(e.target.value)}
                              />
                            ) : (
                              payment.transaction_id
                            )}
                          </td>
                          <td className="text-nowrap" style={{ position: "relative", zIndex: 1000 }}>
                            {isEditing ? (
                              <select
                                className="form-control form-control-sm"
                                value={editMode}
                                onChange={(e) => setEditMode(e.target.value)}
                                style={{ position: 'relative', zIndex: 1001 }}
                              >
                                <option value="">Select</option>
                                {SD_MODE_OF_PAYMENT.map((mode) => (
                                  <option key={mode.code} value={mode.code}>
                                    {mode.name}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              payment.mode
                            )}
                          </td>
                          <td className="text-nowrap" >
                            {isEditing ? (
                              <TextareaAutosize
                                className="form-control form-control-sm"
                                minRows={1}
                                maxRows={4}
                                value={editNote}
                                onChange={(e) => setEditNote(e.target.value)}
                              />
                            ) : (
                              payment.note
                            )}
                          </td>
                          <td className="text-nowrap" style={{ position: "relative", zIndex: 1000 }}>
                            {isEditing ? (
                              <select
                                className="form-control form-control-sm"
                                value={editStatus}
                                onChange={(e) => setEditStatus(e.target.value)}
                                style={{ zIndex: 10, position: "relative" }}
                              >
                                <option value="">Select</option>
                                {SD_INVOICE_STATUS.map((status) => (
                                  <option key={status.code} value={status.code}>
                                    {status.name}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              (() => {
                                const statusObj = SD_INVOICE_STATUS.find(
                                  (item) => item.code === payment.status
                                );
                                return statusObj ? (
                                  <span className={`badge ${statusObj.color}`}>{statusObj.name}</span>
                                ) : (
                                  <span className="badge bg-secondary">Unknown</span>
                                );
                              })()
                            )}
                          </td>

                          <td className="text-nowrap" >
                            {payment.updated_by_name}
                          </td>
                          <td className="text-nowrap" >
                            {isEditing ? (
                              <>
                                <button
                                  className="btn btn-success btn-sm me-1"
                                  onClick={() => handleInlineSave(payment.id)}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-secondary btn-sm me-1"
                                  onClick={() => setEditingRowIndex(null)}
                                >
                                  Cancel
                                </button>

                              </>
                            ) : (
                              <>

                                <Link to="#" className="action" onClick={() => handleInlineEdit(index, payment)}
                                >
                                  <i class='bx bx-pencil'></i>
                                </Link>

                                <Link to="#" className="action" onClick={() => handleDeletePayment(payment.id)}>
                                  <i class='bx bx-trash'></i>
                                </Link>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>


                </table>
              </div>
            </>
          )
        }
      </div>
    </>
  )
}
