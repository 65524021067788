import React, { useContext, useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import feather from 'feather-icons';
import '../../../invoice.css';
import Loading from 'react-fullscreen-loading';
import axios from 'axios';
import * as CONST from '../../includes/Constant';
import { Link, useNavigate } from 'react-router-dom';
import Form from '../../includes/Forms';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { AuthContext } from '../../context/AuthContext';
import TextareaAutosize from "react-textarea-autosize";
import { format } from "date-fns";
import useAuthInfo from "../../../hooks/IsAdmin";
export default function AddInvoice() {
  const { isAdmin, eDepartmentId } = useAuthInfo();
  const navigate = useNavigate();
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState("");
  const [clients, setClients] = useState([]);
  const [project, setProject] = useState([]);
  const [arrProjects, setArrProjects] = useState([]);
  const [settings, setSettings] = useState({});
  const [validate, setValidate] = useState({});
  const [departmentData, setDepartmentData] = useState([]);
  const [departments, setDepartments] = useState(null);
  const [generateInvoice, setGenerateInvoice] = useState(null);
  const [fileName, setFileName] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [internalNote, setInternalNote] = useState();
  const [isGenrateButtonDisabled, setIsGenrateButtonDisabled] = useState(false);
  const [isInvoiceButtonDisabled, setIsInvoiceButtonDisabled] = useState(true);
  const [iErrors, setIErrors] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  useEffect(() => {
    if (!isAdmin) {
      const matchedDept = departmentData.find((d) => d.dep_id === eDepartmentId);
      //console.log(matchedDept);
      setDepartments(matchedDept);
      fetchClientData(eDepartmentId);
    }
  }, [isAdmin, departmentData]);

  const formatINRCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  let totalPayment = '';
  let totalGross = '';
  let totalGstAmount = '';
  useEffect(() => {
    feather.replace();
    //fetchClientData();
    fetchDepartmentData();
    fetchSettings();
  }, []);

  useEffect(() => {
    feather.replace();
  }, [generateInvoice]);

  useEffect(() => {
    if (client) {
      fetchClientProjects(client.value);
    }
  }, [client]);


  const fetchSettings = async () => {
    try {
      const response = await axios.get(CONST.GET_SETTING_LIST);
      if (response.data.status === 'success') {
        const settingsData = response.data.data.reduce((acc, item) => {
          acc[item.meta_key] = item.meta_value;
          return acc;
        }, {});
        setSettings(settingsData);
      }
    } catch (error) {
      toast.error('Error:', error);
    }
  };
  const fetchDepartmentData = async () => {
    try {
      const departmentResponse = await axios.get(CONST.GET_DEPARTMENT_LIST, {
        params: { is_invoice: 0 }
      });
      if (departmentResponse.data.status === 'success') {
        setDepartmentData(departmentResponse.data.data.map(department => (
          {
            value: department.abbr,
            label: department.name,
            dep_id: department.id,
          }
        )));
      }
    } catch (error) {
      toast.error('Error:', error);
    }
  };
  const fetchClientData = async (departmentId) => {
    setLoading(true);
    try {
      const clientsResponse = await axios.get(CONST.GET_ALL_CLIENT, {
        params: departmentId ? { department_id: departmentId } : {},
      });
      if (clientsResponse.data.status === 'success') {
        setClients(clientsResponse.data.data.map(client => ({
          value: client.id,
          client_id: client.id,
          label: client.name,
          name: client.name,
          country: client.country,
          currency: client.currency,
          company_name: client.company_name,
          billing_address: client.billing_address,
          state: client.state,
          gst: client.gst,
          hsn_number: client.hsn_number,
          account_details: client.account_details
        })));
      }
    } catch (error) {
      toast.error('Error:', error);
    }
    setLoading(false);
  };
  const fetchClientProjects = async (clientId) => {
    try {
      const response = await axios.post(CONST.GET_CLIENT_PROJECTS, { cid: clientId });
      if (response.data.status === 'success') {
        setArrProjects(response.data.data.map(project => ({ value: project.id, label: project.name })));
      }
    } catch (error) {
      toast.error('Error fetching projects:', error);

    }
  };
  const handleSearch = () => {
    if (!client || !departments) {
      setValidate({
        client: !client ? 'Client is required' : '',
        department: !departments ? 'Department is required' : '',
      });
      return;
    }

    setGenerateInvoice({
      iClient: client,
      iProject: project,
      iDepartments: departments,
    });
    setIsGenrateButtonDisabled(true);
    setIsInvoiceButtonDisabled(true);
  };
  const handleClientChange = (selectedClient) => {
    setClient(selectedClient);
    setIsInvoiceButtonDisabled(false);
  };
  const handleDepartmentChange = (dpt) => {
    //console.log(dpt);
    setDepartments(dpt);
    fetchClientData(dpt?.dep_id);
  };
  const handleReset = () => {
    setClient("");
    setProject(null);
    setDepartments(null);
    setGenerateInvoice(null);
    setValidate({});
    setInvoiceItems([
      { invoiceSrNo: 1, invoiceDescription: "", invoiceTotal: "", invoiceGross: "", invoiceCgstRate: "", invoiceCgstAmount: "", invoiceSgstRate: "", invoiceSgstAmount: "", invoiceIgstRate: "", invoiceIgstAmount: "" }
    ]);
    setIsGenrateButtonDisabled(false);
    setIsGenrateButtonDisabled(false);
    if (!isAdmin) {
      const matchedDept = departmentData.find((d) => d.dep_id === eDepartmentId);
      //console.log(matchedDept);
      setDepartments(matchedDept);
    }
  };
  const getCurrencySymbol = (currencyCode) => {
    return (0).toLocaleString("en", { style: "currency", currency: currencyCode }).replace(/\d|,|\.|\s/g, '');
  };
  const [invoiceItems, setInvoiceItems] = useState([
    { invoiceSrNo: 1, invoiceDescription: "", invoiceTotal: "", invoiceGross: "", invoiceCgstRate: "", invoiceCgstAmount: "", invoiceSgstRate: "", invoiceSgstAmount: "", invoiceIgstRate: "", invoiceIgstAmount: "" }
  ]);
  // Add New Row
  const addRow = () => {
    setInvoiceItems([...invoiceItems, { invoiceSrNo: invoiceItems.length + 1, invoiceDescription: "", invoiceTotal: "", invoiceGross: "", invoiceCgstRate: "", invoiceCgstAmount: "", invoiceSgstRate: "", invoiceSgstAmount: "", invoiceIgstRate: "", invoiceIgstAmount: "" }]);
  };
  // Remove Row
  const removeRow = (index) => {
    const updatedItems = invoiceItems.filter((_, i) => i !== index);
    setInvoiceItems(updatedItems);
  };
  // Handle Input Change
  const handleInputChange = (index, field, value) => {
    let updatedItems = [...invoiceItems];
    updatedItems[index][field] = value;
    if (field === "invoiceGross") {
      const total = parseFloat(value) || 0;
      if (client.country === "IN") {
        const gstRate = 18; // Example GST Rate
        const gstAmount = (total * gstRate) / 100;
        updatedItems[index].invoiceGross = total;
        updatedItems[index].invoiceTotal = total + gstAmount;
        updatedItems[index].invoiceCgstRate = 9;
        updatedItems[index].invoiceCgstAmount = (total * 9) / 100;
        updatedItems[index].invoiceSgstRate = 9;
        updatedItems[index].invoiceSgstAmount = (total * 9) / 100;
        updatedItems[index].invoiceIgstRate = 18;
        updatedItems[index].invoiceIgstAmount = gstAmount;
      } else {
        updatedItems[index].invoiceTotal = total;
      }
    }
    setInvoiceItems(updatedItems);
  };
  totalPayment = invoiceItems.reduce((sum, item) => sum + (parseFloat(item.invoiceTotal) || "0.00"), 0);
  totalGross = invoiceItems.reduce((sum, item) => sum + (parseFloat(item.invoiceGross) || "0.00"), 0);
  totalGstAmount = invoiceItems.reduce((sum, item) => sum + (parseFloat(item.invoiceIgstAmount) || "0.00"), 0);
  /* if (client.country === "IN") {
    totalPayment=  formatINRCurrency(totalPayment);
    totalGross=  formatINRCurrency(totalGross);
    totalGstAmount=  formatINRCurrency(totalGstAmount);
  } */


  const validateInvoiceItems = (invoiceItems) => {
    let tempErrors = invoiceItems.map((item, index) => {
      let iErrors = {};

      // Validate product name
      if (!item.invoiceDescription || item.invoiceDescription.trim() === "") {
        iErrors.invoiceDescription = `Row ${index + 1}: Product name is required`;
        toast.error(`Row ${index + 1}: Product name is required`);
      }

      // Validate invoice total
      if (item.invoiceTotal === "" || isNaN(Number(item.invoiceTotal)) || Number(item.invoiceTotal) < 0) {
        iErrors.invoiceTotal = `Row ${index + 1}: Enter a valid total amount`;
        toast.error(`Row ${index + 1}: Enter a valid total amount`);
      }

      // Ensure client object exists before checking country/state
      if (client?.country === "IN") {
        if (item.invoiceGross === "" || isNaN(Number(item.invoiceGross)) || Number(item.invoiceGross) < 0) {
          iErrors.invoiceGross = `Row ${index + 1}: Enter a valid gross amount`;
          toast.error(`Row ${index + 1}: Enter a valid gross amount`);
        }

        if (client?.state === "MH") {
          if (item.invoiceCgstRate === "" || isNaN(Number(item.invoiceCgstRate)) || Number(item.invoiceCgstRate) < 0 || Number(item.invoiceCgstRate) > 100) {
            iErrors.invoiceCgstRate = `Row ${index + 1}: CGST rate must be between 0 and 100`;
            toast.error(`Row ${index + 1}: CGST rate must be between 0 and 100`);
          }
          if (item.invoiceCgstAmount === "" || isNaN(Number(item.invoiceCgstAmount)) || Number(item.invoiceCgstAmount) < 0) {
            iErrors.invoiceCgstAmount = `Row ${index + 1}: Enter a valid CGST amount`;
            toast.error(`Row ${index + 1}: Enter a valid CGST amount`);
          }
          if (item.invoiceSgstRate === "" || isNaN(Number(item.invoiceSgstRate)) || Number(item.invoiceSgstRate) < 0 || Number(item.invoiceSgstRate) > 100) {
            iErrors.invoiceSgstRate = `Row ${index + 1}: SGST rate must be between 0 and 100`;
            toast.error(`Row ${index + 1}: SGST rate must be between 0 and 100`);
          }
          if (item.invoiceSgstAmount === "" || isNaN(Number(item.invoiceSgstAmount)) || Number(item.invoiceSgstAmount) < 0) {
            iErrors.invoiceSgstAmount = `Row ${index + 1}: Enter a valid SGST amount`;
            toast.error(`Row ${index + 1}: Enter a valid SGST amount`);
          }
        } else {
          if (item.invoiceIgstRate === "" || isNaN(Number(item.invoiceIgstRate)) || Number(item.invoiceIgstRate) < 0 || Number(item.invoiceIgstRate) > 100) {
            iErrors.invoiceIgstRate = `Row ${index + 1}: IGST rate must be between 0 and 100`;
            toast.error(`Row ${index + 1}: IGST rate must be between 0 and 100`);
          }
          if (item.invoiceIgstAmount === "" || isNaN(Number(item.invoiceIgstAmount)) || Number(item.invoiceIgstAmount) < 0) {
            iErrors.invoiceIgstAmount = `Row ${index + 1}: Enter a valid IGST amount`;
            toast.error(`Row ${index + 1}: Enter a valid IGST amount`);
          }
        }
      }

      return iErrors;
    });

    setIErrors(tempErrors);
    return tempErrors.every((err) => Object.keys(err).length === 0); // Returns true if no errors
  };



  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsGenrateButtonDisabled(true);
    setIsInvoiceButtonDisabled(true);
    const actionType = e.nativeEvent.submitter.value;

    if (!validateInvoiceItems(invoiceItems)) {
      //console.log(iErrors);
      setIsGenrateButtonDisabled(false);
      setIsInvoiceButtonDisabled(false);
      return; // Prevent submission if there are errors
    }

    const inVoiceData = (
      {
        invoice_client_name: client.name ?? "",
        invoice_client_id: client.client_id ?? 0,
        invoice_client_country: client.country ?? "",
        invoice_client_company_name: client.company_name ?? "",
        invoice_client_billing_address: client.billing_address ?? "",
        invoice_client_currency: client.currency ?? "",
        invoice_client_state: client.state ?? "",
        invoice_client_gst_number: client.gst ?? "",
        invoice_client_hsn_number: client.hsn_number ?? "",
        invoice_client_account_details: client.account_detail ?? "",
        invoice_client_bank_details: client.account_details ?? "",
        invoice_client_invoice_date: invoiceDate ? format(invoiceDate, "dd/MM/yyyy") : "",
        invoice_client_purchase_order: purchaseOrder ?? "",
        invoice_client_departments: departments.value ? departments.value.toUpperCase() : "",
        invoice_client_department_id: departments.dep_id ? departments.dep_id : "",
        invoice_client_project: (project && project.value) ? project.value : 0,
        invoice_client_project_name: (project && project.label) ? project.label : "",
        invoice_client_created_by: authUserData ? authUserData[0].user_id : 1,
        invoice_client_invoice_items: invoiceItems ?? "",
        invoice_client_internal_note: internalNote ?? "",
        invoice_client_file_Name: fileName ?? "",
        invoice_action_type: actionType ?? "",
        invoice_total_payment: totalPayment ? parseFloat(totalPayment) : "0.0",
        invoice_total_gross: totalGross ? parseFloat(totalGross) : "0.0",
        invoice_total_gst_amount: totalGstAmount ? parseFloat(totalGstAmount) : "0.0",

      }
    );

    setLoading(true);
    try {
      const response = await axios.post(CONST.ADD_INVOICE, inVoiceData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === "success") {
        if (actionType === 'download') {
          if (response.data.pdf_link) {
            const iPdfLink = CONST.PDF_LINK + response.data.pdf_link;
            //window.open(iPdfLink, '_blank');
            if (iPdfLink) {
              const link = document.createElement("a");
              link.href = iPdfLink;

              // Extract filename from URL
              const fileName = iPdfLink.split("/").pop();

              // using Java Script method to get PDF file
              fetch(iPdfLink).then((response) => {
                response.blob().then((blob) => {

                  // Creating new object of PDF file
                  const fileURL =
                    window.URL.createObjectURL(blob);

                  // Setting various property values
                  let alink = document.createElement("a");
                  alink.href = fileURL;
                  alink.download = fileName;
                  alink.click();

                });
              });
            }

          }
        }
        //navigate(CONST.ADMIN_PRE +"invoice/edit/" + response.data.invoice_id);
        navigate(CONST.ADMIN_PRE + "invoice");
        toast.success('Invoice Generated Successfully');
      } else {
        console.error(response.data.message || "Something went wrong");
        toast.error(response.data.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error adding invoice:", error.response?.data || error.message);
      toast.error("Unexpected response:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
    ////console.log("inVoiceData:", inVoiceData);
    ////console.log("Valid Invoice Data:", invoiceItems);
    ////console.log("Form submitted with action:", actionType);
  };

  return (
    <>
      <Loading loading={loading} background="rgba(0, 0, 0, 0.5)" loaderColor="#fff" />
      <div className="container mt-0 mb-1">
        <div className="row">
          <div className="col-8">
            <h1 className="h3 mb-2">Add <strong>Invoice</strong></h1>
          </div>
          <div className="col-4 text-right">
            <Link className="btn btn-secondary" to={CONST.ADMIN_PRE + 'invoice'}><i className="align-middle" data-feather="dollar-sign"></i>{" "}View All Invoices</Link>
          </div>
        </div>
      </div>
      <div className="container p-0 mb-1">
        <div className="row mt-3">
          <div className="card mt-2">
            <div className="card-body">
              <div className="row">
                <Col >
                  <label className="form-label">Department</label>
                  <Select
                    options={departmentData}
                    placeholder="Select a Department..."
                    onChange={handleDepartmentChange}
                    value={departments}
                    isDisabled={isGenrateButtonDisabled || !isAdmin}
                  />
                  {validate.department && <div className="text-danger">{validate.department}</div>}
                </Col>
                <Col >
                  {
                    (departments && departments.value !== "") ?
                      (
                        <>
                          <label className="form-label">Client</label>
                          <Select
                            options={clients}
                            placeholder="Select a client..."
                            onChange={handleClientChange}
                            value={client}
                            isDisabled={isGenrateButtonDisabled} />
                          {validate.client && <div className="text-danger">{validate.client}</div>}
                        </>

                      ) : ""
                  }
                </Col>
                <Col >
                  {
                    (client && client.value !== "")
                      ?
                      (
                        <>
                          <label className="form-label">Projects</label>
                          <Select
                            placeholder="Select a project..."
                            options={arrProjects}
                            onChange={setProject}
                            value={project}
                            isDisabled={isGenrateButtonDisabled}
                          />
                        </>
                      )
                      : ""
                  }
                </Col>

                <Col className='align-center mt-4' style={{ alignContent: "center" }}>
                  <Button variant="primary" onClick={handleSearch} className="mr-1" disabled={isInvoiceButtonDisabled}>
                    <i className="" data-feather="refresh-ccw"></i>
                    <span className="">{" "}Generate</span></Button>
                  <Button variant="secondary" onClick={handleReset} className="ml-1">
                    <i className="" data-feather="x"></i>
                    <span className="">{" "}Reset</span>
                  </Button>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
      {generateInvoice && (
        <div className="container mt-1">
          <div className="row">
            <div className="card">
              <div className="card-body">
                <h5 className="h6 text-center mb-4">TAX INVOICE</h5>
                <div className="row">
                  <div className="col-md-8">
                    <img src={process.env.PUBLIC_URL + '/assets/img/eluminous-pvt-ltd.png'} alt="logo" style={{ width: '200px' }} className="img-fluid mt-3" />
                  </div>
                  <div className="col-md-4">
                    <strong>eLuminous Technologies Pvt Ltd.</strong>
                    <div style={{ fontSize: '12px' }}>
                      <span>{settings.address || ''}</span><br />
                      {
                        (client.country && client.country === 'IN') ?

                          (
                            <>
                              <span><strong>GSTIN: </strong>{settings.gstin || ''}</span><br />
                              <span><strong>PAN: </strong>{settings.pan || ''}</span>
                            </>
                          ) : (
                            <>
                              <span><strong>WEBSITE: </strong>{settings.website || ''}</span>
                            </>
                          )
                      }
                    </div>
                  </div>
                </div>

                <hr className='my-2' />
                <div className="row">
                  <div className='col-md-4'>
                    <span className='mb-3'><strong>Billing Address</strong></span>
                    <div style={{ fontSize: '12px' }}>
                      <strong>To,</strong><br />
                      {client.company_name && client.company_name ? (<span><strong>{client.company_name}</strong>,<br /></span>) : ""}
                      {client.name && client.name ? (<span><strong>{client.name}</strong>,<br /></span>) : ""}
                      {client.billing_address && client.billing_address ? (<span>{client.billing_address},<br /></span>) : ""}
                      {
                        (client.country && client.country === 'IN') ?
                          (
                            <>
                              {client.gst && client.gst ? (<span><strong>GSTIN: </strong>{client.gst}</span>) : ""}
                            </>
                          )
                          : ""
                      }
                    </div>
                  </div>
                  <div className='col-md-4'></div>
                  <div className='col-md-4'>
                    <div style={{ fontSize: '12px' }}>
                      <div ><strong>Invoice Number: </strong></div>
                      <div><strong>Invoice Date: </strong>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={invoiceDate}
                          onChange={(date) => setInvoiceDate(date)}
                        />
                      </div>
                      {
                        (client.country && client.country === 'IN') ?
                          (
                            <>
                              <div>
                                <strong>Banking Details: </strong> {client.account_details && client.account_details ? (<span>{client.account_details}</span>) : ""}</div>
                              <div>
                                <strong>HSN Code: </strong> {client.hsn_number && client.hsn_number ? (<span>{client.hsn_number}</span>) : ""}</div>
                              <div>
                                <strong>Purchase Order Number: </strong>
                                <input
                                  name="purchaseOrder"
                                  value={purchaseOrder}
                                  onChange={(e) => setPurchaseOrder(e.target.value)}
                                />
                              </div>
                            </>
                          )
                          : ""
                      }
                    </div>
                  </div>
                </div>
                <div className='row mt-5'>
                  <div className="invoice-container">
                    <form onSubmit={handleSubmit}>
                      <table className="table table-bordered text-center align-middle table-responsive invoice-table" width="100%">
                        <thead>
                          <tr>
                            <th rowSpan="2" className="srno align-middle">SR. NO.</th>
                            <th rowSpan="2" className=" align-middle description">DESCRIPTION</th>
                            {
                              client.country === "IN" && (
                                <>
                                  <th rowSpan="2" className="amount align-middle">GROSS</th>
                                  {
                                    client.state === "MH" ? (
                                      <>
                                        <th colSpan="2" className="">CGST</th>
                                        <th colSpan="2" className="">SGST</th>
                                      </>
                                    ) : (
                                      <th colSpan="2" className="">IGST</th>
                                    )
                                  }
                                </>
                              )
                            }
                            <th rowSpan="2" className="amount align-middle">TOTAL</th>
                          </tr>
                          {
                            client.country === "IN" && (
                              <tr>
                                {
                                  client.state === "MH" ? (
                                    <>
                                      <th className="rate">Rate</th>
                                      <th className="amount"> Amount</th>
                                      <th className="rate">Rate</th>
                                      <th className="amount"> Amount</th>
                                    </>
                                  ) : (
                                    <>
                                      <th className="rate">Rate</th>
                                      <th className="amount"> Amount</th>
                                    </>
                                  )
                                }


                              </tr>
                            )}
                        </thead>
                        <tbody style={{ fontSize: "12px" }}>
                          {
                            invoiceItems.map((item, index) => (
                              <tr key={index}>
                                <td className="srno">{item.invoiceSrNo}</td>
                                <td className="description">
                                  <textarea name="invoiceDescription" value={item.invoiceDescription} onChange={(e) => handleInputChange(index, "invoiceDescription", e.target.value)} />

                                </td>

                                {
                                  client.country === "IN" && (
                                    <>
                                      <td className="amount">
                                        <div className="currency-input">
                                          <span>{client.currency ? getCurrencySymbol(client.currency) : ""}</span>
                                          <input type="text" name="invoiceGross" value={item.invoiceGross} onChange={(e) => handleInputChange(index, "invoiceGross", e.target.value)} />
                                        </div>
                                      </td>
                                      {
                                        (client.state === "MH" && client.state === "MH") ? (
                                          <>
                                            <td className="rate">
                                              <div className="percentage-input">
                                                <input type="text" name="invoiceCgstRate" value={item.invoiceCgstRate} readOnly />
                                                <span>%</span>
                                              </div>
                                            </td>
                                            <td className="amount">
                                              <div className="currency-input">
                                                <span>{client.currency ? getCurrencySymbol(client.currency) : ""}</span>
                                                <input type="text" name="invoiceCgstAmount" value={item.invoiceCgstAmount} readOnly />
                                              </div>
                                            </td>
                                            <td className="rate">
                                              <div className="percentage-input">
                                                <input type="text" name="invoiceSgstRate" value={item.invoiceSgstRate} readOnly />
                                                <span>%</span>
                                              </div>
                                            </td>
                                            <td className="amount">
                                              <div className="currency-input">
                                                <span>{client.currency ? getCurrencySymbol(client.currency) : ""}</span>
                                                <input name="invoiceSgstAmount" type="text" value={item.invoiceSgstAmount} readOnly />
                                              </div>
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td className="rate">
                                              <div className="percentage-input">
                                                <input type="text" name="invoiceIgstRate" value={item.invoiceIgstRate} readOnly />
                                                <span>%</span>
                                              </div>
                                            </td>
                                            <td className="amount">
                                              <div className="currency-input">
                                                <span>{client.currency ? getCurrencySymbol(client.currency) : ""}</span>
                                                <input type="text" name="invoiceIgstAmount" value={item.invoiceIgstAmount} readOnly />
                                              </div>
                                            </td>
                                          </>
                                        )
                                      }

                                    </>
                                  )}
                                <td className="amount" >
                                  <div className="currency-input">
                                    <span>{client.currency ? getCurrencySymbol(client.currency) : ""}</span>

                                    <input type="text" name="invoiceTotal" value={item.invoiceTotal} onChange={(e) => {
                                      if (client.country !== "IN") {
                                        handleInputChange(index, "invoiceTotal", e.target.value);
                                      }
                                    }} />
                                  </div>
                                </td >
                                <td className="removeButton" >
                                  {
                                    index && index !== 0 ? (
                                      <button type="button" className="btn btn-outline-danger btn-pill btn-sm" onClick={() => removeRow(index)}>X</button>
                                    ) : ""
                                  }
                                </td>
                              </tr>
                            ))}
                        </tbody>

                      </table>
                      <div className='col-12 text-left'>
                        <Button variant="secondary" onClick={addRow}>
                          <i className="align-middle" data-feather="plus"></i> {" "}Add Row
                        </Button>
                      </div>

                      <div className='col-md-12 text-right'>
                        {
                          client.country === "IN" ? (
                            <>
                              <div>
                                <span className='text-left'><strong>Gross: </strong></span>
                                <span className='text-right'> {client.currency ? getCurrencySymbol(client.currency) : ""}  {totalGross || "0.00"}</span>
                              </div>
                              <div>
                                <span className='text-left'><strong>GST: </strong></span>
                                <span className='text-right'> {client.currency ? getCurrencySymbol(client.currency) : ""}  {totalGstAmount || "0.00"}</span>
                              </div>
                            </>
                          ) : ""
                        }
                        <div>
                          <span className='text-left'><strong>Total: </strong></span>
                          <span className='text-right'> {client.currency ? getCurrencySymbol(client.currency) : ""} {totalPayment || "0.00"}</span>
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='col-12 ml-0 pl-0 mb-5'>

                        <div className='row'>
                          <div className="col-6 text-left" style={{ fontSize: "12px;" }}>
                            <div className='mb-3'>
                              <strong>Account Information for Wire Transfer</strong>
                              <div className="pt-2" >
                                <strong>Account Name: </strong>ELUMINOUS TECHNOLOGIES PVT LTD,<br />
                                <strong>Account Type: </strong>Current Account,,<br />
                                <strong>Number: </strong>00648730000095,<br />
                                <strong>NEFT/IFSC Code: </strong>HDFC0000064,<br />
                                <strong>SWIFT Code: </strong>HDFCINBB,<br />
                                <strong>Bank Address: </strong>HDFC Bank, Thatte Nagar branch, Gangapur Road, Nashik
                              </div>
                            </div>
                            {
                              client?.country === "IN" ? (
                                <>
                                  <strong>Invoice Terms & Other Terms</strong>
                                  <div className="pt-2">
                                    We declare that this CCI shows the actual price of the goods/services described and that all particulars are true and correct.
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="pt-2">
                                    <div>
                                      1) Payment should be done within 3 days.<br />
                                      2) If you have any questions regarding this invoice, please contact accounts@eluminoustechnologies.com within 2 working days.
                                    </div>
                                  </div>
                                </>
                              )
                            }
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <div className="pt-5" >
                              <strong>Thanking You!</strong><br />
                              eLuminous Technologies Pvt Ltd
                            </div>
                          </div>
                          <div className="col-6 text-right align-bottom" style={{ fontSize: "12px;" }}>
                            {
                              client?.country === "IN" ? (
                                <>
                                  <div className="">
                                    <div>For, eLuminous Technologies Pvt Ltd</div>
                                    <div className="pt-5 ">Authorised signatory</div>
                                  </div>
                                </>
                              ) : ""
                            }
                          </div>
                        </div>

                      </div>
                      <hr className='my-4' />
                      <div className='col-12 ml-0 pl-0 mb-5'>
                        <div className='row'>

                          <div className='col-6'>
                            <label className='form-label'>Internal Note:</label>
                            <TextareaAutosize
                              className="form-control"
                              placeholder="Please enter internal note"
                              name="address"
                              value={internalNote || ""}
                              onChange={(e) => setInternalNote(e.target.value)}
                              minRows={2} // Minimum number of rows
                              maxRows={10} // Maximum number of rows before scrolling
                              style={{
                                width: "100%",
                                resize: "none",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 text-center'>
                        {/*  <button type="submit" value="preview" className="btn btn-primary mr-2"><i className="align-middle" data-feather="eye"></i> {" "}Preview</button> */}
                        <button type="submit" value="download" className="btn btn-secondary mr-2"  ><i className="align-middle" data-feather="download-cloud"></i> {" "}Save & Download</button>
                        <button type="submit" value="draft" className="btn btn-success mr-2" ><i className="align-middle" data-feather="tag"></i> {" "}Save as Draft</button>
                      </div>

                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div >
        </div >
      )
      }
    </>
  );
}
