import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import TextareaAutosize from "react-textarea-autosize";
import Form from "../../includes/Forms";
import Loading from 'react-fullscreen-loading';
import { format } from "date-fns";
import axios from 'axios';
import * as CONST from '../../includes/Constant';
import { toast } from 'react-toastify';
import { S_RENDER_HTML, SD_CAP_FIRST_LETTER } from '../../includes/staticData';

export default function InvoiceHistory({ invoice_id }) {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchInvoiceHistory = async () => {
    try {
      setLoading(true);
      const response = await axios.post(CONST.GET_INVOICE_HISTORY, {
        invoice_id: invoice_id,
      });
      if (response) {
        if (response.data.status === "success" && response.data.data.length > 0) {
          let eHistory = response.data.data;
          setHistory(eHistory);

        } else {
          console.error("Error fetching invoice:", response.data.message);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Please try again.");
    }
  }
  useEffect(() => {
    fetchInvoiceHistory();
  }, [])

  return (
    <>
      {loading && <Loading loading={loading} />}
      <div className='row'>

        {
          history && (
            <>
              <div className='col-12'>
                <table className="table table-striped table-bordered " style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{width:"150px"}} >By</th>
                      <th style={{width:"150px"}}>Date</th>
                      <th style={{width:"80px"}}>Type</th>
                      <th>Description</th>

                    </tr>
                  </thead>
                  <tbody>
                    {
                      history.map((h) => (

                        <tr key={h.id}>
                          <td>{h.updated_by_name}</td>
                          <td>{new Date(h.updated_at).toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" })}</td>
                          <td >{SD_CAP_FIRST_LETTER(h.change_type)}</td>
                          <td> {S_RENDER_HTML({ description: h.description })}</td>

                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </>
          )
        }
      </div>
    </>
  )
}
