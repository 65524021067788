import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import NavRoutes from "./component/includes/NavRoutes";
import SidebarMenu from "./component/includes/SidebarMenu";
import Header from "./component/includes/Header";
import Footer from "./component/includes/Footer";
import { AuthProvider } from "./component/context/AuthContext";
import feather from "feather-icons";
import { GlobalModal } from "./component/admin/modal/GlobalModal";

function App() {
  useEffect(() => {
    // Activate Feather icons
    window.feather.replace();
  }, []);
  return (
    <>
      <BrowserRouter basename="">
        <AuthProvider>
          <div className="wrapper">
            <SidebarMenu />
            <div className="main">
              <Header />
              <div className="content">
                <NavRoutes />
                <GlobalModal/>
              </div>
              <Footer />
            </div>
          </div>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
